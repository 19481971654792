// Import external modules
import axios from 'axios'
import { CampaignManualStatus } from '../../constants/models/Campaign.model'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

/**
 * Fetch campaigns data related to an user
 */
export const getCampaigns = (userId) => {
  return axios.get(`${API_ENDPOINT}/campaigns`, {
    params: {
      filter: {
        where: {
          manualStatus: { neq: CampaignManualStatus.Pending },
          advertiserId: userId,
        },
        include: [
          {
            relation: 'account',
          },
          {
            relation: 'user',
          },
          {
            relation: 'advertisements',
            scope: {
              order: ['sort ASC'],
              include: [
                {
                  relation: 'advertisingType',
                },
                {
                  relation: 'resources',
                },
                {
                  relation: 'resourcesClick',
                },
                { 
                  relation: 'mathilde' 
                },  
                {
                  relation: 'advertisingDetailSegmentations',
                  scope: {
                    include: [
                      {
                        relation: 'segmentationScope',
                        scope: {
                          include: [
                            {
                              relation: 'segmentationMatch',
                            },
                            {
                              relation: 'formatDisplay',
                              scope: {
                                include: [
                                  {
                                    relation: 'format',
                                  },
                                  {
                                    relation: 'display',
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  relation: 'advertisingDetails',
                  scope: {
                    include: [
                      {
                        relation: 'formatBySectionOnDisplay',
                        scope: {
                          include: [
                            {
                              relation: 'formatDisplay',
                              scope: {
                                include: [
                                  {
                                    relation: 'format',
                                  },
                                  {
                                    relation: 'display',
                                  },
                                ],
                              },
                            },
                            {
                              relation: 'digitalPropertyBySection',
                              scope: {
                                include: [
                                  {
                                    relation: 'digitalProperty',
                                  },
                                ],
                              },
                            },
                            {
                              relation: 'assingmentCity'
                            }
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            relation: 'adSponsoredContents',
            scope: {
              include: [
                {
                  relation: 'resourcesContents',
                },
                {
                  relation: 'adSponsoredContentPricingAdContents',
                  scope: {
                    include: [
                      {
                        relation: 'pricingAdContent',
                        scope: {
                          include: [
                            {
                              relation: 'advertisingTypeDigitalProperties',
                              scope: {
                                include: [
                                  {
                                    relation: 'digitalProperty',
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  })
}

/**
 * Replace resource for an advertising
 */

export const replaceResource = async (id, file, advertisingTypeId, type) => {
  if((advertisingTypeId == 5 || advertisingTypeId == 6) && type){
    const formData = new FormData()

    formData.append('file', file)

    return axios.patch(
    `${API_ENDPOINT}/advertising/${id}/replace-resource-click/${type}`,
    formData,
    { headers: { 'content-type': 'multipart/form-data' } }
  )

  }else{
    const formData = new FormData()

    formData.append('file', file)
    return axios.patch(
    `${API_ENDPOINT}/advertising/${id}/replace-resource`,
    formData,
    { headers: { 'content-type': 'multipart/form-data' } }
  )
  }
  
  // return axios.patch(
  //   `${API_ENDPOINT}/advertising/${id}/replace-resource`,
  //   formData,
  //   { headers: { 'content-type': 'multipart/form-data' } }
  // )
}
