// import external modules
import { combineReducers } from 'redux'

// import internal modules
import globalMessages from './globalMessages/globalMessages'
import ui from './ui/ui'
import campaigns from './campaigns/campaignsReducer'
import user from './user/user'
import notifications from './notifications/notifications'

const reducer = combineReducers({
  globalMessages,
  ui,
  campaigns,
  user,
  notifications,
})

export default reducer
