export function getMinDate(dates) {
  return dates.reduce((a, b) => a < b ? a : b);
}

export function getMaxDate(dates) {
  return dates.reduce((a, b) => a > b ? a : b);
}

export function getTotal(nums) {
  return nums.reduce((a, b) => a + b, 0);
}