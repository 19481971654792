/* import external modules */
import React from 'react'

/* import internal modules */
import Icon from '../Icon'

import messages from '../../../constants/messages'

import { Link, Span } from './styles'

/** @description This is a container functional component of ExternalLinkUrl
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name ExternalLinkUrl
 *  @returns {Component} Returns the container component of the external link url
 **/

const ExternalLinkUrl = ({ url,type, ...props }) => {
  const openUrlBrowser = () => {
    if (url.startsWith('http')) {
      window.open(url)
    } else {
      window.open(`//${url}`)
    }
  }

  return (
    <Link {...props} onClick={openUrlBrowser} target="_blank">
      <Icon icon="url" color="#096CA5" className="icon-outline" />
      <Icon icon="urlFill" color="#096CA5" className="icon-fill" />
      <Span>{type != "Mathilde" ? messages.messageTextUrl : messages.messageViewReport}</Span>
    </Link>
  )
}

export default ExternalLinkUrl
