import {
  SET_CAMPAIGNS,
  SET_FETCHING_CAMPAIGNS,
  SET_CLEAR_CAMPAIGNS,
  WERE_COOKIES_ACCEPTED
} from '../../types/types'

/**
 * Save campaigns to redux store
 */
export const setCampaigns = (campaigns) => ({
  type: SET_CAMPAIGNS,
  payload: { campaigns },
})

/**
 * Save campaigns to redux store
 */
export const setClearCampaigns = (campaigns) => ({
  type: SET_CLEAR_CAMPAIGNS,
  payload: { campaigns },
})

/**
 * Save flag when fetching campaigns
 */
export const setFetchingCampaigns = (fetching) => ({
  type: SET_FETCHING_CAMPAIGNS,
  payload: { fetching },
})

export const setWereCookiesAccepted = (data) => ({
  type: WERE_COOKIES_ACCEPTED,
  payload: {data}
})
