/* import external modules */
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SpanTag = styled.span`
  vertical-align: baseline;
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.height ? props.height : null)};
  display: ${(props) => (props.display ? props.display : null)};
  float: ${(props) => (props.float ? props.float : null)};
  padding: ${(props) => (props.p ? props.p : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : null)};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  margin: ${(props) => (props.m ? props.m : null)};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : null)};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : null)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : null)};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : null)};
  color: ${(props) => (props.color ? props.color : null)};
  font-family: 'DMSans-Regular';
  font: ${(props) => (props.font ? props.font : null)};
  ${media.md`
     font: ${(props) => (props.fontMd ? props.fontMd : null)};
     font-size: ${(props) => (props.fontSizeMd ? props.fontSizeMd : null)};
     line-height: ${(props) =>
       props.lineHeightMd ? props.lineHeightMd : null};
  `}
`
