/* import external modules */
import React, { useEffect, useState } from 'react'

/* import internal modules */
import { ModalOverlay, ModalContainer } from './styles'
import Portal from './portal'
import Button from '../Button'

/** @description This is a container functional component Modal
 *  @version 1.0.0
 *  @since 14/05/2020
 *  @author Yadurani López <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Modal
 *  @params {boolean} open Is open Modal
 *  @params {function} onClose Is close Modal
 *  @params {component} Is children modal
 *  @returns {Component} Returns the container component Modal
 **/

const Modal = ({
  children,
  open,
  onClose,
  width,
  textBtn,
  height,
  className,
  modalVideo,
  ...props
}) => {
  const [fadeType, setFade] = useState(null)
  const body = document.body
  useEffect(() => {
    body.classList.add('overflow')
    open && body.classList.add('modal')
    setTimeout(() => setFade('in'), 0)
  }, [body, open])

  const transitionEnd = (e) => {
    if (e.propertyName !== 'opacity' || fadeType === 'in') return
    fadeType === 'out' && onClose()
  }
  const close = (e) => {
    const idModal = e.target.id
    const buttonModal = e.target.nodeName
    if (idModal === 'overlay' || buttonModal === 'BUTTON') {
      body.classList.remove('modal')
      setFade('out')
    }
  }
  
  const handleClose = () => {
    body.classList.remove('modal')
    setFade('out')
  }
  if (!open) {
    return null
  }
  return (
    open && (
      <Portal selector="#modal">
        <ModalOverlay
          onTransitionEnd={transitionEnd}
          onClick={(e) => close(e)}
          id="overlay"
          className={`fade-${fadeType} ` + className}>
          <ModalContainer
            col
            xl="8"
            lg="10"
            md="10"
            sm="12"
            xs="12"
            width={width}
            height={height}
            textBtn={textBtn}
            {...props}>
            <Button
              text="Cerrar"
              color="#006CA5"
              onClick={() => handleClose()}
              id="btn-close-modal"
              background="transparent"
              iconNew="closeModal"
              font="14px/18px 'DMSans-Regular'"
            />
            {children}
          </ModalContainer>
        </ModalOverlay>
      </Portal>
    )
  )
}

export default Modal
