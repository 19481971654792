/* import external modules */
import React from 'react'

/* import internal modules */
import { Img } from './styles'

const Image = ({ icon, width, height, alt, className, ...props }) => {
  return (
    <Img
      className={className}
      alt={alt}
      src={icon}
      width={width}
      height={height}
      {...props}
    />
  )
}

export default Image
