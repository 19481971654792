import styled from 'styled-components'

export const LinkReportDisabled = styled.a`
  cursor: pointer;
  position: relative;
  text-decoration: none;
  display: inline-flex;
  margin: 8px 0 15px;
  color: ${( { theme }) => theme.colors.gray};
  @media (max-width: 1024px) {
    margin: 0 0 20px;
  }
  .badge-report {
    ${'' /* width: 390px; */}
    width: 14rem;
    white-space: pre-line;
    @media(max-width: 1024px) {
      width: auto;
    }
  }
  &:hover .badge-report {
    display: inline-block;
  }
  svg {
    flex-shrink: 0;
  }
`
export const CaptionAnchor = styled.span`
  color: inherit;
  padding-left: 10px;
  font: 14px/20px 'DMSans-Regular';
  flex: 1;
  @media(max-width: 1024px) {
    display: none;
  }
`