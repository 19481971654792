import {TOKEN_DB_KEY} from './localStorage'
import {useMemo} from "react";


const generateUrl = (token, redirect, fallback) => {
    const url = new URL(process.env.REACT_APP_CUSTOMERS_URL);
    url.pathname = 'auth'
    url.searchParams.set('identity', token)
    url.searchParams.set('redirectUrl', redirect)
    url.searchParams.set('fallbackUrl', fallback ?? '/')
    return url.toString();
}

const useCustomerRoutes = (fallback) => {
    return useMemo(() => {
        const token = window.sessionStorage.getItem(TOKEN_DB_KEY);
        return {
            customersStartCampaignPage: generateUrl(token, 'empieza-tu-campana', fallback),
            customersHomePage: generateUrl(token, '/', fallback),
        }
    }, [fallback])
}

export default useCustomerRoutes;