/* import external modules */
import React, { useState, useRef, useEffect } from 'react'

/* import internal modules */
import { Input, ButtonUpload, Label, ContenUpload } from './styles'
import Icon from '../Icon'
import messages from '../../../constants/messages'

/** @description This is a functional component of image upload with validations format, dimensions and weight
 *  @version 1.0.0
 *  @since 14/05/2020
 *  @author Jaime Andrés Gómez Gutiérrez <jaigom.ext@eltiempo.com>
 *
 *  @function
 *  @name UploadComponent
 *  @param {props} handleImage Function for handle alerts status upload images
 *  @param {number} num Number into title selected ads
 *  @param {number} maxWeight Max weight of image
 *  @param {string} unitMaxWeight Unit max weight of image on B, KB or MB
 *  @param {number} heightImage Height image
 *  @param {number} widthImage Width image
 *  @returns {Component} Returns the button upload component with hidden image for validate dimensions
 **/

const UploadComponent = ({
  idAd,
  handleImage,
  num,
  maxWeight,
  unitMaxWeight,
  widthImage,
  heightImage,
  className,
}) => {
  let validatedFormat = false
  let validatedWeight = false
  let renderedPreviewImage = false
  const refImage = useRef(null)
  const [file, setFile] = useState(null)
  const [imagePreview, setImagePreview] = useState(null)

  useEffect(() => {
    handleValidationsImage(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  const handleValidationsImage = (file) => {
    if (file) {
      showStatusImageValidations(null, null, 'loading')
      validateFormat(file.type)
      if (validatedFormat) {
        validateWeight(file.size, maxWeight, unitMaxWeight)
        if (validatedWeight) {
          showPreviewImage(file)
          if (renderedPreviewImage) {
            validateDimensions(heightImage, widthImage)
          }
        }
      }
    }
  }

  const validateDimensions = (heightImage, widthImage) => {
    renderedPreviewImage = false

    /* waiting for image render into <img/> */
    setTimeout(() => {
      logicValidateDimensions(heightImage, widthImage)
    }, 700)
  }

  const logicValidateDimensions = (heightImage, widthImage) => {
    let currentWidthImage = refImage.current.width
    let currentHeightImage = refImage.current.height
    let verifiedWidth = false
    let verifiedHeight = false

    heightImage === currentHeightImage
      ? (verifiedHeight = true)
      : (verifiedHeight = false)

    widthImage === currentWidthImage
      ? (verifiedWidth = true)
      : (verifiedHeight = false)

    if (verifiedWidth && verifiedHeight) {
      showStatusImageValidations(file, '', true)
    } else {
      showStatusImageValidations(
        file,
        messages.messageErrorInvalidDimensions,
        false
      )
    }
  }

  const showPreviewImage = (file) => {
    let tmpImage = file && URL.createObjectURL(file)
    setImagePreview(tmpImage)
    renderedPreviewImage = true
  }

  const showStatusImageValidations = (file, message, status) => {
    handleImage(file, message, status)
  }

  const validateWeight = (fileSizeB, maxWeight, unitMaxWeight) => {
    const fileSizeKB = Math.round(fileSizeB / 1000)
    const fileSizeMB = (fileSizeB / 1000 / 1000).toFixed(2)
    const messageError = messages.messageErrorExceededWeight
    switch (unitMaxWeight) {
      case 'B':
        fileSizeB < maxWeight
          ? (validatedWeight = true)
          : showStatusImageValidations(file, messageError, false)

        break

      case 'KB':
        fileSizeKB < maxWeight
          ? (validatedWeight = true)
          : showStatusImageValidations(file, messageError, false)
        break

      case 'MB':
        fileSizeMB < maxWeight
          ? (validatedWeight = true)
          : showStatusImageValidations(file, messageError, false)
        break

      default:
        showStatusImageValidations(file, messageError, false)
        break
    }
  }

  const validateFormat = (format) => {
    switch (format) {
      case 'image/png':
        validatedFormat = true
        break

      case 'image/jpg':
        validatedFormat = true
        break

      case 'image/jpeg':
        validatedFormat = true
        break

      default:
        validatedFormat = false

        showStatusImageValidations(
          file,
          messages.messageErrorInvalidFormat,
          false
        )
        break
    }
  }

  return (
    <ContenUpload className={className}>
      <Input
        accept="image/*"
        id={`contained-button-file-${num}`}
        type="file"
        onChange={(event) => {
          setFile(event.target.files[0])
          event.target.value = ''
        }}
      />
      <Label htmlFor={`contained-button-file-${num}`}>
        <ButtonUpload>
          Cargar archivo
          <Icon icon="upload" color="#006CA5" path={true} />
        </ButtonUpload>
      </Label>
      <img
        src={imagePreview}
        ref={refImage}
        style={{ display: 'none' }}
        alt={num}
      />
    </ContenUpload>
  )
}

export default UploadComponent
