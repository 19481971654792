/* import external modules */
import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'

/* import internal modules */
import Modal from '../../Common/Modal'
import {
  ContentImgDummy,
  SpanContent,
  ColTitle,
  ContentImgDummyHeigth,
  ColFooterModal,
  ColFooterButtons,
} from './styles'
import P from '../../Common/P'
import Button from '../../Common/Button'
import messages, { statusError } from '../../../constants/messages'
import theme from '../../../constants/themeConstants'
import { setGlobalMessage } from '../../../redux/actions/globalMessages/globalMessages'
import { setLoading } from '../../../redux/actions/ui/ui'
import {
  getCampaigns,
  replaceResource,
} from '../../../services/Campaigns/campaigns'
import {
  setCampaigns,
  setClearCampaigns,
} from '../../../redux/actions/campaigns/campaigns'
import ButtonPrimaryMd from '../../Common/ButtonPrimaryMd'
moment.locale('es')
moment.suppressDeprecationWarnings = true

/** @description This is a container functional component of AdvertiseList
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name ModalUploadMaterialReject
 *  @param {boolean} open Open modal true/false
 *  @param {function} onClose Function for close modal
 *  @param {number} imageTemplate Image Template for image preview
 *  @param {number} imageUrlUpload Url of image upload for button
 *  @param {string} device Type of device of each advertisements
 *  @param {number} format Name format of each advertisements
 *  @returns {Component} Returns the container component of the Modal Upload Material Reject
 **/

const ModalUploadMaterialReject = ({
  open,
  onClose,
  imageTemplate,
  imageUrlUpload,
  device,
  format,
  imageToUpload,
  adId,
  advertisingTypeId,
  nameFormat
}) => {
  const svgContainer = useRef()
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user.user)
  const fetchSvg = () => {
    if (!imageTemplate) return
    fetch(imageTemplate)
      .then((r) => r.text())
      .then((t) => {
        svgContainer.current.innerHTML = t
        if (svgContainer.current.querySelector('image')) {
          svgContainer.current
            .querySelector('image')
            .setAttribute('xlink:href', imageUrlUpload && imageUrlUpload)
        }
      })
  }
  useEffect(() => {
    fetchSvg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const widthModal = device === 'mobile' ? '600px' : '60%'
  const messageSuccessMaterialUpload = (
    <span>
      {messages.messageReplaceMaterial}
      <span className="Date">
        &nbsp;
        {moment(new Date()).format('D/MMM/YYYY').replace('.', '')}
      </span>
    </span>
  )

  const handleReplaceMaterial = () => {
    dispatch(setLoading(true))

    replaceResource(adId, imageToUpload, advertisingTypeId, nameFormat)
      .then(() => {
        return getCampaigns(user.userId)
      })
      .then(({ data }) => {
        dispatch(setClearCampaigns())
        dispatch(setCampaigns(data))
        dispatch(setLoading(false))
        handleToogleAlert()
      })
      .catch((error) => {
        dispatch(setLoading(false))
        handlerErrorToogleAlert(error)
      })
    onClose()
  }

  const handleToogleAlert = () => {
    dispatch(
      setGlobalMessage({
        open: true,
        message: messageSuccessMaterialUpload,
        status: 'success',
      })
    )
    setTimeout(
      () =>
        dispatch(setGlobalMessage({ open: false, message: '', status: '' })),
      5000
    )
  }

  const handlerErrorToogleAlert = (error) => {
    dispatch(
      setGlobalMessage({
        open: true,
        message:
          error.statusCode === 500
            ? 'No se pudo actualizar'
            : error.response.data.error.message,
        ...statusError,
      })
    )
    setTimeout(() => {
      dispatch(
        setGlobalMessage({
          open: false,
          ...statusError,
        })
      )
    }, 5000)
  }
  return (
    <Modal
      open={open}
      onClose={onClose}
      device={device}
      width={widthModal}
      preview={true}
      className="modal-preview">
      <ColTitle>
        <P
          align="left"
          font="14px/22px 'DMSans-Regular'"
          fontSm="16px/24px 'DMSans-Regular'"
          mb="15px">
          Formato: <SpanContent>{format} </SpanContent>
        </P>
      </ColTitle>
      <ContentImgDummyHeigth device={device}>
        <ContentImgDummy device={device} ref={svgContainer} width="100%" />
        <ColFooterModal>
          {messages.messageChangeMaterial}{' '}
          <ColFooterButtons>
            <ButtonPrimaryMd
              name="reemplazar-material"
              text="Si, reemplazarlo"
              width="150px"
              height="40px"
              onClick={handleReplaceMaterial}
            />
            <Button
              name="reemplazar-material"
              text="No"
              width="50px"
              height="40px"
              font="14px/26px 'DMSans-Regular'"
              background="transparent"
              color={theme.colors.secondary}
              borderRadius="4px"
              border="none"
              onClick={onClose}
            />
          </ColFooterButtons>
        </ColFooterModal>
      </ContentImgDummyHeigth>
    </Modal>
  )
}

ModalUploadMaterialReject.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  imageTemplate: PropTypes.string,
  imageUrlUpload: PropTypes.string,
  device: PropTypes.string,
  format: PropTypes.string,
}

export default ModalUploadMaterialReject
