/* import external modules */
import React, { useState }  from 'react'
import PropTypes from 'prop-types'

/* import internal modules */
import ModalFullwidth from '../../Common/ModalFullwidth'
import CardInfo from '../../Common/CardInfo'

import { Badge, Format, WrapperHeader } from './styles'
import Icon from '../../Common/Icon'
import {campaignIdStringFormat} from "../../../herpers/completeCampingId";
import messages from '../../../constants/messages'

/** @description This is a container functional component of ModalReport
 ** @version 1.0.0
 *  @since 24/05/2021
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name ModalReport
 *  @returns {Component} Returns the container component of the modal report
 **/

const ModalReport = ({ data, ...props }) => {
  const [loading, setLoading] = useState(true)
  const dataFormat = data
  const hideLoading = () => setLoading(false)
  return (
    <ModalFullwidth {...props}>
      <Badge>
        <Icon icon="warning"/>
        <span>
          <b>Para descargar el informe</b>
          <span className="Report--desktop">{messages.messageReportDesktop}</span>
          <span className="Report--mobile">{messages.messageReportMobile}</span>
        </span>
      </Badge>
      <CardInfo title="Reporte de campaña:" text={dataFormat.campaignName}/>
      <WrapperHeader>
        <CardInfo title="Id de campaña:" textSecondary={campaignIdStringFormat(dataFormat.campaignId)}/>
        <CardInfo title="Formato:">
          <Format>
            {dataFormat.format && <Icon icon={dataFormat.device} />}
            {dataFormat.format?.name || 'N/A'}
          </Format>
        </CardInfo>
        <CardInfo title="Portal:" text={dataFormat.nameJoinPortals}/>
        <CardInfo title="Impresiones:" text={dataFormat.impressions}/>
        <CardInfo title="Url ingresada:" text={dataFormat.url}/>
        <CardInfo className="Date" title="Fecha inicial:" text={dataFormat.initialDate}/>
        <CardInfo className="Date" title="Fecha final:" text={dataFormat.endDate}/>
      </WrapperHeader>
      <iframe
        id="iframe"
        className={loading ? 'loading' : ''}
        width="100%"
        onLoad={hideLoading}
        src={data.reportDataStudio}/>
    </ModalFullwidth>
  )
}

ModalReport.propTypes = {
  data: PropTypes.object.isRequired
}

export default ModalReport
