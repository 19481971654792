/* import external modules */
import React, { useEffect, useState } from 'react'

/* import internal modules */
import { Container, Header, Nav, NavContent, NavItem, Row, ModalCookies } from './styles'
import Logo from '../Logo'
import Menu from '../Menu'
import useCustomerRoutes from '../../../constants/routes'
import cname from '../../../constants/classNameData'
import Notifications from '../../Notifications'
import { useSelector, useDispatch } from 'react-redux'
import { TOKEN_DB_KEY } from '../../../constants/localStorage'
import { logout } from '../../../herpers/auth'
import ButtonPrimaryMd from '../ButtonPrimaryMd'
import { setWereCookiesAccepted } from '../../../redux/actions/campaigns/campaigns'
import { setLoading } from '../../../redux/actions/ui/ui'
import { hashUser, hashUserGTM } from '../../../herpers/hasUser'
import { acceptCookies } from '../../../herpers/eventsGTM'

/**
 *  @description This functional component is a header of the project
 *  @version 1.0.0
 *  @since 27/04/2020
 *  @author Yadurani López Rivera <yadlo.ext@eltiempo.com>
 *
 *  @function
 *  @name Header
 *  @returns {Component} Returns components of material-ui
 **/

const HeaderComponent = () => {
  const [url, setUrl] = useState('')
  const dispatch = useDispatch()
  const user = useSelector(({ user }) => user.user)
  const firstname = user ? user.firstname : ''
  const surname = user ? user.surname : ''
  const { customersHomePage, customersStartCampaignPage } =
    useCustomerRoutes(url)
  const token = window.sessionStorage.getItem(TOKEN_DB_KEY)
  const wereCookiesAccepted = useSelector(({campaigns}) => campaigns.wereCookiesAccepted)
  // localStorage.setItem('statusCookies', false)
  const [statusModalCookies, setStatusModalCookies] = useState(localStorage.getItem('statusCookies') || 'false')
  //

  const itemsMenu = [
    {
      label: 'Cerrar sesión',
      icon: 'signOut',
      onClick: async () => await logout(token),
    },
  ]
  
  // const cookiesAccepted = (document.cookies.includes('cookies-info=true') ? document.cookies.includes('cookies-info=true') : false);
  function getCookie(name) {
    let cookieArr = document.cookie.split(";");
  
    for (let i = 0; i < cookieArr.length; i++) {
      let cookiePair = cookieArr[i].split("=");
  
      if (name == cookiePair[0].trim()) {
        return true;
      }
    }
  
    return false;
  }

  const cookieName = "cookies-info";
  const cookieValue = getCookie(cookieName);

  const handleStatusModalCookies = () => {
    document.cookie = 'cookies-info=true; expires='+new Date(new Date().getTime() +90*24*60*60*1000).toUTCString(); 
    setStatusModalCookies(true)
    dispatch(setWereCookiesAccepted(true))
    localStorage.setItem('cookies-info','true')
    acceptCookies()
  }



  // useEffect(() => {
  //   window.addEventListener('beforeunload', () => {
  //     localStorage.clear();
  //   });

   
  // }, []);



  const redirectBuy = () => window.open(customersStartCampaignPage)
 

  useEffect(() => {
    const location = window.location.origin
    setUrl(location)

    }, []);

 



  return (
    <Header id="header">
      <Container fluid>
        <Row alignItems="center" justifyContent="between">
          <Logo
            alt="El tiempo Ads logo"
            img="images/logo/logo-et-ads-white.svg"
            className={"logo-login "+cname.home_logo}
            onClick={() => {
              window.open(customersHomePage)
            }}
          />
          <Nav>
            <NavContent>
              <NavItem className="notifications">
                <Notifications />
              </NavItem>
              <NavItem className="menu">
                <Menu
                  nameUser={`${firstname} ${surname}`}
                  itemsMenu={itemsMenu}
                  classt={cname.menu_user}
                />
              </NavItem>
              <NavItem className="publish-button publish-login">
                <ButtonPrimaryMd
                  name="publish-button"
                  classt={cname.header_button_bell}
                  text="Crear campaña"
                  onClick={redirectBuy}
                />
              </NavItem>
            </NavContent>
          </Nav>
        </Row>
      </Container>
      {cookieValue === false ?
        <ModalCookies>
            <div className="cookie-modal-content">
             <div className="cookie-modal-body"> 
                    <p className="cookie-content">
                    En este portal utilizamos datos de navegación / cookies propias y de terceros para gestionar el portal, elaborar información estadística, optimizar la funcionalidad del sitio y mostrar publicidad relacionada con sus preferencias a través del análisis de la navegación. 
                    Si continúa navegando, usted estará aceptando esta utilización. Puede conocer cómo deshabilitarlas u obtener más información<span>&nbsp;</span> 
                     <a className="link-cookie-content"  href="https://autoservicio.eltiempoads.com/politicas-de-navegacion" target="_blank">aquí.</a>
                    </p>

                    <ButtonPrimaryMd
                      text="Entendido"
                      classt="cookie-btn"
                      width="300px"
                      height="40px"
                      fontSize="18"
                      onClick={handleStatusModalCookies}
                    />
             </div>
            </div>
          </ModalCookies> :  null
          }
    </Header>
  )
}

export default HeaderComponent
