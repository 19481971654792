import styled from 'styled-components'
import P from '../../Common/P'

export const Item = styled.div`
  width: 100%;
  padding: 14px 0 20px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray_2};
  text-align: left;
  &:last-of-type {
    border-bottom: none;
    padding-bottom: 10px;
  }  
  .colorCart{
    background-color: #f1f6f9;
    padding: 10px;
    border-radius: 4px;    
  }
  .classTextInfo{
    margin: 20px 0px 20px 30px;
    width: 240px;
    color: #959595;
    font-weight: 600;
    @media (max-width: 1024px){
      margin: 20px 0px 20px 10px;
    }
  }
  .classTextInfoHead{
    margin: 10px 0px 2px 20px;
    width: 240px;
    color: #959595;
  }
  .classCenter{
    text-align: center;
  }
  
`

export const Concept = styled(P)`
  margin-bottom: ${(props) => (props.mb ? props.mb : '7px')};
  color: ${({ theme }) => theme.colors.gray_7};
  font: 13px/20px 'DMSans-Regular';

  .textPosition{
    color: #fff;
    background-color: #f1f6f9;
    padding: 10px;
    border-radius: 4px;  
  }
`

export const Description = styled.span`
  width: 50%;
  min-width: 155px;
  float: right;
  font: 13px/20px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.gray_1};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  svg {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 5px;
  }
`

