/* import external modules */
import styled from 'styled-components'
import { Container as ContainerComponent, media } from 'styled-bootstrap-grid'

/* import internal modules */

export const Footer = styled.footer`
  background: #000413 0% 0% no-repeat padding-box;
  height: auto;
  @media print {
    display: none;
  }
`
export const Container = styled(ContainerComponent)`
  padding-top: 50px;
  padding-bottom: 90px;
  max-width: 1200px;
  ${media.lg`
    padding-bottom: 100px;
  `}
`

/* Styles for contact section */
export const LinkContact = styled.div`
  text-align: left;
  font-weight: 400;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  line-height: 18px;
  letter-spacing: 0px;
  color: ${(props) => (props.color ? props.color : props.theme.colors.white)};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : 'none'};
  span {
    color: ${({ theme }) => theme.colors.white};
  }
  svg {
    vertical-align: text-bottom;
    margin-right: 20px;
  }
  a {
    color: white;
  }
  @media (max-width: 992px) {
    padding-top: ${(props) =>
      props.paddingTopMobile ? props.paddingTopMobile : null};
  }
`

export const TextMobile = styled.div`
  text-decoration: underline;

  ${media.lg`
    text-decoration: none;
  `}
`

export const RowDirectionElements = styled.div`
  display: flex;
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
  margin-top: 0;
  @media (max-width: 768px) {
    margin-top: ${(props) => props.marginTop && '20px'};
  }
`
export const DivMobile = styled.div`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '50px')};
  ${media.lg`
    padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '0px')};
  `}
`

export const RowCopyright = styled.div`
  img {
    float: left;
  }
  @media (max-width: 991px) {
    display: block;
    margin-bottom: 22px;
    img {
      float: none;
      margin-bottom: 25px;
    }
  }
`

export const RowDirection = styled.div`
  display: inline-flex;
  @media (max-width: 1200px) {
    display: block;
  }
`
