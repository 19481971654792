 const gtm = () =>{
     (function(w,d,s,l,i){w[l]=w[l]||[];
         w[l].push({'gtm.start':
           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
           })
          
          
         (window,document,'script','dataLayer','GTM-52F5HLL');
 
        }

// gtm()
<script>
{gtm()}
</script>
window.dataLayer = window.dataLayer || [];
export const acceptCookies = () => {

  if(window.dataLayer){
    window.dataLayer.push({'event':'aceptacion_cookies'});
  } else {
    console.error('dataLayer is not defined')
  }
  }
  