/* import external modules */
import React from 'react'

/* import internal modules */
import { ICONS } from './icons'
import { Svg } from './styles'

/** @description This functional component is Icon use all components
 *  @version 1.0.0
 *  @since 12/05/2020
 *  @author Yadurani López <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name icon
 *  @param {Boolean} path Is path o polygon
 *  @param {String} icon Name icon
 *  @param {String} color Color in hexadecimal
 *  @param {Number} width Width of Icon
 *  @param {Number} height Height of icon
 *  @returns {Component} Returns components
 **/

const Icon = ({
  path = false,
  icon,
  color,
  strokeColor,
  width,
  height,
  viewBox,
  ...props
}) => {
  return (
    <>
      {icon && (
        <Svg
          xmlns="http://www.w3.org/2000/svg"
          width={width || ICONS[icon].width}
          height={height || ICONS[icon].height}
          fill="none"
          viewBox={viewBox || ICONS[icon].viewBox}
          {...props}>
          {ICONS[icon].paths.map((p) => (
            <path
              key={p}
              d={p}
              fill={color}
              stroke={strokeColor}
              fillRule="evenodd"
            />
          ))}
        </Svg>
      )}
    </>
  )
}

export default Icon
