/* import external modules */
import React from 'react'

/* import internal modules */
import ExternalLinkReport from '../../Common/LinkExternalReport'
import ExternalLinkUrl from '../../Common/LinkExternalUrl'
           
import { AdvertisingType } from '../../../constants/advertisingType'

/** @description This is a container functional component of ActionsAdvertise
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *  @author Franklin Marquez <framar.ext@eltiempo.com>
 *
 *  @function
 *  @name ActionsAdvertise
 *  @returns {Component} Returns the container component of the actions advertise
 **/

const ActionsAdvertise = ({ typeAd, nextDayFinalDate, urlReport, urlSmart, onClick, reportType }) => {
  return (
    <>
  {urlReport !== "Mathilde" ? (
    <>
      <ExternalLinkReport
        onClick={onClick}
        nextDayFinalDate={nextDayFinalDate}
        urlReport={urlReport}
        reportType={reportType}
      />
      {typeAd === AdvertisingType.Display && urlSmart && (
        <ExternalLinkUrl url={urlSmart} type={urlReport} />
      )}
    </>
  ) : (
    typeAd === AdvertisingType.Display && urlSmart && (
      <ExternalLinkUrl url={urlSmart} type={urlReport} />
    )
  )}
</>
  )
}

export default ActionsAdvertise
