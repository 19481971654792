/** import external modules  */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/** import internal modules  */
import CampaignsAndMaterials from '../CampaignsAndMaterialsWrapper'
import { getCampaigns } from '../../../services/Campaigns/campaigns'
import {
  setCampaigns,
  setFetchingCampaigns,
} from '../../../redux/actions/campaigns/campaigns'
import { setLoading } from '../../../redux/actions/ui/ui'

/** @description This is a container functional component of CampaignsAndMaterialsPage
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name CampaignsAndMaterialsPage
 *  @returns {JSX.Element} Returns the container component of the Campaigns And Materials Page
 **/

const CampaignsAndMaterialsPage = () => {
  const campaignsList = useSelector(({ campaigns }) => campaigns.campaigns)
  const user = useSelector(({ user }) => user.user)
  const dispatch = useDispatch()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (Boolean(user)) {
      fetchCampaigns()
    }
  }, [user])  
  /**
   * Send request to get campaigns data; manage fetching status to show/hide loader gif
   */
  const fetchCampaigns = () => {
    dispatch(setFetchingCampaigns(true))
    dispatch(setLoading(true))
    getCampaigns(user.userId)
      .then((response) => {
        dispatch(setCampaigns(response.data))
      })
      .finally(() => {
        dispatch(setFetchingCampaigns(false))
        dispatch(setLoading(false))
        setLoaded(true)
      })
  }
  return (
    <>
    <CampaignsAndMaterials campaignsList={campaignsList} loaded={loaded} />   
  </>
  )
}

export default CampaignsAndMaterialsPage
