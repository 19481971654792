/* import external modules */
import React, { useEffect, useState } from 'react'

/* import internal modules */
import { Container, Toggle, Collapsed, Title, Label } from './styles'
import cname from '../../constants/classNameData'
import List from '../Common/List'
import Icon from '../Common/Icon'
import NotificationItem from './item'
import { getNotifications } from '../../services/Notifications/notifications'
import { useDispatch, useSelector } from 'react-redux'
import { setNotifications } from '../../redux/actions/notifications/notifications'

/** @description This is a functional component of the notifications menu
 *  @version 1.0.0
 *  @since 14/12/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name Notifications
 *  @returns {Component} Returns the items of notification list with their respective status
 **/

const Notifications = () => {
  const [active, setActive] = useState(false)
  const [newNotifications, setNewNotifications] = useState(true)
  const authUser = useSelector(({ user }) => user.user)
  const dispatch = useDispatch()
  const notifications = useSelector(
    ({ notifications }) => notifications.notifications
  )

  useEffect(() => {
    if (Boolean(authUser)) {
      fetchNotifications()
    }
  }, [authUser])

  /**
   * Check if there is some new notification and set flag to show indicator
   */
  useEffect(() => {
    const newNotificationsList = notifications.filter((n) => n.status === 0)
    setNewNotifications(newNotificationsList.length > 0)
  }, [notifications])

  useEffect(() => {
    if (Boolean(authUser)) {
      fetchNotifications()
    }
  }, [authUser])

  const handleToggle = () => {
    setActive(!active)
    if (!active) {
      document.body.classList.add('overflow-hidden-mobile')
    } else {
      document.body.classList.remove('overflow-hidden-mobile')
    }
    setNewNotifications(false)
  }

  const handleClose = () => {
    document.body.classList.remove('overflow-hidden-mobile')
    setActive(false)
  }

  /**
   * Send request to fetch list of notifications
   */
  const fetchNotifications = () => {
    getNotifications().then((response) => {
      dispatch(setNotifications(response.data))
    })
  }

  return (
    <Container tabIndex="0" onBlur={handleClose}>
      <Toggle onClick={handleToggle} className={`${active && 'active '+cname.notifications}`}>
        {newNotifications && (
          <Icon className="new-notifications" icon="approved" color="#FF7900" />
        )}
        <Icon icon="bell" color="#ffffff" />
      </Toggle>
      <Collapsed className={`${active && 'active'}`}>
        <List>
          <Title>Notificaciones</Title>
          {notifications.length > 0 ? (
            notifications.map((item, index) => (
              <NotificationItem
                key={index}
                campaignId={item.campaignId}
                type={item.type}
                subject={item.subject}
                status={item.status}
                notificationId={item.eventNotificationId}
                handleClose={handleClose}
              />
            ))
          ) : (
            <Label text>No tienes notificaciones en este momento</Label>
          )}
        </List>
      </Collapsed>
    </Container>
  )
}

export default Notifications
