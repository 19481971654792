/* import external modules */
import React from 'react'

/* import internal modules */
import { SectionContainer } from './styles'

const Section = (props) => {
  return <SectionContainer {...props}>{props.children}</SectionContainer>
}

export default Section
