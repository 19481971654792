export const MaterialStatus = {
  Rejected: 'Rechazado',
  Approved: 'Aprobado',
  Pending: 'Pendiente',
}

export const MaterialStatusCode = {
  Rejected: 0,
  Approved: 1,
  Pending: 2,
}

export const CampaignStatusTitle = {
  Canceled: 'Cancelada',
  InProgress: 'Campaña en curso',
  Finished: 'Campaña finalizada',
  PendingMaterials: 'Materiales en revisión',
  RejectedMaterials: 'Materiales rechazados',
  ApprovedMaterials: 'Materiales aprobados',
}

export const CampaignManualStatusCode = {
  Pending: 0,
  Active: 1,
  Canceled: 2,
}

export const CampaignDateStatusCode = {
  NotStarted: 0,
  InProgress: 1,
  Finished: 2,
}

export const CampaigntTypeStatus = {
  Portal: 1,
  Audience: 2
}

export const CampaignStatusRecord = {
  ManualStatus: { [CampaignManualStatusCode.Canceled] : { title: CampaignStatusTitle.Canceled, icon: 'canceled' } },
  DateStatus: {
    [CampaignDateStatusCode.InProgress] : { title: CampaignStatusTitle.InProgress, icon: 'chronometer' },
    [CampaignDateStatusCode.Finished] : { title: CampaignStatusTitle.Finished, icon: 'flag' }
  },
  MaterialStatus: {
    [MaterialStatusCode.Rejected] : { title: CampaignStatusTitle.RejectedMaterials, icon: 'img' },
    [MaterialStatusCode.Approved] : { title: CampaignStatusTitle.ApprovedMaterials, icon: 'img' },
    [MaterialStatusCode.Pending] : { title: CampaignStatusTitle.PendingMaterials, icon: 'img'}
  }
}