/* import external modules */
import styled from 'styled-components'

export const LabelContent = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  height: 30px;
  background: ${(props) =>
    props.background ? props.background : props.theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayMediumLight};
  font: 13px/30px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.grayDark};
  text-align: center;
  border-radius: 0px 4px 4px 4px;
  position: absolute;
  top: -15px;
  right: 10px;
  padding: 0 10px;
  z-index: 1;
  &::before {
    display: block;
    content: '';
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-top: 7px solid transparent;
    border-right: 7px solid ${({ theme }) => theme.colors.grayMedium};
    border-bottom: 7px solid ${({ theme }) => theme.colors.grayMedium};
    position: absolute;
    top: 0;
    left: -15px;
  }
  svg {
    margin-right: 8px;
    vertical-align: text-top;
  }
  .icon {
    position: absolute;
    left: 23px;
    top: 3px;
    path:last-child {
      fill: white;
    }
  }
`
