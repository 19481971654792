/* import external modules */
import React from 'react'
import PropTypes from 'prop-types'

/* import external modules */
import Button from '../Button'

/** @description This is a container functional component of ButtonPrimaryMd
 **  @version 1.0.0
 *  @since 10/08/2021
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name ButtonPrimaryMd
 *  @param {string} name Name button for cypress
 *  @param {string} text Text contain button
 *  @param {string} height Height button optional
 *  @param {string} width Width button optional
 *  @param {string} onClick Action to do
 *  @returns {Component} Returns the primary button
 **/

const ButtonPrimaryMd = ({ name, text, onClick, width, height, classt = false }) => {
  return (
    <Button
      name={name}
      text={text}
      classt={classt}
      width={width}
      height={height}
      font="500 16px/26px 'Heebo', sans-serif"
      background="#FFA217"
      boxShadow="2px 2px 0px #C85F00"
      color="#001634"
      borderRadius="4px"
      border="none"
      backgroundHover="#EB8D00"
      onClick={onClick}
    />
  )
}

ButtonPrimaryMd.defaultProps = {
  width: '170px',
  height: '35px',
}

ButtonPrimaryMd.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default ButtonPrimaryMd
