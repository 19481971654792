/* import external modules */
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const SectionContainer = styled.section`
  width: ${(props) => (props.width ? props.width : null)};
  height: ${(props) => (props.hegiht ? props.height : null)};
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : null)};
  padding-top: ${(props) => (props.pt ? props.pt : null)};
  padding-bottom: ${(props) => (props.pb ? props.pb : '40px')};
  padding-left: ${(props) => (props.pl ? props.pl : null)};
  padding-right: ${(props) => (props.pr ? props.pr : null)};
  padding: ${(props) => (props.p ? props.p : '20px')};
  margin: ${(props) => (props.m ? props.m : '0')};
  margin-top: ${(props) => (props.mt ? props.mt : null)};
  margin-bottom: ${(props) => (props.mb ? props.mb : '35px')};
  margin-left: ${(props) => (props.ml ? props.ml : null)};
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  background: ${(props) => (props.bg ? props.bg : props.theme.colors.white)};
  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 4px;
  position: relative;

  ${media.md`
    padding: ${(props) => (props.pm ? props.pm : '20px')};
  `}
`
