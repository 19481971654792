import styled from 'styled-components'

export const TooltipContent = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
`

export const TooltipCard = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grayMediumBorder};
  padding: 10px;
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.grayShadow};
  font: 12.5px/18px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.gray};
  border-radius: 4px;
  width: ${(props) => `${props.width}px`};
  letter-spacing: 0.2px;
  z-index: 9;
  @media (max-width: 768px) {
    max-width: 300px;
  }
`
export const TooltipArrow = styled.span`
  width: 0;
  height: 0;
  border-right: 0 solid transparent;
  border-top: ${(props) => (props.place === 'top-start' ? '10px' : '14px')}
    solid transparent;
  border-left: ${(props) => (props.place === 'top-start' ? '14px' : '10px')}
    solid ${({ theme }) => theme.colors.gray};
  border-bottom: 0px solid ${({ theme }) => theme.colors.gray};
  transform: ${(props) =>
    props.place === 'top-start' ? 'rotate(180deg)' : 'rotate(90deg)'};
  display: inline-flex;
  position: absolute;
  bottom: ${(props) => (props.place === 'top-start' ? '-9px' : '-11px')};
  left: ${(props) => (props.place === 'top-start' ? '3px' : null)};
  right: ${(props) => (props.place === 'top-end' ? '3px' : null)};
  z-index: 1;
  @media (max-width: 768px) {
    transform: ${(props) =>
      props.responsivePlace === 'top-start'
        ? 'rotate(180deg)'
        : 'rotate(90deg)'};
    border-top: ${(props) =>
        props.responsivePlace === 'top-start' ? '10px' : '14px'}
      solid transparent;
    border-left: ${(props) =>
        props.responsivePlace === 'top-start' ? '14px' : '10px'}
      solid ${({ theme }) => theme.colors.gray};
    bottom: ${(props) =>
      props.responsivePlace === 'top-start' ? '-9px' : '-11px'};
    left: ${(props) =>
      props.responsivePlace === 'top-start' ? '3px' : 'initial'};
    right: ${(props) =>
      props.responsivePlace === 'top-end' ? '3px' : 'initial'};
  }
`
export const TooltipArrowContent = styled.div`
  position: absolute;
  left: ${(props) => (props.place === 'top-start' ? '0px' : 'initial')};
  bottom: ${(props) =>
    props.place === 'top-start' || props.place === 'top-end' ? '35px' : null};
  right: ${(props) => (props.place === 'top-end' ? '0px' : 'initial')};
  @media (max-width: 768px) {
    left: ${(props) =>
      props.responsivePlace === 'top-start' ? '0px' : 'initial'};
    right: ${(props) => (props.responsivePlace === 'top-end' ? '0px' : null)};
  }
`
