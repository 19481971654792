import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  &:focus {
    outline: none;
  }
`
export const Toggle = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  user-select: none;
  outline: none;
  background: transparent;
  &:hover {
    cursor: pointer;
    user-select: none;
    outline: none;
    transition: background 0.25s;
    @media (max-width: 768px) {
      background: transparent;
    }
  }
  &.active {
    @media (max-width: 768px) {
      background: transparent;
    }
  }
  svg {
    margin-top: 9px;
    &.new-notifications {
      width: 10px;
      height: 10px;
      position: absolute;
      border: 1px solid ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      top: 0px;
      right: 10px;
    }
  }
`

export const Collapsed = styled.div`
  position: absolute;
  right: 0;
  top: 49px;
  overflow: hidden;
  width: 0;
  height: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.grayShadow};
  z-index: 1;
  &.active {
    width: 400px;
    height: auto;
    @media (max-width: 768px) {
      height: calc(100% - 50px);
      width: 100%;
      left: 0;
      top: 50px;
      bottom: 0;
    }
  }
  &:before {
    display: block;
    content: '';
    width: 100%;
    height: 5px;
    background: transparent;
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    box-shadow: 0px 3px 4px ${({ theme }) => theme.colors.grayShadow};
    z-index: 1;
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 0;
    box-shadow: none;
  }
  ul {
    overflow: auto;
    height: 100%;
    max-height: calc(100vh - 60px);
    @media (max-width: 768px) {
      max-height: calc(100vh - 50px);
    }
  }
`

export const IconLabel = styled.div`
  width: 40px;
  position: relative;
  svg {
    margin-top: 13px;
  }
  .icon {
    position: absolute;
    left: 12px;
    top: -5px;
    path:last-child {
      fill: white;
    }
  }
`
export const Label = styled.span`
  display: inline-block;
  color: ${({ theme }) => theme.colors.third};
  font: 14px/20px 'DMSans-Regular';
  padding: ${(props) => props.text && '0 20px 18px 20px'};
  width: calc(100% - 80px);
  align-self: center;
`
export const Title = styled.li`
  font: 18px/28px 'DMSans-Bold';
  padding: 10px 20px;
  color: #252627;
  list-style: none;
`

export const ListItem = styled.li`
  cursor: pointer;
  width: 100%;
  min-height: 80px;
  text-decoration: none;
  align-items: center;
  position: relative;
  list-style: none;
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 10px 20px 20px;
  background: ${({ theme }) => theme.colors.graySuperLightness};
  transition: background 0.25s;
  &.visited {
    background: white;
  }
  &:hover {
    background: #f3f3f3;
    @media (max-width: 768px) {
      background: inherit;
    }
  }
`

export const Button = styled.div`
  position: relative;
  svg {
    margin: 10px;
    &:hover ~ .badge-text {
      display: inline-block;
      right: 1px;
      top: -30px;
      bottom: auto;
      @media (max-width: 1024px) {
        display: none;
      }
    }
  }
  .badge-text {
    @media (max-width: 1024px) {
      display: none;
    }
  }
`
