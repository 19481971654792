/* import external modules */
import React, { useEffect, useState } from 'react'
import { Col } from 'styled-bootstrap-grid'

/* import internal modules */
import Img from '../Common/Img'
import P from '../Common/P'
import { Row } from './styles'
import useCustomerRoutes from '../../constants/routes'
import cname from '../../constants/classNameData'
import ButtonPrimaryMd from '../Common/ButtonPrimaryMd'

/** @description This is a functional component of of page without campaigns
 *  @version 1.0.0
 *  @since 12/11/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name WithoutCampaigns
 *  @returns {JSX.Element} Returns to information when there are no campaigns, and the button publish ad
 **/

const WithoutCampaigns = () => {
  const [url, setUrl] = useState('')
  const routes = useCustomerRoutes(url)

  const redirectBuy = () => window.open(routes.customersStartCampaignPage)

  useEffect(() => {
    const location = window.location.origin
    setUrl(location)
  }, [])

  return (
    <>
      <Row>
        <Col col>
          <Img
            icon="/images/without-campaigns-image.svg"
            alt="Imagen sin campañas"
          />
          <P font="24px/32px 'DMSans-Regular'" pt="24px">
            No tienes campañas en este momento
          </P>
          <P font="16px/24px'DMSans-Regular'" p="15px 0 25px 0">
            Publica nuevos anuncios y da a conocer tus productos y servicios de
            manera efectiva
          </P>
          <ButtonPrimaryMd
            name="publish-button"
            text="Crear campaña"
            classt={cname.first_button_bell}
            height="40px"
            onClick={redirectBuy}
          />
        </Col>
      </Row>
    </>
  )
}

export default WithoutCampaigns
