/* import internal modules */
import React from 'react'
import { useSelector } from 'react-redux'

/* import external modules */
import { LoadContent, LoadText } from './styles'
import Img from '../Img'
import P from '../P'

const Loading = () => {
  const loading = useSelector((state) => state.ui.loading)

  return (
    loading && (
      <LoadContent>
        <LoadText>
          <Img
            width="100"
            src="images/logo/cargador.gif"
            alt="Cargador El tiempo ads"
          />
          <P color="#4F5356" font="14px/26px DMSans-Medium">
            Cargando...
          </P>
        </LoadText>
      </LoadContent>
    )
  )
}

export default Loading
