import {
  SET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
} from '../../types/types'

import { notificationsStatus } from './../../../constants/notificationStatus'

const initialState = {
  notifications: [],
}

const notificationsReducer = (state = initialState, action) => {
  let id
  let status
  let notifications
  switch (action.type) {
    case SET_NOTIFICATIONS:
      return Object.assign({}, state, {
        notifications: action.payload.notifications,
      })
    case DELETE_NOTIFICATION:
      id = action.payload.notificationId
      const index = state.notifications.findIndex(
        (i) => i.eventNotificationId === id
      )
      state.notifications[index].status = notificationsStatus.deleted
      return Object.assign({}, state, { ...state.notifications })
    case UPDATE_NOTIFICATION_STATUS:
      id = action.payload.notificationId
      status = action.payload.status
      notifications = state.notifications.map((n) =>
        n.eventNotificationId === id ? { ...n, status } : n
      )
      return Object.assign({}, state, { notifications })
    default:
      return state
  }
}

export default notificationsReducer
