import React from 'react'
import PropTypes from 'prop-types'
import {
  RejectContentCause,
  RejectContentDate,
  RejectContentWrapper,
} from './styles'
import Icon from '../../Common/Icon'

/** @description This is a container functional component of const CampaignRejectContent = ({ review, date }) => {

 **  @version 1.0.0
 *  @since 05/04/2021
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name CampaignReject
 *  @param {string} review Review and comments because element was reject
 *  @param {string} date Date limit for upload new article
 *  @returns {Component} Returns the container component of the campaign reject of content
 **/

const CampaignRejectContent = ({ review, date }) => {
  return (
    <RejectContentWrapper>
      <RejectContentCause>
        <Icon icon="warning" color="#E20000" marginRight="10px" />
        <p>
          <b>Motivo del rechazo: </b> {review}
        </p>
      </RejectContentCause>
      <RejectContentDate>
        Por favor envía los ajustes de tu contenido antes del <b>{date}</b> al
        correo <b>pabsal@eltiempo.com</b>
      </RejectContentDate>
    </RejectContentWrapper>
  )
}

CampaignRejectContent.propTypes = {
  review: PropTypes.string,
  date: PropTypes.string,
}
export default CampaignRejectContent
