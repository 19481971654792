/* import external modules */
import React from 'react'

/* import internal modules */
import { LabelContent } from './styles'
import Icon from '../Icon'

const Label = ({ title, iconName, background, iconExtra, iconColor }) => {
  return (
    <LabelContent background={background}>
      {iconExtra && (
        <Icon className="icon" icon={iconExtra} color={iconColor} />
      )}
      <Icon icon={iconName} color="#8e9194" />
      {title}
    </LabelContent>
  )
}

export default Label
