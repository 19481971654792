import _ from "lodash";

export const groupCities = (advertisingCities) => {
  let cities = []
  if (Boolean(advertisingCities)) {
    advertisingCities.forEach((item) => {
      if (Boolean(item.formatBySectionOnDisplay.assingmentCityId)) {
        cities.push(item.formatBySectionOnDisplay.assingmentCity.description)
      } else {
        cities.push('N/A')
      }
    })
  }

  return _.join(_.uniq(cities), ', ')
}