/* import external modules */
import React from 'react'

/* import internal modules */
import { Logo } from './styles'

const LogoComponent = ({ url, img, width, alt, ...props }) => {
  return (
    <Logo {...props}>
      <img src={'/' + img} width={width} alt={alt} />
    </Logo>
  )
}

export default LogoComponent
