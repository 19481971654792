import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, WrapperMain } from './styles';
//import TagManager from 'react-gtm-module';
import { hashUser } from '../../../herpers/hasUser'

const Main = ({ children }) => {
  const [hasExecuted, setHasExecuted] = useState(false);
  const [bandera, setBandera] = useState(0);
  const dataAuth = useSelector((state) => state.user.user);

  useEffect(() => {
    if (dataAuth && !hasExecuted && bandera === 0) {
      const dataUserBoolean = Object.keys(dataAuth).length > 0;
      hashUser(dataUserBoolean, dataUserBoolean ? dataAuth.email : null);
      setBandera(1);
      setHasExecuted(true);
    }
  }, [dataAuth, hasExecuted, bandera]);

  return (
    <WrapperMain>
      <Container fluid>{children}</Container>
    </WrapperMain>
  );
};

export default Main;