/* import external modules */
import React from 'react'

/* import internal modules */
import { Ul } from './styles'

const List = ({ children }) => {
  return <Ul>{children}</Ul>
}

export default List
