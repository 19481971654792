/* import external modules */
import React from 'react'

/* import internal modules */
import Img from '../../../Common/Img'
import { PictureContainer, HomeTemplate } from './styles'

export const ImageTemplateEditor = () => (
  <PictureContainer>
    <source
      srcSet="images/content/content-preview-mobile.jpg"
      media="(max-width: 991px)"
      alt="content-preview-mobile"
    />
    <Img src="images/content/content-preview.jpg" alt="content-preview" />
  </PictureContainer>
)

export const ImageTemplateCarrousel = () => (
  <picture>
    <source
      srcSet="images/content/previsualizacion-contenido-home-eltiempo-mobile.jpg"
      media="(max-width: 991px)"
      alt="home-preview-mobile"
    />
    <HomeTemplate src="images/content/home-preview.jpg" alt="home-preview" />
  </picture>
)
