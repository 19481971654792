/* import external modules */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

/* import internal modules */
import Icon from '../../Common/Icon'
import {
  CampaignItemCause,
  CampaignRejectWrapper,
  CampaignItemDate,
  CampaignInfo,
  CampaignUpload,
  CampaingError,
} from './styles'
import Upload from '../../Common/Upload'
import Alert from '../../Common/Alert'
import { setLoading } from '../../../redux/actions/ui/ui'
import ModalUploadMaterialReject from '../ModalUploadMaterialReject'

/** @description This is a container functional component of AdvertiseList
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name CampaignReject
 *  @param {string} review Review and comentarios because element was reject
 *  @param {string} date Date limit for upload new image
 *  @param {number} width Width of advertisements
 *  @param {number} height Height of advertisements
 *  @param {string} num Id of campaign for upload button
 *  @param {number} weight Weight of advertisements
 *  @param {string} imageTemplate Image template svg for image preview
 *  @param {string} device Type of device of each advertisements
 *  @param {string} format Name format of each advertisements
 *  @returns {Component} Returns the container component of the advertise list
 **/

const CampaignReject = ({
  review,
  date,
  width,
  height,
  num,
  weight,
  imageTemplate,
  device,
  format,
  adId,
  advertisingTypeId,
  nameFormat
}) => {
  const [messageAlert, setMessageAlert] = useState({
    message: '',
    state: '',
    show: false,
  })
  const [imageUrl, setImageUrl] = useState('')
  const [imageToUpload, setImageToUpload] = useState('')
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)

  const handleImage = (image, message, status) => {
    switch (status) {
      case true:
        setImageToUpload(image)
        const createUrl = URL.createObjectURL(image)

        let reader = new FileReader()
        reader.readAsDataURL(image)
        reader.onload = () => {
          setOpenModal(true)
          dispatch(setLoading(false))
        }
        reader.onerror = function (error) {
          console.error('Error: ', error)
        }
        setImageUrl(createUrl)
        setMessageAlert({
          ...messageAlert,
          show: false,
          message: '',
          state: '',
        })
        break

      case false:
        setImageUrl('')
        setMessageAlert({
          ...messageAlert,
          message: message,
          state: 'error',
          show: true,
        })
        dispatch(setLoading(false))
        break

      case 'loading':
        setMessageAlert({
          ...messageAlert,
          message: '',
          state: '',
          show: false,
        })
        dispatch(setLoading(true))
        break

      default:
        setImageUrl('')
        setOpenModal(false)
        dispatch(setLoading(false))
        setMessageAlert({
          ...messageAlert,
          message: '',
          state: '',
          show: false,
        })
        break
    }
  }

  return (
    <>
      <CampaignRejectWrapper>
        <CampaignItemCause>
          <Icon icon="warning" color="#E20000" marginRight="10px" />
          <div>
            <b>Motivo del rechazo: </b> {review}
          </div>
        </CampaignItemCause>
        <CampaignItemDate>
          Por favor carga un nuevo archivo antes del <b>{date}</b>
        </CampaignItemDate>
        <CampaignInfo>
          Tamaño:{' '}
          <b>
            {width} x {height} pixeles
          </b>
          <br />
          Peso máximo: <b>{weight / 1000} kb</b>
        </CampaignInfo>
        <CampaignUpload>
          <Upload
            className="zu-upload"
            num={num}
            handleImage={handleImage}
            widthImage={width}
            heightImage={height}
            maxWeight={weight}
            unitMaxWeight="B"
          />
        </CampaignUpload>
      </CampaignRejectWrapper>
      {messageAlert.show && (
        <CampaingError>
          <Alert title={messageAlert.message} state={messageAlert.state} />
        </CampaingError>
      )}
      {openModal && (
        <ModalUploadMaterialReject
          open={openModal}
          onClose={() => {
            setOpenModal(false)
          }}
          imageTemplate={imageTemplate}
          imageUrlUpload={imageUrl}
          imageToUpload={imageToUpload}
          device={device}
          format={format}
          adId={adId}
          advertisingTypeId={advertisingTypeId}
          nameFormat={nameFormat}
        />
      )}
    </>
  )
}

CampaignReject.propTypes = {
  review: PropTypes.string,
  date: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  num: PropTypes.string,
  weight: PropTypes.number,
  imageTemplate: PropTypes.string,
  device: PropTypes.string,
  format: PropTypes.string,
}

export default CampaignReject
