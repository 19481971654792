/* import external modules */
import React from 'react'

/* import internal modules */
import { Text } from './styles'

const Copyright = (props) => {
  return (
    <Text {...props}>
      COPYRIGHT © 2020 EL TIEMPO Casa Editorial. Prohibida su reproducción total
      o parcial, así como su traducción a cualquier idioma sin autorización
      escrita de su titular.
    </Text>
  )
}

export default Copyright
