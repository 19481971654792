/* import external modules */
import React from 'react'
import { useSelector } from 'react-redux'

/* import internal modules */
import Main from '../Main'
import Header from '../Header'
import Footer from '../Footer'
import AlertFixed from '../AlertFixed'
import Loading from '../Loading'

/**
 * @description This functional component is Layout for header y footer
 * @version 1.0.0
 * @since 18/11/2020
 * @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 */

const Layout = ({ children }) => {
  const [globalMessagesMessage, globalMessagesOpen, globalMessagesStatus] =
    useSelector(({ globalMessages }) => [
      globalMessages.messages.message,
      globalMessages.messages.open,
      globalMessages.messages.status,
    ])

  return (
    <>
      <Header />
      <Main>{children}</Main>
      <Footer />
      <Loading />
      {globalMessagesOpen && (
        <AlertFixed
          status={globalMessagesStatus}
          text={globalMessagesMessage}
        />
      )}
    </>
  )
}

export default Layout
