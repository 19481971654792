/* import external modules */
import React from 'react'
import moment from 'moment'

/* import internal modules */
import Img from '../../../Common/Img'
import Modal from '../../../Common/Modal'
import P from '../../../Common/P'
import { ImageTemplateEditor, ImageTemplateCarrousel } from './imageTemplate'
import messages from '../../../../constants/messages'
import InfoStatic from './infoStatic'
import {
  ContainerWrapper,
  ContainerTemplate,
  ImagesWrapper,
  ImageContainer,
  ContentStaticInfo,
  ContentParagraph,
  TitleContent,
  LeadContent,
  ContentCard,
  CardImage,
  TitleSponsoredContent,
  DateArticle,
  TitleArticle,
} from './styles'
import { getCurrentResource } from '../../../../herpers/listCampaigns'
import { descryptImgUrl } from '../../../../herpers/descriptImgUrl'

const ModalViewArticle = ({
  isOpen,
  numAdvertise,
  data: { title, lead, paragraph, resourcesContents, dateStart },
  ...props
}) => {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
  const resource = getCurrentResource(resourcesContents)
  const urlResource = `${API_ENDPOINT}/${descryptImgUrl(resource.src)}`
  const date = moment(dateStart).format('MMMM DD [DE] YYYY').split('.').join('')
  return (
    <Modal open={isOpen} {...props} width="887px" className="modal-content">
      <P font="14px/22px 'DMSans-Regular'">
        Pauta {numAdvertise}: <b>Contenido Patrocinado</b>
      </P>
      <ContainerWrapper>
        <ImageTemplateEditor />
        <ContainerTemplate>
          <TitleContent>{title}</TitleContent>
          <LeadContent>{lead}</LeadContent>
          <ImageContainer>
            <Img src={urlResource} alt="Image de ejemplo" />
          </ImageContainer>
          <ContentStaticInfo>
            <InfoStatic />
            <ContentParagraph
              dangerouslySetInnerHTML={{
                __html: paragraph,
              }}></ContentParagraph>
          </ContentStaticInfo>
        </ContainerTemplate>
      </ContainerWrapper>
      <ImagesWrapper>
        <ImageTemplateCarrousel />
        <ContentCard>
          <CardImage src={urlResource} />
          <TitleSponsoredContent>
            {messages.messageDefaultSponsoredTitle}
          </TitleSponsoredContent>
          <DateArticle>{date}</DateArticle>
          <TitleArticle>{title}</TitleArticle>
        </ContentCard>
      </ImagesWrapper>
    </Modal>
  )
}

export default ModalViewArticle
