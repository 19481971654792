/* import external modules */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* import internal modules */
import CampaignsItem from '../CampaignsItem'
import { scrollToId } from '../../../herpers/scrollToId'
import { getFormatDate } from '../../../herpers/listCampaigns'

/** @description This is a container functional component of CampaignsList
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name CampaignsList
 *  @param {array} campaignsList List of purchased campaigns
 *  @returns {Component} Returns the container component of the Campaigns List
 **/

const CampaignsList = ({ campaignsList }) => {
  const [activeState, setActiveState] = useState([])

  const toggleAccordion = (index, campaignId) => {
    let newActiveState = activeState.slice()
    if (activeState[index] === true) {
      newActiveState[index] = !newActiveState[index]
    } else {
      newActiveState = activeState.map((i, idx) => idx === index)
      newActiveState[index] = true
    }
    
    setActiveState(newActiveState)
    scrollToId('campaignsItem-' + campaignId)
  }

  return campaignsList.map((item, index) => {
    const adsDisplay = item.advertisements || []
    const adsSponsored = item.adSponsoredContents || []
    const totalAds = adsDisplay.concat(adsSponsored)
    return (
      <CampaignsItem
        key={index}
        campaignName={item.name}
        campaignId={item.campaignId}
        buyDate={getFormatDate(item.createdAt)}
        onClick={() => toggleAccordion(index, item.campaignId)}
        active={activeState[index]}
        ads={totalAds}
        advertisementsType={item.advertisements || []}
        adSponsoredContentsType={item.adSponsoredContents || []}
        manualStatus={item.manualStatus}
        materialStatus={item.materialStatus}
        dateStatus={item.dateStatus}
        id={'campaignsItem-' + item.campaignId}
      />
    )
  })
}

CampaignsList.propTypes = {
  campaignsList: PropTypes.array,
}

export default CampaignsList
