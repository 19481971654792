/* import external modules */
import styled from 'styled-components'
import {
  Container as ContainerComponent,
  Row as RowComponent,
  media,
} from 'styled-bootstrap-grid'

/* import internal modules */

export const Header = styled.header`
  width: 100%;
  background: ${({ theme }) => theme.colors.blue_darkness};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  position: ${(props) => (props.sticky ? 'relative' : 'fixed')};
  z-index: 102;
  top: 0;
  @media print {
    /* display: none; */
    box-shadow: none;
  }

  .publish-button {
    @media (max-width: 767px) {
      position: fixed;
      bottom: 0;
      width: 100%;
      left: 0;
      padding: 10px 15px;
      background: #001634;
    }
    button {
      @media (max-width: 500px) {
        line-height: 35px;
        height: 40px;
        font-size: 20px;
      }
    }
  }
`
export const Container = styled(ContainerComponent)`
  max-width: 1200px;
`

export const Row = styled(RowComponent)`
  height: 60px;
  padding: 0 15px;
  img {
    width: 130px;
    ${media.md`
      width: 173px;
    `}
    ${media.lg`
      width: 193px;
    `}
  }
  @media (max-width: 768px) {
    height: 50px;
  }
  .logo-login {
    @media (max-width: 768px) {
      left: 30px;
      position: relative;
    }
  }
`
export const ModalCookies = styled.div`
    display: block;
    position: fixed;
    z-index: 99999999;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    overflow: auto; /* Enable scroll if needed */
    -webkit-animation-name: fadeIn; /* Fade in the background */
    -webkit-animation-duration: 0.4s;
    animation-name: fadeIn;
    animation-duration: 0.4s;

  .cookie-modal-content{
    position: fixed;
    bottom: 0;
    background-color: #fefefe;
    width: 100%;
    -webkit-animation-name: slideIn;
    -webkit-animation-duration: 0.4s;
    animation-name: slideIn;
    animation-duration: 0.4s;
    border-top: 2px solid rgb(255, 162, 23);
    -webkit-box-shadow: 0px 0px 35px -14px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 35px -14px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 35px -14px rgba(0,0,0,0.55);
  }

  .cookie-modal-body {
    padding: 16px 20px;
    display: flex;
    max-width: 80%;
    margin: 0 auto;
  }
  .cookie-content{
    font: 16px / 22px DMSans-Regular;
    color: rgb(79, 83, 86)
  }
  .cookie-btn{
    font-family: Heebo, sans-serif;
    font-weight: 500;
    line-height: 10px;
    transition: 0.5s;
    margin: auto 20px;
    padding: 12px 32px;
    cursor: pointer;
  }

  .link-cookie-content{
    color: #2553BC;
    text-decoration: underline;
  }
  @media (max-width: 720px) {
    .cookie-modal-body{
        display: block;
        max-width: 100%;
    }
    .cookie-content{
        width: 100%;
        margin: 20px 0 32px;
    }
    .cookie-btn{
        margin: 0 auto;
        display: block;
        text-align: center;
    }
    .cookie-btn{
        margin-bottom: 4px;
        padding: 16px 32px;
    }
  }
`

export const Nav = styled.nav``

export const NavContent = styled.ul`
  margin: 0;
  padding: 0;
`

export const NavItem = styled.li`
  list-style: none;
  display: inline-block;
  &.login-button {
    display: ${(props) => (props.sticky ? 'inline-block' : 'none')};
    ${media.md`
      margin-right: 24px;
      display: inline-block;
    `};
  }
  &.publish-button {
    &.publish-login {
      button {
        @media (max-width: 767px) {
          width: 100%;
          overflow: hidden;
          line-height: 35px;
          height: 35px;
        }
      }
    }
    button {
      width: 155px;
      ${media.md`
        width: 170px;
      `};
    }
  }
  &.menu {
    margin-right: 15px;
    @media (max-width: 768px) {
      position: absolute;
      top: 7px;
      left: 7px;
    }
  }
  &.notifications {
    margin-right: 8px;
    vertical-align: middle;
    @media (max-width: 767px) {
      margin-right: -10px;
    }
  }
`
