/* import external modules */
import React, { useState } from 'react'

/* import internal modules */
import ModalViewArticle from '../ViewArticle/ModalViewArticle'
import { ButtonContent } from './styles'
import Img from '../../Common/Img'

const TypeProductContent = ({ numAdvertise, data }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleClose = () => setIsOpen(false)
  const handleOpen = () => setIsOpen(true)

  return (
    <>
      <ButtonContent onClick={handleOpen}>
        <Img src="images/content/zu-contenido-miniatura.jpg" />
      </ButtonContent>
      {isOpen && (
        <ModalViewArticle
          isOpen={isOpen}
          numAdvertise={numAdvertise}
          data={data}
          onClose={handleClose}
        />
      )}
    </>
  )
}

export default TypeProductContent
