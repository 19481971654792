import styled from 'styled-components'

export const Input = styled.input`
  display: none;
`
export const ButtonUpload = styled.span`
  width: max-content;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  height: 40px;
  background: transparent;
  cursor: pointer;
  font: 16px/26px 'DMSans-Medium';
  padding: 0 16px;
  color: ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 10px;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
    color: white;
    path {
      fill: white;
    }
    @media (max-width: 768px) {
      background: transparent;
      color: ${({ theme }) => theme.colors.secondary};
      path {
        fill: ${({ theme }) => theme.colors.secondary};
      }
    }
  }
`

export const Label = styled.label`
  display: inline-block;
`
export const ContenUpload = styled.div`
  display: flex;
  @media (max-width: 767px) {
    justify-content: center;
    margin-bottom: 42px;
  }
`
