/* import external modules */
import styled from 'styled-components'

export const Card = styled.div`
  overflow-wrap: break-word;
  word-break: break-word;
  @media (max-width: 1024px) {
    margin-bottom: 20px;
  }
  &.Date div:last-child {
    text-transform: capitalize;
  }
`

export const TextTitle = styled.div`
  font: 13px/24px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.grayLight};
  display: flex;
  line-height: 18px;
  align-items: center;
  @media (max-width: 1024px) {
    font: 13px/24px ${({ theme }) => theme.font.regular};
  }
`

export const TextPrimary = styled.div`
  font: 18px/26px 'DMSans-Bold';
  color: ${({ theme }) => theme.colors.third};
  @media (max-width: 1024px) {
    font: 14px/24px 'DMSans-Bold';
    display: flex;
    white-space: nowrap;
  }
`
export const TextSecundary = styled.div`
  font: 16px/26px 'DMSans-Bold';
  color: ${({ theme }) => theme.colors.third};
`
export const Text = styled.div`
  font: 14px/24px 'DMSans-Medium';
  color: ${({ theme }) => theme.colors.third};
`
