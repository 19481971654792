/* import external modules */
import styled from 'styled-components'

export const Button = styled.button`
  align-items: center;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: flex;
  font: 14px/20px 'DMSans-Regular';
  height: 24px;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 12px;
  padding: 0;
  svg {
    margin-right: 10px;
    flex-shrink: 0;
  }
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: 1168px) {
    margin: 12px 0 12px 10px;
  }
  @media (max-width: 1024px) {
    justify-content: start;
    margin-left: 0;
    &:hover {
      text-decoration: underline;
    }
  }
`
