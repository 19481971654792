// Import external modules
import axios from 'axios'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

/**
 * Get info about auth user
 * @param {*} token
 */
export const getAuthInfo = (token) => {
  const body = {
    token,
  }
  const url = `${API_ENDPOINT}/authorization`
  return axios.post(url, body, {
    headers: { 'Content-Type': 'application/json' },
  })
}

/**
 * Path for token to expire
 * @param {string} token 
 */
export const logout = (token) => {
  const body = {
    token
  };
  const url = `${API_ENDPOINT}/logout`;
  return axios.post(url, body).then(r => r.data);
}