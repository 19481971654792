/* import external modules */
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const ButtonMain = styled.button`
  disabled: ${(props) => (props.disabled ? props.disabled : null)};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => (props.height ? props.height : null)};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'center')};
  letter-spacing: ${(props) =>
    props.letterSpacing ? props.letterSpacing : null};
  color: ${(props) => (props.color ? props.color : null)};
  background: ${(props) => (props.background ? props.background : null)};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : null};
  box-shadow: ${(props) => (props.boxShadow ? props.boxShadow : null)};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '4px'};
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  border: ${(props) => (props.border ? props.border : 'none')};
  line-height: ${(props) => (props.lineHeight ? props.lineHeight : null)};
  font-family: 'DMSans-Regular';
  font: ${(props) => (props.font ? props.font : null)};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : null)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : null)};
  transition: all 0.3s;
  margin-right: ${(props) => (props.mr ? props.mr : null)};
  display: ${(props) => (props.icon ? 'flex' : null)};
  &:hover {
    color: ${(props) => (props.color ? props.color : null)};
    background: ${(props) => (props.background ? props.background : null)};
    transition: none;
  }
  &:focus {
    outline: none;
  }
  &:disabled {
    &:hover {
      background: ${({ theme }) => theme.colors.grayDisabled};
    }
    pointer-events: none;
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colors.grayDisabled};
    box-shadow: 2px 2px 0px ${({ theme }) => theme.colors.graySuperDark};
  }
  ${media.md`
    &:hover {
      color: ${(props) => (props.colorHover ? props.colorHover : null)};
      background: ${(props) =>
        props.backgroundHover ? props.backgroundHover : null};
      transition: all 0.3s;
    }
  `}
  @media print {
    display: none;
  }
`
