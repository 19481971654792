import styled from 'styled-components'

export const CampaignRejectWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
  }
  
`

export const CampaignItemCause = styled.div`
  width: 400px;
  display: flex;
  align-items: center;
  font: 14px/20px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.error};

  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  @media (max-width: 1024px) {
    margin: 0 0 30px 0;
    width: 100%;
    justify-content: center;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`
export const CampaignItemDate = styled.div`
  border-radius: 4px;
  font: 14px/20px 'DMSans-Regular';
  background: ${({ theme }) => theme.colors.graySuperLightness};
  color: ${({ theme }) => theme.colors.grayDark};
  height: auto;
  text-align: center;
  b {
    text-transform: capitalize;
  }
  @media (max-width: 1024px) {
    width: 100%;
    height: auto;
    text-align: center;
    margin: 0 0 30px 0;
  }
`
export const CampaignInfo = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
  font: 14px/20px 'DMSans-Regular';
  margin-left: 20px;
  margin-right: 20px;
  line-height: 24px;
  @media (max-width: 1024px) {
    margin: 0 0 30px 0;
    text-align: center;
    line-height: 30px;
  }
`
export const CampaignUpload = styled.div`
  .zu-upload {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin: 0 0 5px 0;
  }
`
export const CampaingError = styled.div`
  text-align: right;
  margin-top: 30px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
  }
  @media (max-width: 960px) {
    text-align: left;
  }
`
