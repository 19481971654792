import styled, { css } from 'styled-components'
import { Svg } from '../Icon/styles'

export const Span = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
  padding-left: 10px;
  white-space: nowrap;
`

export const Link = styled.a`
  cursor: pointer;
  display: flex;
  font: 14px/20px 'DMSans-Regular';
  text-decoration: none;
  justify-content: ${(props) => (props.type == 'Mathilde' ? 'normal' : 'center')};
  ${Svg} {
    flex-shrink: 0;
  }
  @media (min-width: 1024px) {
    ${Svg} {
      margin-left: 0px;
    }
  }
  .icon-fill {
    display: none;
  }
  &:hover .icon-fill {
    display: block;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &:hover .icon-outline {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
`
