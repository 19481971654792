/* import external modules */
import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

/* import internal modules */
import CampaingPage from '../../pages/Campaign'

const Routes = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={CampaingPage} />
      </Switch>
    </BrowserRouter>
  )
}
export default Routes
