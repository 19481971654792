// import external modules
import { createStore, applyMiddleware, compose } from 'redux'
import thunk from "redux-thunk";

// import internal modules
import rootReducer from '../reducers/rootReducer'

const composeEnchancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  composeEnchancers(applyMiddleware(thunk))
)

export { store }
