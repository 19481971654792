/* import external modules */
import React, { useRef, useState } from 'react'

/* import internal modules */
import {
  TooltipContent,
  TooltipCard,
  TooltipArrow,
  TooltipArrowContent,
} from './styles'

/** @description This functional component is tooltip
 *  @version 1.0.0
 *  @since 18/05/2020
 *  @author Yadurani Lopez Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Tooltip
 *  @param {string} title is content tooltip
 *  @param {string} place is position tooltip "top-start", "top-end"
 *  @param {string} responsivePlace is position tooltip in responsive "top-start", "top-end"
 *  @param {component} children is child
 *  @returns {Component} Returns components with any customized text
 **/

const Tooltip = ({ title, children, width, ...props }) => {
  const node = useRef()
  const [isVisible, setState] = useState(false)
  const toggle = () => setState(!isVisible)

  return (
    <TooltipContent ref={node} onMouseOverCapture={toggle} onMouseOut={toggle}>
      {children}
      {isVisible && (
        <TooltipArrowContent {...props}>
          <TooltipCard width={width}>{title}</TooltipCard>
          <TooltipArrow {...props} />
        </TooltipArrowContent>
      )}
    </TooltipContent>
  )
}

export default Tooltip
