/* import external modules */
import styled from 'styled-components'
import { Row as RowComponent } from 'styled-bootstrap-grid'

export const Row = styled(RowComponent)`
  text-align: center;
  img {
    @media (max-width: 768px) {
      width: 126px;
      height: auto;
    }
  }
  button {
    @media (max-width: 768px) {
      width: 240px;
    }
  }
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`
