// Global messages types
export const FETCH_SET_GLOBAL_MESSAGE = 'FETCH_SET_GLOBAL_MESSAGE'

// Ui
export const FETCH_LOADING = 'FETCH_LOADING'

// Campaigns
export const SET_CAMPAIGNS = 'SET_CAMPAIGNS'
export const SET_FETCHING_CAMPAIGNS = 'SET_FETCHING_CAMPAIGNS'
export const SET_CLEAR_CAMPAIGNS = 'SET_CLEAR_CAMPAIGNS'

export const FETCH_MESSAGES = 'FETCH_MESSAGES'

// User Action Types
export const HANDLE_USER = 'HANDLE_USER'
export const SET_TOKEN = 'SET_TOKEN'
export const WERE_COOKIES_ACCEPTED = 'WERE_COOKIES_ACCEPTED'

// Notifications
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const UPDATE_NOTIFICATION_STATUS = 'UPDATE_NOTIFICATION_STATUS'
