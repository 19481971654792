/** import external modules  */
import React from 'react'

/** import internal modules  */
import CampaignsAndMaterialsPage from '../components/CampaignAndMaterials/CampaignsAndMaterialsPage'

/**
 *  @description This is a Campaign page
 *  @version 1.0.0
 *  @since 12/11/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name Campaign
 *  @returns {Component} Returns page campaign component
 **/

const Campaign = () => {
  return (
    <>
    <CampaignsAndMaterialsPage />      
    </>
    )
}

export default Campaign
