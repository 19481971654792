/* import external modules */
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

/* import internal modules */
import { ListItem, IconLabel, Label, ItemContainer, Button } from './styles'
import Icon from '../Common/Icon'
import Badge from '../Common/Badge'
import ModalAlert from '../Common/ModalAlert'
import { setGlobalMessage } from '../../redux/actions/globalMessages/globalMessages'
import messages from '../../constants/messages'
import { scrollToId } from '../../herpers/scrollToId'
import {
  deleteNotification as deleteNotificationService,
  updateNotification,
} from '../../services/Notifications/notifications'
import {
  removeNotification,
  updateNotificationStatus,
} from '../../redux/actions/notifications/notifications'

/** @description This is a functional component of the notification items
 *  @version 1.0.0
 *  @since 14/12/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name NotificationItem
 *  @param {string} type type of notification
 *  @param {string} subject text of notification
 *  @param {number} status to check if a notification has been visited
 *  @param {number} notificationId notification identifier
 *  @param {function} handleClose function to hide notifications after click event
 *  @returns {Component} Returns the items in the notification list with their respective status
 **/

const NotificationItem = ({
  type,
  subject,
  notificationId,
  status,
  handleClose,
  campaignId,
}) => {
  const [openModal, setOpenModal] = useState(false)
  const dispatch = useDispatch()
  const [iconName, setIconName] = useState('error')
  const [hasClass, setHasClass] = useState(false)

  useEffect(() => {
    let icon = ''
    switch (type) {
      case 'MATERIALS_REJECTED':
        icon = 'error'
        setHasClass(true)
        break
      case 'MATERIALS_APPROVED':
        icon = 'approved'
        setHasClass(true)
        break
      case 'CAMPAIGN_STARTED':
        icon = 'chronometer'
        break
      case 'CAMPAIGN_FINISHED':
        icon = 'flag'
        break
      case 'CAMPAIGN_CANCELED':
        icon = 'canceled'
        break
      case 'REPORT_SENT':
        icon = 'file'
        break
      default:
        icon = 'error'
    }
    setIconName(icon)
  }, [])

  const handleDeleteNotification = () => {
    handleCloseModal()
    deleteNotification()
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleToogleAlert = () => {
    dispatch(
      setGlobalMessage({
        open: true,
        message: messages.deleteNotificationSuccessAlert,
        status: 'success',
      })
    )
    setTimeout(
      () =>
        dispatch(setGlobalMessage({ open: false, message: '', status: '' })),
      5000
    )
  }

  /**
   * Notification click event
   */
  const handleClick = () => {
    if (status !== 1) {
      updateStatus()
    }
    handleClose()
    scrollToId('campaignsItem-' + campaignId)
  }

  /**
   * Send request to change notification status
   */
  const updateStatus = () => {
    const body = { status: 1 }
    updateNotification(notificationId, body).then((response) => {
      dispatch(updateNotificationStatus(notificationId, 1))
    })
  }

  /**
   * Send request to delete notification
   */
  const deleteNotification = () => {
    deleteNotificationService(notificationId)
      .then((response) => {
        dispatch(removeNotification(notificationId))
        handleToogleAlert()
      })
      .catch((error) => {
        dispatch(
          setGlobalMessage({
            open: true,
            message: 'No se pudo borrar la notificación',
            status: 'error',
          })
        )
        setTimeout(
          () =>
            dispatch(
              setGlobalMessage({ open: false, message: '', status: '' })
            ),
          5000
        )
      })
  }

  return (
    <ListItem>
      <ItemContainer
        onClick={handleClick}
        className={status === 1 && 'visited'}>
        <>
          <IconLabel>
            <Icon
              className={hasClass ? 'icon' : ''}
              icon={iconName}
              color={
                ['MATERIALS_REJECTED'].includes(type)
                  ? '#e20000'
                  : ['MATERIALS_APPROVED'].includes(type)
                  ? '#00ae34'
                  : '#8E9194'
              }
            />
            {['MATERIALS_REJECTED', 'MATERIALS_APPROVED'].includes(type) && (
              <Icon icon="img" color="#8E9194" />
            )}
          </IconLabel>
          <Label dangerouslySetInnerHTML={{ __html: subject }} />
        </>
        <Button onClick={() => setOpenModal(true)}>
          <Icon icon="trash" color="#8E9194" />
          <Badge className="badge-text" text={messages.deleteNotification} />
        </Button>
        {openModal && (
          <ModalAlert
            open={openModal}
            onClose={handleCloseModal}
            titleAlert={messages.messageTitleDeleteNotification}
            contentAlert={messages.messageDeleteNotification}
            onClickAction={handleDeleteNotification}
            titleAction={messages.deleteNotification}
          />
        )}
      </ItemContainer>
    </ListItem>
  )
}

NotificationItem.propTypes = {
  type: PropTypes.string,
  subject: PropTypes.string,
  notificationId: PropTypes.number,
  status: PropTypes.number,
  handleClose: PropTypes.func,
  campaignId: PropTypes.number,
}

export default NotificationItem
