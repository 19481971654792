import axios from 'axios'
import {TOKEN_DB_KEY} from '../constants/localStorage'
import {LOGIN_URL} from '../constants/auth'

/** @description Calls an interceptor each time a request is called and
 *  adds the authorization token to the request headers
 *  @version 1.0.0
 *  @since 17/12/2020
 *  @author Juan Felipe Vásquez <juavas.ext@eltiempo.com>
 **/
axios.interceptors.request.use((config) => {
    const token = window.sessionStorage.getItem(TOKEN_DB_KEY)
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    return config
})

/** @description Calls an interceptor each time a response is received
 *  and validate status code; if it's 401 (unauthorized) redirect to
 *  login page
 *  @version 1.0.0
 *  @since 17/12/2020
 *  @author Juan Felipe Vásquez <juavas.ext@eltiempo.com>
 **/
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            window.sessionStorage.clear()
            window.location.replace(LOGIN_URL);
        }
    }
)
