import { Container } from 'styled-bootstrap-grid'
import styled, { keyframes, css } from 'styled-components'

const translateIn = keyframes`
from {
  transform: translateY(100%);
} 
to {
  transform: translateY(0);
}
`

const translateOut = keyframes`
from {
  transform: translateY(0);
} 
to {
  transform: translateY(100%);
}
`

export const ModalWrapper = styled.div` 
  background: ${({ theme }) => theme.colors.grayBg};
  bottom: 0;
  height: calc(100vh - 60px);
  left: 0;
  overflow-y: auto;
  padding-right: ${({ visible, delay }) => visible ? '17px' : delay ? '0' :  '0'} ;
  padding-top: 48px;
  padding-bottom: 48px;
  position: fixed;
  width: 100%;
  z-index: 3;
  .ModalReportClose {
    align-items: center;
    color: ${({ theme }) => theme.colors.secondary};
    display: inline-flex;
    float: right;
    width: auto;
    @media(max-width: 1024px) {
      display: flex;
      float: none;
      margin-left: auto;
    }
  }
  ${({ visible }) => visible  && css`
    animation: ${translateIn} 0.4s ease;
  `}
  ${({ delay }) => delay && css`
    animation: ${translateOut} 0.4s ease;
  `}
  ${Container} {
    max-width: 1200px;
  }
  @media(max-width: 768px) {
    height: calc(100% - 49px);
    padding-right: 0;
    padding-top: 24px;
    padding-bottom: 24px;
  }
`