/* import external modules */
import React from 'react'

/* import internal modules */
import { AlertContent, ContenIcon, ContenTitle } from './styles'
import Icon from '../Icon'

/** @description This is a container functional component of Alert
 *  @version 1.0.0
 *  @since 22/05/2020
 *  @author Yadurani López <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Alert
 *  @param {string} state Checked state, success, error and loading
 *  @param {string} title Name alert
 *  @param {number} progress Value progress in spiner
 *  @returns {Component} Returns the container component of Alert
 **/

const Alert = ({ title, state, progress, ...props }) => {
  return (
    <AlertContent {...props} state={state}>
      <ContenIcon state={state}>
        <Icon icon={state === 'success' ? 'check' : 'close'} />
      </ContenIcon>
      <ContenTitle>{title}</ContenTitle>
    </AlertContent>
  )
}

export default Alert
