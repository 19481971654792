import styled from 'styled-components'

export const LinkReport = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: inline-flex;
  margin: 10px 0 10px;
  position: relative;
  text-decoration: none;
  @media (max-width: 1024px) {
    margin: 0 0 20px;
  }
  .IconHover {
    display: none;
  }
  &:hover .IconHover {
    display: block;
    @media (max-width: 1024px) {
      display: none;
    }
  }
  &:hover .IconBase {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
  svg {
    flex-shrink: 0;
  }
  &:disabled {
    pointer-events: none;
    color: ${({ theme }) => theme.colors.grayDisabled};
    path {
      fill: ${({ theme }) => theme.colors.grayDisabled};

    }
  }
`
export const CaptionAnchor = styled.span`
  color: inherit;
  padding-left: 10px;
  font: 14px/20px 'DMSans-Regular';
  flex: 1;
`