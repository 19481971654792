import styled from 'styled-components'
import { Col, media } from 'styled-bootstrap-grid'

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 4, 19, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 103;
  opacity: 0;
  transition: opacity linear 0.15s;
  @media (max-width: 767px) {
    min-height: 100vh;
  }
  &.fade-in {
    opacity: 1;
    transition: opacity linear 0.15s;
  }
  &.fade-out {
    opacity: 0;
    transition: opacity linear 0.15s;
  }
  &.alert {
    background-color: rgba(0, 4, 19, 0.8);
    padding: 15px;
  }
  &.alert > div {
    border-radius: 4px;
    padding: 25px;
    @media (max-width: 768px) {
      padding: 15px;
    }
  }
  &.modal-preview > div {
    @media (max-width: 767px) {
      height: 100%;
      min-height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
  @media (max-width: 767px) {
    background: ${({ theme }) => theme.colors.white};
  }
  &.modal-content {
    background-color: rgba(0, 4, 19, 0.8);
    > div {
      border-radius: 4px;
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 887px;
      > p {
        text-align: left;
      }
      @media (max-width: 990px) {
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: calc(100% - 30px);
        width: calc(100% - 30px);
        padding: 15px;
      }
      @media (max-width: 400px) {              
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 20%;
        max-width: calc(100% - 30px);
        width: calc(80% - 30px);
        padding: 13px;
      }
      @media (max-width: 767px) {
        > p {
          margin-top: 40px;
        }
      }
    }
  }
  &.modal-mathilde {
    background-color: rgba(0, 4, 19, 0.8);
    > div {
      border-radius: 4px;
      margin-bottom: 30px;
      margin-top: 30px;
      max-width: 887px;
      > p {
        text-align: left;
      }
      @media (max-width: 990px) {
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: calc(100% - 30px);
        width: calc(100% - 30px);
        padding: 15px;
      }
      @media (max-width: 400px) {              
        margin-top: 15px;
        margin-bottom: 15px;
        margin-right: 0px;
        max-width: calc(100% - 30px);
        width: calc(80% - 30px);
        padding: 13px;
      }
      @media (max-width: 767px) {
        > p {
          margin-top: 40px;
        }
      }
    }
  }
`

export const ModalContainer = styled(Col)`
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0;
  padding: 20px;
  position: relative;
  text-align: center;
  width: ${(props) => (props.width ? `${props.width}` : null)};
  height: auto;
  min-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}` : 'initial'};
  max-height: ${(props) =>
    props.maxHeight ? `${props.maxHeight}` : 'max-content'};
  flex: inherit !important;
  overflow-y: auto;
  overflow-x: hidden;
  #btn-close-modal {
    position: absolute;
    right: ${(props) => (props.device === 'desktop' ? '15px' : '5px')};
    top: ${(props) => (props.device === 'desktop' ? '20px' : '22px')};
    padding-right: 0;
    width: 85px;
    text-align: right;
    display: flex;
  }
  button {
    &:hover {
      @media (max-width: 767px) {
        color: ${({ theme }) => theme.colors.secondary};
      }
    }
  }

  @media (min-width: 1200px) {
    max-width: ${(props) => props.portals && '1200px !important'};
  }
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    width: ${(props) => (props.device === 'mobile' ? '100%' : null)};
  }
  @media only screen and (max-device-width: 812px) and (orientation: landscape) {
    overflow: auto !important;
  }
  @media only screen and (max-device-width: 1024px) and (orientation: portrait) {
    width: ${(props) =>
      props.device === 'mobile'
        ? '60%'
        : props.device === 'desktop'
        ? '80%'
        : null};
  }
  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    width: ${(props) =>
      props.device === 'mobile' || props.device === 'desktop' ? '100%' : null};
  }

  ${media.md`
    border-radius: 4px;
    width: ${(props) =>
      (props.preview && props.device === 'mobile') || props.device === 'desktop'
        ? null
        : props.device === 'desktop' || props.device === 'mobile'
        ? '100%'
        : null};
      min-height: ${(props) => (props.height ? `${props.height}` : 'initial')};
      overflow-y: auto;
      
  `};
`
export const ButtonVideo = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: 0;
  right: -50px;
  cursor: pointer;
  @media (max-width: 768px) {
    top: -45px;
    right: 15px;
  }
`
