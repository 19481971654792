import React, {useEffect, useState} from 'react'
import {Provider} from 'react-redux'
import {store} from '../../redux/storeConfig/store'

import {ThemeProvider} from 'styled-components'
import theme from '../../constants/themeConstants'

import {GlobalStyle} from '../../styles/GlobalStyle'
import Layout from '../../components/Common/Layout'
import Routes from '../Routes'
import {getAuthInfo} from '../../services/Auth/auth'
import {setUser} from '../../redux/actions/user/user'
import {TOKEN_DB_KEY, USER_AUTH_KEY} from '../../constants/localStorage'
import {FORGOT_PASSWORD_URL, LOGIN_URL } from '../../constants/auth'
import {setLoading} from '../../redux/actions/ui/ui'
import '../../services/interceptor';
import {useSessionWatcher} from "../../herpers/auth";

const App = () => {
    const [auth, setauth] = useState(false);
    useSessionWatcher(
        () => window.sessionStorage.getItem(TOKEN_DB_KEY)
    );

    useEffect(() => {
        store.dispatch(setLoading(true))
        const token = getSessionInfo()
        if (!token) {
            const location = window.location
            const url = new URL(location.href)
            const code = url.searchParams.get('code')
            const error = url.searchParams.get('error_description')
            const codeForgotPassword = error ? error.includes('AADB2C90118') : null
            if (code) {
                window.sessionStorage.setItem(TOKEN_DB_KEY, code)
                fetchAuthInfo(code)
            } else if (codeForgotPassword) {
                window.location.replace(FORGOT_PASSWORD_URL)
            } else {
                window.location.replace(LOGIN_URL)
                return
            }
        } else {
            fetchAuthInfo(token)
        }
    }, [])

    /**
     * Send request to get data about user or redirect to login if there's some error
     * @param {string} token auth token obtained from login
     */
    const fetchAuthInfo = (token) => {
        getAuthInfo(token)
            .then(({data}) => {
                if (data.result) {
                    // Redirect to login
                    window.sessionStorage.clear()
                    window.location.replace(LOGIN_URL)
                } else {
                    window.sessionStorage.setItem(USER_AUTH_KEY, JSON.stringify(data))
                    store.dispatch(setUser(data))
                    setauth(true)
                }
            })
            .catch((err) => {
                window.sessionStorage.clear()
            })
    }

    /**
     * Get session info from session storage
     * @returns {string} Returns auth token as a string
     */
    const getSessionInfo = () => {
        const sessionInfo = window.sessionStorage.getItem(TOKEN_DB_KEY)
        if (!sessionInfo) {
            return null
        }
        return sessionInfo
    }

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyle/>
                <Layout>{auth && <Routes/>}</Layout>
            </ThemeProvider>
        </Provider>
    )
}

export default App
