/* import external modules */
import React from 'react'

/* import internal modules */
import Modal from '../Modal'
import P from '../P'
import Button from '../Button'
import { ButtonContainer } from './styles'
import ButtonPrimaryMd from '../ButtonPrimaryMd'

const ModalAlert = ({
  open,
  onClose,
  titleAlert,
  contentAlert,
  onClickAction,
  titleAction,
}) => {
  return (
    <Modal
      className="alert"
      open={open}
      onClose={onClose}
      width="370px"
      height="auto"
      textBtn>
      <P
        font="18px/26px 'DMSans-Medium'"
        textAlign="left"
        p="30px 0"
        pmb="30px 0 25px 0">
        {titleAlert}
      </P>
      <P textAlign="left" fontSize="14px">
        {contentAlert}
      </P>

      <ButtonContainer>
        <Button
          text="Cancelar"
          onClick={onClose}
          name="cancelar"
          width="60px"
          textAlign="center"
          color="#006CA5"
          font="14px/18px 'DMSans-Regular'"
          backgroundColor="#ffffff"
          mr="20px"
        />
        <ButtonPrimaryMd
          text={titleAction}
          onClick={onClickAction}
          height="40px"
        />
      </ButtonContainer>
    </Modal>
  )
}

export default ModalAlert
