// import internal modules
import { FETCH_LOADING, FETCH_MESSAGES } from '../../types/types'

export const setLoading = (load) => ({
  type: FETCH_LOADING,
  payload: { load },
})

export const setMessage = (message) => ({
  type: FETCH_MESSAGES,
  payload: { message },
})
