import styled from 'styled-components'

export const PictureContainer = styled.picture`
  img {
    border: 1px solid #8d8d8d;
    opacity: 0.4;
    width: 260px;
    @media (min-width: 991px) {
      width: 580px;
    }
    @media (min-width: 1160px) {
      width: 690px;
    }
    @media (min-width: 1215px) {
      width: 830px;
    }
  }
`

export const ContainerTemplate = styled.div`
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 137px;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  right: 0;
  top: 0;
  width: 260px;
  @media (min-width: 991px) {
    margin-top: 67px;
    padding-left: 7px;
    padding-right: 36px;
    width: 580px;
  }
  @media (min-width: 1160px) {
    margin-top: 78px;
    padding-left: 13px;
    padding-right: 43px;
    width: 690px;
  }
  @media (min-width: 1215px) {
    margin-top: 93px;
    padding-left: 23px;
    padding-right: 51px;
    width: 830px;
  }
`
export const ContainerWrapper = styled.div`
  position: relative;
  margin-top: 20px;
`
export const ImagesWrapper = styled.div`
  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  overflow: hidden;
  position: relative;
  width: max-content;
  border: 1px solid rgb(141, 141, 141, 0.4);
`
export const HomeTemplate = styled.img`
  opacity: 0.4;
  width: 260px;
  @media (min-width: 991px) {
    width: 580px;
  }
  @media (min-width: 1160px) {
    width: 690px;
  }
  @media (min-width: 1215px) {
    width: 830px;
  }
`

export const ImageContainer = styled.div`
  height: 173.88px;
  margin-left: auto;
  width: 260px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (min-width: 991px) {
    width: 420px;
    height: 208px;
  }
  @media (min-width: 1160px) {
    width: 500px;
    height: 252px;
  }
  @media (min-width: 1215px) {
    width: 605px;
    height: 302.5px;
  }
  @media (max-width: 990px) {
    margin-left: -15px;
    margin-right: -15px;
  }
`
export const ContentStaticInfo = styled.div`
  margin-left: 0px;
  width: 260px;
  @media (min-width: 991px) {
    margin-left: 116px;
  }
  @media (min-width: 1160px) {
    margin-left: 133px;
  }
  @media (min-width: 1215px) {
    margin-left: 152px;
  }
`
export const ContentParagraph = styled.div`
  margin-top: 7px;
  width: 230px;
  max-height: 700px;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  p {
    color: #393939;
    font-family: ${({ theme }) => theme.fonts[3]};
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }
  a {
    background: linear-gradient(
        to right,
        rgb(3, 201, 234) 0px,
        rgb(3, 201, 234) 100%
      )
      0px 100% / 100% 3px repeat-x transparent;
    color: rgb(57, 57, 57);
    display: inline;
    padding: 0px 0px 3px;
    text-decoration: none;
    word-break: break-word;
  }
  @media (min-width: 991px) {
    margin-top: 2px;
    width: 276px;
    max-height: 160px;
    p {
      font-size: 6.4px;
      line-height: 14px;
    }
  }
  @media (min-width: 1160px) {
    margin-top: 7px;
    width: 329px;
    max-height: 198px;
    p {
      font-size: 8.7px;
      line-height: 16px;
    }
  }

  @media (min-width: 1215px) {
    margin-top: 7px;
    width: 379px;
    max-height: 240px;
    p {
      font-size: 10px;
      line-height: 18px;
    }
  }
`
export const TitleContent = styled.h2`
  font-size: 22.5px;
  font-weight: 700;
  line-height: 25.5px;
  margin: 0;
  padding-left: 30px;
  font-family: ${({ theme }) => theme.fonts[3]};
  text-align: left;
  @media (min-width: 991px) {
    font-size: 20px;
    line-height: 22px;
    min-height: 46px;
    max-height: 46px;
  }
  @media (min-width: 1160px) {
    font-size: 26px;
    line-height: 29px;
    min-height: 60px;
    max-height: 60px;
  }
  @media (min-width: 1215px) {
    font-size: 29px;
    line-height: 32px;
    min-height: 67px;
    max-height: 67px;
  }
  @media (max-width: 990px) {
    padding-left: 0px;
  }
`
export const LeadContent = styled.p`
  color: #1c1c1c;
  font-family: ${({ theme }) => `${theme.fonts[2]}, ${theme.fonts[0]}`};
  font-size: 12.15px;
  font-weight: 600;
  line-height: 17px;
  padding-left: 30px;
  text-align: left;
  margin-bottom: 39px;
  margin-top: 7px;
  @media (min-width: 991px) {
    font-size: 8px;
    margin-bottom: 22px;
    margin-top: 1px;
  }
  @media (min-width: 1160px) {
    font-size: 10px;
  }
  @media (min-width: 1215px) {
    font-size: 12.15px;
    margin-bottom: 35px;
    margin-top: 3px;
  }
  @media (max-width: 990px) {
    padding-left: 0px;
  }
`
export const SupplementaryText = styled.p`
  text-align: left;
  color: #666666;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: ${(props) => (props.mbSm ? `${props.mbSm}px` : null)};
  margin-top: ${(props) => (props.mtSm ? `${props.mtSm}px` : 0)};
  opacity: 0.4;
  &.first {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      width: 40px;
      height: 1px;
      background: #dddddd;
      bottom: -5px;
      left: 0;
    }
  }
  @media (min-width: 991px) {
    font-size: 7px;
    margin-bottom: 11px;
    margin-top: 4px;
    &.last {
      margin-bottom: 0px;
    }
  }
  @media (min-width: 1160px) {
    &.last {
      margin-bottom: 0px;
    }
    margin-bottom: 17px;
  }
  @media (min-width: 1215px) {
    font-size: 8px;
    margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : null)};
    margin-top: ${(props) => (props.mt ? `${props.mt}px` : null)};
  }
  @media (max-width: 990px) {
    display: ${(props) => (props.column ? 'flex' : null)};
    flex-direction: ${(props) => (props.column ? 'column' : null)};
    > span:nth-of-type(1) {
      display: none;
    }
  }
`

export const SupplementaryTextSmall = styled.p`
  text-align: left;
  color: #666666;
  font-family: ${({ theme }) => theme.fonts[2]};
  font-weight: 400;
  line-height: 14px;
  margin-top: 0;
  opacity: 0.4;
  @media (min-width: 991px) {
    font-size: 7px;
    margin-bottom: 4px;
  }
  @media (min-width: 1160px) {
    margin-bottom: 6px;
  }
  @media (min-width: 1215px) {
    font-size: 7.3px;
    margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : null)};
  }
  @media (max-width: 990px) {
    display: none;
  }
`
export const CardImage = styled.img`
  background: ${({ theme }) => theme.colors.gray_5};
  height: 74px;
  margin-bottom: 12px;
  width: 158px;
  @media (min-width: 991px) {
    margin-bottom: 10px;
    height: 54px;
    width: 106px;
  }
  @media (min-width: 1160px) {
    height: 64px;
    margin-bottom: 13px;
    width: 126px;
  }
  @media (min-width: 1215px) {
    height: 81px;
    margin-bottom: 12px;
    width: 152px;
  }
`

export const ContentCard = styled.div`
  top: 24px;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: -44px;
  width: min-content;
  z-index: 10;
  text-align: left;
  @media (min-width: 991px) {
    right: 67px;
    top: 31px;
  }
  @media (min-width: 1160px) {
    right: 80px;
    top: 37px;
  }
  @media (min-width: 1215px) {
    right: 96px;
    top: 43px;
  }
`
export const DateArticle = styled.p`
  color: ${({ theme }) => theme.colors.gray_15};
  font-family: ${({ theme }) => `${theme.fonts[2]}, ${theme.fonts[0]}`};
  font-size: 8.5px;
  font-weight: 700;
  margin-bottom: 3px;
  margin-top: 2px;
  text-transform: uppercase;
  @media (min-width: 991px) {
    font-size: 4.65px;
    margin: 0;
  }
  @media (min-width: 1160px) {
    font-size: 5.65px;
    margin: 0;
  }
  @media (min-width: 1215px) {
    font-size: 6.65px;
    margin-bottom: 1px;
  }
`
export const TitleArticle = styled.p`
  color: #2d4154;
  font-family: ${({ theme }) => `${theme.fonts[3]}, ${theme.fonts[0]}`};
  font-size: 13.1px;
  font-weight: 700;
  line-height: 15.1px;
  margin-top: 0;
  word-break: break-word;
  @media (min-width: 991px) {
    font-size: 7.3px;
    line-height: 9px;
  }
  @media (min-width: 1160px) {
    font-size: 9px;
    line-height: 11px;
  }
  @media (min-width: 1215px) {
    font-size: 11px;
    line-height: 13px;
  }
`
export const TitleSponsoredContent = styled.p`
  color: ${({ theme }) => theme.colors.secondary};
  font-family: ${({ theme }) => `${theme.fonts[2]}, ${theme.fonts[0]}`};
  font-size: 9.7px;
  font-weight: 700;
  margin-bottom: 0px;
  margin-top: 0;
  @media (min-width: 991px) {
    font-size: 6.4px;
    margin-bottom: 1px;
  }
  @media (min-width: 1160px) {
    font-size: 7.2px;
    margin-bottom: 1px;
  }
`
