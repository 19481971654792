import { FETCH_SET_GLOBAL_MESSAGE } from '../../types/types'

const initialState = {
  messages: [],
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_SET_GLOBAL_MESSAGE:
      return {
        ...state,
        messages: action.payload.messages,
      }
    default:
      return state
  }
}
