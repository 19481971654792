/* import external modules */
import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  &:last-child section {
    margin-bottom: 0;
  }
`

export const CampaignTitle = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr 1fr 1fr 1fr 1fr 140px;
  grid-gap: 0 20px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0;
    flex-wrap: wrap;
    .NameCampaign {
      grid-column: 1 / -1;
    }
  }
  @media (max-width: 1024px) {
    > div:nth-of-type(6),
    > div:nth-of-type(7) {
      margin-bottom: 0;
    }
  }
`

export const CampaignContent = styled.div`
  max-height: ${(props) => (props.active ? '100%' : '0px')};
  overflow: ${(props) => (props.active ? 'inherit' : 'auto')};
`
