/* import external modules */
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export const Container = styled.div`
  display: flex;
`
export const Img = styled.img`
  margin: ${(props) => (props.margin ? props.margin : null)};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};

  ${(props) =>
    props.name === 'mobile'
      ? `width: auto;
        height: 80%; 
        margin-top: 25px;`
      : null}

  ${(props) =>
    props.name === 'desktop'
      ? `width: 100%;
        height: auto; 
        margin-top: 25%;`
      : null}

  ${media.sm`
    ${(props) =>
      props.name === 'desktop'
        ? `width: 90%;
        height: auto;
        margin-top: 6%;`
        : null};
  `}

  ${media.md`
    ${(props) =>
      props.name === 'mobile'
        ? `width: auto;
        height: 80%;`
        : null};
  `}
`
