import { Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'

export const Container = styled.div`
  margin-top: 20px;
  padding-top: 24px;
  border-top: 1px solid ${({ theme }) => theme.colors.grayBorder};
  #styleMathilde{
      color: #006ca5 !important;
      cursor: pointer;
      width: 140%;
    @media (max-width: 1024px) {
      width: 100%;
    }
    }
  .Report {
    min-width: 27px;
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
  .City {
    min-width: 180px;
    @media (max-width: 1024px) {
      width: 100% !important;
    }
  }
  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
  
`
export const CardWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px 100px 1fr 1fr 1fr 1fr 1fr 140px;
  grid-gap: 0 20px;
  @media (max-width: 1024px) {
    grid-template-columns:  1fr 1fr;
    grid-gap: 0;
    flex-wrap: wrap;
    .TitleAdvertise,
    .Report  {
      grid-column: 1 / -1;
      grid-gap: 15px;
    }
    .City  {
      grid-column: 1 / -1;
      grid-gap: 15px;
    }
    .TitleAdvertise {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .Report {
      margin-bottom: 0;
      @media (max-width: 1024px) { 
        > div:first-child {
          display: none;
        }
      }
    }    
    .City {
      margin-bottom: 0;
      @media (max-width: 1024px) { 
        > div:first-child {
          display: none;
        }
      }
    }
  }
`
export const CardWrapperClics = styled.div`
 
  display: grid;
  grid-template-columns: 150px 100px 1fr 1fr 1fr 1fr 1fr 140px;
  grid-gap: 0 20px;
  
  @media (max-width: 1024px) {
    grid-template-columns:  1fr 1fr;
    grid-gap: 0;
    flex-wrap: wrap;
    .TitleAdvertise,
    .Report  {
      grid-column: 1 / -1;
      grid-gap: 15px;
    }
    .City  {
      grid-column: 1 / -1;
      grid-gap: 15px;
    }
    .TitleAdvertise {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .Report {
      margin-bottom: 0;
      @media (max-width: 1024px) { 
        > div:first-child {
          display: none;
        }
      }
    }
    .City {
      margin-bottom: 0;
      @media (max-width: 1024px) { 
        > div:first-child {
          display: none;
        }
      }
    }
  }
`

export const CardTitle = styled.div`
  width: 170px;
  display: inline-flex;
  flex-direction: column;
  .title {
    margin-bottom: 15px;
    > div:first-child {
      display: flex;
    }
  }
  .Img {
    text-align: center;
    @media (max-width: 1024px) { 
      text-align: left;
    }
  }
  @media (max-width: 1024px) {
    width: 100%;
    margin-right: 0;
    flex-direction: row;
  }
 
 
`
export const RowC = styled(Row)`
 @media only screen and (max-device-width: 767px) {
  margin-left: 10px;
  }
`

export const DivTitle = styled.div`
 @media only screen and (max-device-width: 767px) {
    display: none;
  }`


export const Line = styled.div`
  width: 4px;
  background-color:  ${({ theme }) => theme.colors.graySuperLightness};
  @media only screen and (max-device-width: 767px) {
    display: none;
  }
  margin: 0px 20px 0px 30px;

  
`

export const State = styled.span`
  display: block;
  border-radius: 4px;
  width: 65px;
  height: 20px;
  font: 11px/20px 'DMSans-Regular';
  text-align: center;
  margin-top: 4px;
  margin-left: 10px;
  &.Aprobado {
    color: ${({ theme }) => theme.colors.success};
    background: ${({ theme }) => theme.colors.successLight};
  }
  &.Pendiente {
    color: ${({ theme }) => theme.colors.pending};
    background: ${({ theme }) => theme.colors.pendingLight};
  }
  &.Rechazado {
    color: ${({ theme }) => theme.colors.error};
    background: ${({ theme }) => theme.colors.errorLight};
  }
  @media (max-width: 1024px) {
    float: right;
    margin-top: 3px;
    margin-right: 0;
    margin-left: 5px;
    width: 60px;
  }
`
export const Link = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: contents;
  text-align: center;
  text-decoration: none;
  img {
    width: 70px;
    @media (max-width: 768px) {
      width: 100%;
      height: 60px;
      object-fit: contain;
      object-position: left;
    }
  }
  svg {
    margin: 12px 0;
  }
  span {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
`
export const LinkClics = styled.a`
  color: ${({ theme }) => theme.colors.secondary};
  cursor: pointer;
  display: contents;
  text-align: center;
  text-decoration: none;
  img {
    width: 70px;
    @media (max-width: 768px) {
      width: 70%;
      height: 60px;
      object-fit: contain;
      object-position: center;
    }
  }
 
  span {
    display: none;
    @media (max-width: 1024px) {
      display: block;
    }
  }
`

export const Format = styled.div`
  display: inline-block;
  font: 14px/24px 'DMSans-Medium';
  color: ${({ theme }) => theme.colors.third};
  svg {
    vertical-align: middle;
    margin-bottom: 4px;
    margin-right: 5px;
    fill: #8E9194;
  }
`

export const ButtonContent = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  padding: 0;
  &:focus {
    outline: none;
  }
  img {
    width: 70px;
    border: 1px solid #a6a9ab;
    @media (max-width: 768px) {
      width: auto;
      height: 60px;
      object-fit: contain;
      object-position: left;
    }
  }
`
