/* import external modules */
import React from 'react'
import Icon from '../Icon'

/* import internal modules */
import Tooltip from '../Tooltip'

const TooltipInfo = ({ text, textBase }) => {
  return (
    <>
      {textBase}
      <Tooltip
        width="192"
        place="top-start"
        responsivePlace="top-start"
        title={text}>
        <Icon
          icon="info"
          color="#8E9194"
          marginLeft="5px"
          marginTop="0px"
        />
      </Tooltip>
    </>
  )
}

export default TooltipInfo
