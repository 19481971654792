/* import external modules */
import React from 'react'

/* import internal modules */
import Span from '../../../Common/Span'
import { SupplementaryText, SupplementaryTextSmall } from './styles'

const InfoStaticEditor = () => {
  return (
    <>
      <SupplementaryText mb="24" mt="7" mtSm="9" mbSm="34" className="first">
        <b>Foto:</b> FOTO
      </SupplementaryText>
      <SupplementaryTextSmall mb="12">
        <b>RELACIONADOS:</b> RELACIONADOS
      </SupplementaryTextSmall>
      <SupplementaryText className="last" mbSm="5" column>
        <b>
          Por: <Span color="#0C5991">CONTENIDO PATROCINADO</Span>
        </b>
        <Span ml="10px" mr="10px">
          |
        </Span>
        <Span>Fecha</Span>
      </SupplementaryText>
    </>
  )
}

export default InfoStaticEditor
