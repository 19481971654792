import moment from 'moment'
import { dateFormat } from './../constants/formats'
moment.locale('es')

export const getCurrentResource = (resources) => {
  let current = resources[0]
  for (let i = 1; i < resources.length; i++) {
    if (resources[i].createdAt > current.createdAt) {
      current = resources[i]
    }
  }
  return current
}

export const getCurrentResourceClick = (resourcesClick) => {
  let current = resourcesClick[0]
  for (let i = 1; i < resourcesClick.length; i++) {
    if (resourcesClick[i].createdAt > current.createdAt) {
      current = resourcesClick[i]
    }
  }
  return current
}

export const getFormatDate = (date) => {
  return moment(date).format(dateFormat).split('.').join('')
}

export const getSubtractFormatDate = (date, num) => {
  return moment(date)
    .subtract(num, 'days')
    .format(dateFormat)
    .split('.')
    .join('')
}
