export function hashUser(userBoolean, userEmail){
if (userBoolean) {
    var email=userEmail;     // el email del usuario logeado
    var UserId
    var n = email.indexOf("@");
    var nameMail = email.substr(0, n);
    var hashMd5 = hashMD5(nameMail);
    var userhash = email + hashMd5;
    UserId = SHA256(userhash);
    var hashMd5 = hashMD5(nameMail);
    var userhash = email + hashMd5;
    UserId = SHA256(userhash); 
    localStorage.setItem("HashUser",UserId);
    localStorage.setItem("UserTypeLogue","Registrado");
    //return { HashUser: UserId, UserTypeLogue: "Registrado" }
}else{
    localStorage.setItem("HashUser",null);
    localStorage.setItem("UserTypeLogue","Anonimo");
    //return { HashUser: null, UserTypeLogue: "Anonimo" }
}
 }

function hashMD5(stringTo) {
    var MD5 = function(d) {
        var result
        result = M(V(Y(X(d), 8 * d.length)));
        return result.toLowerCase()
    };
    return MD5(stringTo)
}

function M(d) {
    var m = "0123456789ABCDEF";
    var f = "";
    for (var r = 0; r < d.length; r++) {
        var charCode = d.charCodeAt(r);
        f += m.charAt(charCode >>> 4 & 15) + m.charAt(15 & charCode);
    }
    return f;
}

    function X(d) {
        for (var _ = Array(d.length >> 2), m = 0; m < _.length; m++) _[m] = 0;
        for (m = 0; m < 8 * d.length; m += 8) _[m >> 5] |= (255 & d.charCodeAt(m / 8)) << m % 32;
        return _
    }

    function V(d) {
        for (var _ = "", m = 0; m < 32 * d.length; m += 8) _ += String.fromCharCode(d[m >> 5] >>> m % 32 & 255);
        return _
    }

    function Y(d, _) {
        var index1 = _ >> 5;
        var index2 = 14 + (_ + 64 >>> 9 << 4);
        d[index1] |= 128 << (_ % 32);
        d[index2] = _;
    
        var m = 1732584193;
        var f = -271733879;
        var r = -1732584194;
        var i = 271733878;
        var n = 0;
    
        for (; n < d.length; n += 16) {
            var h = m;
            var t = f;
            var g = r;
            var e = i;
    
            f = md5_ii(f, r, i, m, d[n + 0], 7, -680876936);
            r = md5_ii(r, i, m, f, d[n + 1], 12, -389564586);
            m = md5_ii(i, m, f, r, d[n + 2], 17, 606105819);
            // Sigue con las demás operaciones de forma similar...
    
            m = safe_add(m, h);
            f = safe_add(f, t);
            r = safe_add(r, g);
            i = safe_add(i, e);
        }
    
        return [m, f, r, i];
    }
    

    function md5_cmn(d, _, m, f, r, i) {
        return safe_add(bit_rol(safe_add(safe_add(_, d), safe_add(f, i)), r), m)
    }

    function md5_ff(d, _, m, f, r, i, n) {
        return md5_cmn(_ & m | ~_ & f, d, _, r, i, n)
    }

    function md5_gg(d, _, m, f, r, i, n) {
        return md5_cmn(_ & f | m & ~f, d, _, r, i, n)
    }

    function md5_hh(d, _, m, f, r, i, n) {
        return md5_cmn(_ ^ m ^ f, d, _, r, i, n)
    }

    function md5_ii(d, _, m, f, r, i, n) {
        return md5_cmn(m ^ (_ | ~f), d, _, r, i, n)
    }

    function safe_add(d, _) {
        var m = (65535 & d) + (65535 & _);
        return (d >> 16) + (_ >> 16) + (m >> 16) << 16 | 65535 & m
    }

    function bit_rol(d, _) {
        return d << _ | d >>> 32 - _
    }
    


function SHA256(s) {
    var chrsz = 8;
    var hexcase = 0;

    function safe_add(x, y) {
        var lsw = (x & 65535) + (y & 65535);
        var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
        return msw << 16 | lsw & 65535
    }

    function S(X, n) {
        return X >>> n | X << 32 - n
    }

    function R(X, n) {
        return X >>> n
    }

    function Ch(x, y, z) {
        return x & y ^ ~x & z
    }

    function Maj(x, y, z) {
        return x & y ^ x & z ^ y & z
    }

    function Sigma0256(x) {
        return S(x, 2) ^ S(x, 13) ^ S(x, 22)
    }

    function Sigma1256(x) {
        return S(x, 6) ^ S(x, 11) ^ S(x, 25)
    }

    function Gamma0256(x) {
        return S(x, 7) ^ S(x, 18) ^ R(x, 3)
    }

    function Gamma1256(x) {
        return S(x, 17) ^ S(x, 19) ^ R(x, 10)
    }

    function core_sha256(m, l) {
        var K = new Array(1116352408, 1899447441, 3049323471, 3921009573, 961987163, 1508970993, 2453635748, 2870763221, 3624381080, 310598401, 607225278, 1426881987, 1925078388, 2162078206, 2614888103, 3248222580, 3835390401, 4022224774, 264347078, 604807628, 770255983, 1249150122, 1555081692, 1996064986, 2554220882, 2821834349, 2952996808, 3210313671, 3336571891, 3584528711, 113926993, 338241895, 666307205, 773529912, 1294757372, 1396182291, 1695183700, 1986661051, 2177026350, 2456956037, 2730485921, 2820302411, 3259730800, 3345764771, 3516065817, 3600352804, 4094571909, 275423344, 430227734, 506948616, 659060556, 883997877, 958139571, 1322822218, 1537002063, 1747873779, 1955562222, 2024104815, 2227730452, 2361852424, 2428436474, 2756734187, 3204031479, 3329325298);
        var HASH = new Array(1779033703, 3144134277, 1013904242, 2773480762, 1359893119, 2600822924, 528734635, 1541459225);
        var W = new Array(64);
        var a, b, c, d, e, f, g, h, i, j;
        var T1, T2;
        m[l >> 5] |= 128 << 24 - l % 32;
        m[(l + 64 >> 9 << 4) + 15] = l;
        for (var i = 0; i < m.length; i += 16) {
            a = HASH[0];
            b = HASH[1];
            c = HASH[2];
            d = HASH[3];
            e = HASH[4];
            f = HASH[5];
            g = HASH[6];
            h = HASH[7];
            for (var j = 0; j < 64; j++) {
                if (j < 16) W[j] = m[j + i];
                else W[j] = safe_add(safe_add(safe_add(Gamma1256(W[j - 2]), W[j - 7]), Gamma0256(W[j - 15])), W[j - 16]);
                T1 = safe_add(safe_add(safe_add(safe_add(h, Sigma1256(e)), Ch(e, f, g)), K[j]), W[j]);
                T2 = safe_add(Sigma0256(a), Maj(a, b, c));
                h = g;
                g = f;
                f = e;
                e = safe_add(d, T1);
                d = c;
                c = b;
                b = a;
                a = safe_add(T1, T2)
            }
            HASH[0] = safe_add(a, HASH[0]);
            HASH[1] = safe_add(b, HASH[1]);
            HASH[2] = safe_add(c, HASH[2]);
            HASH[3] = safe_add(d, HASH[3]);
            HASH[4] = safe_add(e, HASH[4]);
            HASH[5] = safe_add(f, HASH[5]);
            HASH[6] = safe_add(g, HASH[6]);
            HASH[7] = safe_add(h, HASH[7])
        }
        return HASH
    }
    function str2binb(str) {
        var bin = Array();
        var mask = (1 << chrsz) - 1;
        for (var i = 0; i < str.length * chrsz; i += chrsz) {
            bin[i >> 5] |= (str.charCodeAt(i / chrsz) & mask) << 24 - i % 32
        }
        return bin
    }

    function Utf8Encode(string) {
        string = string.replace(/\r\n/g, "\n");
        var utftext = "";
        for (var n = 0; n < string.length; n++) {
            var c = string.charCodeAt(n);
            if (c < 128) {
                utftext += String.fromCharCode(c)
            } else if (c > 127 && c < 2048) {
                utftext += String.fromCharCode(c >> 6 | 192);
                utftext += String.fromCharCode(c & 63 | 128)
            } else {
                utftext += String.fromCharCode(c >> 12 | 224);
                utftext += String.fromCharCode(c >> 6 & 63 | 128);
                utftext += String.fromCharCode(c & 63 | 128)
            }
        }
        return utftext
    }

    function binb2hex(binarray) {
            var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
            var str = "";
            for (var i = 0; i < binarray.length * 4; i++) {
                str += hex_tab.charAt(binarray[i >> 2] >> (3 - i % 4) * 8 + 4 & 15) + hex_tab.charAt(binarray[i >> 2] >> (3 - i % 4) * 8 & 15)
            }
            return str
    }
        s = Utf8Encode(s);
        return binb2hex(core_sha256(str2binb(s), s.length * chrsz))
    }


export function hashUserGTM() {
    // Agregar el objeto ecommerce actualizado a dataLaye
    let hashU = localStorage.getItem('HashUser')
    let userT = localStorage.getItem('UserTypeLogue')
    // dataLayer.push({
    //   'event': 'Usuario',
    //   'user_id': hashU, //null si está como anónimo   que envie null o que no envie la llave
    //   'user_type': userT, //anonimo, registrado
    // })
  }
