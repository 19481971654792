import {encryptingKey} from "../constants/auth";
import * as CryptoJS from 'crypto-js';


export const descryptImgUrl = (imgUrl) => {
  if (imgUrl.includes('assets')) {
    return imgUrl
  } else {
    let bytes = CryptoJS.AES.decrypt(imgUrl, encryptingKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }

}