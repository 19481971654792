import { LOGIN_URL } from '../constants/auth'
import { getAuthInfo, logout as logoutService } from '../services/Auth/auth'
import { useEffect } from 'react'
import { debounce } from './debounce'
import lifecycle from 'page-lifecycle'

export async function logout(token) {
  window.sessionStorage.clear()
  await logoutService(token)
  window.location.replace(LOGIN_URL)
}

export function useSessionWatcher(tokenGetter) {
  const token = tokenGetter()

  useEffect(() => {
    if (!token) return

    const listener = debounce(async (ev) => {
      if (
        ['passive', 'active'].includes(ev.newState) &&
        ev.newState !== ev.oldState
      ) {
        await getAuthInfo(token)
      }
    }, 100)
    lifecycle.addEventListener('statechange', listener)
    return () => {
      lifecycle.removeEventListener('statechange', listener)
    }
  }, [token])
}
