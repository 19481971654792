/* import external modules */
import React, {useMemo} from 'react'
import PropTypes from 'prop-types'

/* import internal modules */
import Section from '../../Common/Section'
import CardInfo from '../../Common/CardInfo'
import ButtonShow from '../../Common/ButtonShow'
import AdvertiseList from '../AdvertiseList'
import Label from '../../Common/Label'

import {getFormatDate, getSubtractFormatDate} from '../../../herpers/listCampaigns'

import {
  CampaignDateStatusCode,
  CampaignManualStatusCode,
  CampaignStatusRecord,
  CampaignStatusTitle,
  MaterialStatusCode,
} from './../../../constants/status'
import cname from './../../../constants/classNameData'

import {CampaignContent, CampaignTitle, Container} from './styles'
import {campaignIdStringFormat} from "../../../herpers/completeCampingId";
import {getMaxDate, getMinDate, getTotal} from "../../../herpers/campaignsItemHelper";

/** @description This is a container functional component of CampaignsItem
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Elizabeth Velásquez <elivel@eltiempo.com>
 *
 *  @function
 *  @name CampaignsItem
 *  @param {string} campaignName Receives name of campaign
 *  @param {number} campaignId Identifier of campaign
 *  @param {string} id Id of campaign for upload button
 *  @param {string} buyDate Date bought of campaign
 *  @param {bool} active State open or close of accordion
 *  @param {func} onClick Event for open or close accordion
 *  @param {array} ads Array of advertisements of each campaign
 *  @param {number} manualStatus This prop is displayed when the campaign was canceled (which is something that is done manually in the admin campaign module)
 *  @param {number} materialStatus Material States (Rejected, Approved Pending)
 *  @param {number} dateStatus  Indicates the status of the campaign (In Progress, Un started, Completed, Canceled)
 *  @returns {Component} Returns the container component of the Campaigns Item
 **/

const CampaignsItem = ({
  campaignName,
  campaignId,
  id,
  buyDate,
  active,
  onClick,
  ads,
  manualStatus,
  materialStatus,
  dateStatus,
  advertisementsType,
  adSponsoredContentsType,
  ...props
}) => {
  /**
   * Find start and end date for a campaign
   */
  const [startDate, endDate, artUploadDate] = useMemo(() => {
    if (ads.length === 0) return [null, null, null]
    const minDate = getMinDate(ads.map(ad => new Date(ad.dateStart)));
    return [
      getFormatDate(minDate),
      getFormatDate(getMaxDate(ads.map(ad => new Date(ad.dateEnd)))),
      getFormatDate(getSubtractFormatDate(minDate, 1))
    ]
  }, [ads])

  /**
   * Calc total price for a campaign
   */
  const totalPrice = useMemo(() => {
    if (ads.length === 0) return `$ 0`
    const total = getTotal(ads.map(ad => Number(ad.price)))
    return `$ ${new Intl.NumberFormat('es-ES').format(Math.trunc(total * 1.19))}`
  }, [ads])

  /**
   * Set campaign status
   */
  const {title: campaignStatus, icon: iconName} = useMemo(() => {
    if (manualStatus === CampaignManualStatusCode.Canceled) {
      return CampaignStatusRecord.ManualStatus[CampaignManualStatusCode.Canceled]
    }
    if (
      [CampaignDateStatusCode.InProgress, CampaignDateStatusCode.Finished].includes(dateStatus)
    ) {
      return CampaignStatusRecord.DateStatus[dateStatus]
    }
    if (
      [MaterialStatusCode.Rejected, MaterialStatusCode.Approved].includes(materialStatus)
    ) {
      return CampaignStatusRecord.MaterialStatus[materialStatus]
    }

    return CampaignStatusRecord.MaterialStatus[MaterialStatusCode.Pending]
  }, [manualStatus, dateStatus, materialStatus])

  return (
    <Container {...props} id={id}>
      <Label
        title={campaignStatus}
        iconName={iconName}
        background={
          campaignStatus === CampaignStatusTitle.Canceled && '#E9E9E9'
        }
        iconExtra={
          campaignStatus === CampaignStatusTitle.ApprovedMaterials
            ? 'approved'
            : campaignStatus === CampaignStatusTitle.RejectedMaterials
            ? 'error'
            : null
        }
        iconColor={
          campaignStatus === CampaignStatusTitle.ApprovedMaterials
            ? '#00ae34'
            : campaignStatus === CampaignStatusTitle.RejectedMaterials
            ? '#e20000'
            : null
        }
      />
      <Section pm="28px 20px" p="25px 15px">
        <CampaignTitle>
          <CardInfo 
            className="NameCampaign" 
            title="Nombre de la campaña:" 
            text={campaignName} />
          <CardInfo
            title="Id de campaña:"
            textSecondary={campaignIdStringFormat(campaignId)}
          />
          <CardInfo
            className="Date"
            title="Inicio de campaña: "
            text={startDate}
          />
          <CardInfo
            className="Date"
            title="Fin de campaña: "
            text={endDate}
          />
          <CardInfo
            className="Date"
            title="Fecha de compra:"
            text={buyDate}
          />
          <CardInfo 
            title="Costo:" 
            text={totalPrice} />
          <CardInfo>
            <ButtonShow onClick={onClick} active={active} className={(!active) ? cname.see_detail : cname.hide_detail}/>
          </CardInfo>
        </CampaignTitle>
        <CampaignContent active={active}>
          <AdvertiseList
            advertisementsType={advertisementsType}
            adSponsoredContentsType={adSponsoredContentsType}
            startDate={artUploadDate}
            id={`${campaignId}-${id}`}
            campaignName={campaignName}
            campaignId={campaignId}
          />
        </CampaignContent>
      </Section>
    </Container>
  )
}

CampaignsItem.propTypes = {
  campaignName: PropTypes.string,
  campaignId: PropTypes.number,
  id: PropTypes.string,
  buyDate: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  ads: PropTypes.array,
  manualStatus: PropTypes.number,
  materialStatus: PropTypes.number,
  dateStatus: PropTypes.number,
  advertisementsType: PropTypes.array,
  adSponsoredContentsType: PropTypes.array,
}

export default CampaignsItem
