/* import external modules */
import React from 'react'

/* import internal modules */
import Icon from '../Icon'

import messages from '../../../constants/messages'

import { CaptionAnchor, LinkReport } from './styles'

const LinkIconActive = ({ url, ...props }) => (
  <LinkReport {...props} href={url} target="_blank" >
    <Icon icon="file" color="#006ca5" className="IconBase" />
    <Icon icon="fileFill" color="#006ca5" className="IconHover" />
    <CaptionAnchor>{messages.messageViewReport}</CaptionAnchor>
  </LinkReport>
)


export default LinkIconActive
