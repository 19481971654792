/* import external modules */
import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

/* import internal modules */
import CardInfo from '../../Common/CardInfo'
import ActionsAdvertise from '../AdvertiseItem/ActionsAdvertise'

import { dateFormat } from './../../../constants/formats'
import { MaterialStatusCode, MaterialStatus, CampaigntTypeStatus } from '../../../constants/status'

import { Container, CardWrapper, CardTitle, State, Link, Format, DIvTitle, DivTitle } from '../AdvertiseItem/styles'

import 'moment/locale/es' // without this line it didn't work
import TooltipInfo from '../../Common/TooltipInfo'
import messages from '../../../constants/messages'
import ModalReport from '../ModalReport'
import {groupPortals} from "../../../herpers/groupPortals";

moment.locale('es')
moment.suppressDeprecationWarnings = true

/** @description This is a container functional component of AdvertiseItem
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Camila Alvarez <quicam@eltiempo.com>
 *
 *  @function
 *  @name CardTitle
 *  @param {number} numAdvertise Receives a number of advertisements
 *  @param {number} statusCode Status advertise approved, pending, reject
 *  @param {string} imgAd Image for each advertisements
 *  @param {object} format Detail format for each advertisements
 *  @param {string} device Device for each advertisements
 *  @param {number} typeSegment Segment type for each advertisements
 *  @param {string} numCampaign Receive a number for the campaign as an identifier
 *  @param {array} infoSegmentPortal Receives array selected portals
 *  @param {array} infoSegmentAudience Receives array selected audience
 *  @param {string} impressions Number of impressions
 *  @param {string} url Url for each advertisements
 *  @param {string} initialDate Initial date for each advertisements
 *  @param {string} endDate End date for each advertisements
 *  @param {string} urlReport Url report for each advertisements
 *  @param {string} review Review for each advertisements
 *  @param {string} imageTemplate Image template for each advertisements
 *  @param {string} limitDate Limit date for each advertisements
 *  @param {object} adContent content ad info
 *  @param {string} typeAd type Ad
 *  @param {string} urlSmart Smart traking link
 *  @param {string} reportType Number
 *  @param {string} reportDataStudio String
 *  @param {string} campaignName String
 *  @param {string} campaignId Number
 *  @returns {Component} Returns the container component of the advertise item
 **/

const CardTitleInfo = ({
  numAdvertise,
  statusCode,
  format,
  device,
  typeSegment,
  infoSegmentPortal,
  infoSegmentAudience,
  impressions = 'N/A',
  url = 'N/A',
  initialDate,
  endDate,
  urlReport,
  typeAd,
  urlSmart,
  reportDataStudio,
  reportType,
  campaignName,
  campaignId,
  advertisements,
}) => {
  const [ open, setOpen ] = useState(false)
  let portals = groupPortals(infoSegmentPortal)
  let statusAdvertise =
    statusCode === MaterialStatusCode.Rejected
      ? MaterialStatus.Rejected
      : statusCode === MaterialStatusCode.Approved
      ? MaterialStatus.Approved
      : MaterialStatus.Pending

  const nameJoinPortals =
    (infoSegmentPortal && infoSegmentPortal.length === 6
      ? 'Toda la red'
      : portals) ||
    (infoSegmentAudience && infoSegmentAudience.join(', ')) ||
    []

  const start = moment()
  const finalDate = moment(endDate, dateFormat)

  //Difference in number of days
  const diff = start.diff(finalDate, 'days')
  const nextDayFinalDate = diff > 0
  const advertisingData = {
    numAdvertise,
    format,
    nameJoinPortals,
    impressions,
    url,
    initialDate,
    endDate,
    reportDataStudio,
    campaignName,
    campaignId,
    device
  }
  const handleClose = () => setOpen(false)
  return (
    <Container>
    
      <CardWrapper>
        <CardTitle className="TitleAdvertise">
          <CardInfo
            className="title"
            textPrimary={
              <>
                Pauta {numAdvertise}:
                <State className={statusAdvertise}>
                  {statusAdvertise}
                </State>
              </>
            }
            text={typeAd}
          />
          
        </CardTitle>
       <DivTitle></DivTitle>
      
        <CardInfo
         title="Portales" 
         text="Toda la red" 
        />
        <CardInfo
          title={
            <TooltipInfo 
              textBase="Clicks:"
              text={messages.messageTooltipClics}/>
          }
          text={impressions}
        />
        <CardInfo 
          title="Url ingresada:" 
          text={url} />
        <CardInfo
          className="Date"
          title="Fecha inicial:"
          text={initialDate}/>
        <CardInfo 
          className="Date" 
          title="Fecha final:" 
          text={endDate} />
        <CardInfo 
          className="Report" 
          title="Acciones:">
          <ActionsAdvertise
            onClick={() => setOpen(true)}
            nextDayFinalDate={nextDayFinalDate}
            typeAd={typeAd}
            urlReport={urlReport}
            urlSmart={urlSmart}
            reportType={reportType}
          />
        </CardInfo>
        <CardInfo className="Report" title="" text="" />
        
      </CardWrapper>
     
      
      {open && <ModalReport onClose={handleClose} open={open} data={advertisingData}/>}
    </Container>
  )
}

CardTitleInfo.propTypes = {
  numAdvertise: PropTypes.number,
  statusCode: PropTypes.number,
  imgAd: PropTypes.string,
  format: PropTypes.object,
  device: PropTypes.string,
  typeSegment: PropTypes.number,
  numCampaign: PropTypes.string,
  infoSegmentPortal: PropTypes.array,
  infoSegmentAudience: PropTypes.array,
  impressions: PropTypes.string,
  url: PropTypes.string,
  initialDate: PropTypes.string,
  endDate: PropTypes.string,
  urlReport: PropTypes.string,
  review: PropTypes.string,
  imageTemplate: PropTypes.string,
  limitDate: PropTypes.string,
  adContent: PropTypes.object,
  typeAd: PropTypes.string,
  urlSmart: PropTypes.string,
  reportDataStudio: PropTypes.string,
  campaignName: PropTypes.string,
  campaignId: PropTypes.number,
  reportType: PropTypes.number
}

export default CardTitleInfo
