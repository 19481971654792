import { createGlobalStyle } from 'styled-components'
export const GlobalStyle = createGlobalStyle`
    * {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    body {  
        font-family: 'DMSans-Regular';
        margin: 0;
        overflow-x: hidden;
    }
    @font-face {
        font-family: 'DMSans-Regular';
        font-display: block;
        src: url('/fonts/DM_Sans/DMSans-Regular.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
       
    }
    @font-face {
        font-family: 'DMSans-Medium';
        font-display: block;
        src: url('/fonts/DM_Sans/DMSans-Medium.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
        
    }
    @font-face {
        font-family: 'DMSans-Bold';
        font-display: block;
        src: url('/fonts/DM_Sans/DMSans-Bold.ttf') format('truetype');
        font-weight: normal;
        font-style: normal;
       
    }

    .MuiPopover-paper .MuiMenu-list {
        padding: 0
    }
    .modal {
        &::-webkit-scrollbar,
        &::-webkit-scrollbar-thumb {
            pointer-events: none;
            background: rgba(0,4,19,0.8);
        }
        &::-moz-scrollbar { 
            pointer-events: none;
        } 
        &.modal-video {
            overflow: hidden;
            header  {
                padding-right: 17px !important;
                @media (max-width: 1024px) {
                    padding-right: 0 !important;
                }
            }
        }
        @media (max-width: 1024px) {
           overflow: hidden;
        }
    }
    .overflow {
        overflow-x: hidden;
    }
    .overflow-hidden-mobile {
        @media (max-width: 768px) {
            overflow: hidden;
        }
    }
    @media print {
        html, body {
            height:100%; 
            margin: 0 !important; 
            padding: 0 !important;
            overflow: hidden;
        }
    }
    .Date div:last-child  {
        text-transform: capitalize;
    }
    .heightSelect {
        max-height: 300px !important;
    }
    .grecaptcha-badge {
        bottom: 60px !important;
        display: none;
    }
    .MuiTypography-body1,
    .MuiTypography-body2,
    .MuiTypography-caption {
        font-family: 'DMSans-Regular' !important;
    }
    .MuiPickersBasePicker-pickerView {
        @media (max-width: 350px) {
            min-width: 290px !important;
        }
    }
    button {
        background: none;
        border: none;
        padding: 0;
    }
    #iframe.loading {
        height: 0;
    }
    #iframe {
        border: 0;
        box-shadow: 0 0 5px rgba(0,0,0,0.2);
        height: 2100px;
    }
    .ModalWidth--overflow {
        overflow: hidden;
        padding-right: 17px;
    }
    .ModalHeader--overflow {
        padding-right: 17px;
    }
    @media(max-width: 768px) {
        .ModalWidth--overflow,
        .ModalHeader--overflow {
            padding-right: 0px;
        }
        #iframe {
            height: 100vh;
        }
    }
`
