import styled from 'styled-components'

export const AlertContent = styled.div`
  color: ${(props) =>
    props.state === 'success'
      ? `${props.theme.colors.success}`
      : props.state === 'error'
      ? `${props.theme.colors.error}`
      : `${props.theme.colors.secondary}`};
  font: 14px/26px 'DMSans-Regular';
  path {
    fill: ${(props) =>
      props.state === 'success'
        ? `${props.theme.colors.success}`
        : props.state === 'error' && `${props.theme.colors.error}`};
  }
  margin-top: ${(props) => (props.mt ? `${props.mt}px` : null)};
  margin-bottom: ${(props) => (props.mb ? `${props.mb}px` : null)};
  display: flex;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: center;
  }
`
export const ContenIcon = styled.span`
  width: 30px;
  height: 30px;
  border: 2px solid
    ${(props) =>
      props.state === 'success'
        ? `${props.theme.colors.success}`
        : props.state === 'error'
        ? `${props.theme.colors.error}`
        : 'transparent'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  margin-right: 10px;
`
export const ContenTitle = styled.span`
  color: ${(props) =>
    props.state === 'success'
      ? `${props.theme.colors.success}`
      : props.state === 'error' && `${props.theme.colors.error}`};
  font: 14px/26px 'DMSans-Regular';
  path {
    fill: ${(props) =>
      props.state === 'success'
        ? `${props.theme.colors.success}`
        : props.state === 'error' && `${props.theme.colors.error}`};
  }
  flex: 1;
  line-height: 17px;
  @media (max-width: 1340px) {
    white-space: pre-line;
  }
  @media (max-width: 767px) {
    font: 13px/26px 'DMSans-Regular';
    flex: 1;
    line-height: 18px !important;
  }
`
