/* import external modules */
import React from 'react'

/* import internal modules */
import Icon from '../Icon'
import theme from '../../../constants/themeConstants'
import { WrapperAlertFixed } from './styles'
import { statusSuccess } from '../../../constants/messages'

/** @description This is a container functional component of AlertFixed
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name AlertFixed
 *  @param {string} status State success or error for global alert
 *  @param {string} text Text for global alert
 *  @returns {Component} Returns the container component of the AlertFixed
 **/

const AlertFixed = ({ status, text }) => {
  const { success, error } = theme.colors

  return (
    <WrapperAlertFixed>
      <Icon
        icon={status === statusSuccess.status ? 'check' : 'canceled'}
        color={status === statusSuccess.status ? success : error}
        width={28}
        height={28}
        marginRight="13px"
        viewbox="0 0 28 28"
      />
      {text}
    </WrapperAlertFixed>
  )
}

export default AlertFixed
