import styled from 'styled-components'

export const BadgeMain = styled.span`
  display: none;
  position: absolute;
  white-space: nowrap;
  border: 1px solid ${({ theme }) => theme.colors.grayMediumBorder};
  border-radius: 4px;
  background: white;
  padding: 3px 11px;
  right: -7px;
  bottom: 35px;
  box-shadow: 0px 5px 6px ${({ theme }) => theme.colors.grayShadow};
  color: ${({ theme }) => theme.colors.gray};
  font: 13px/20px ${({ theme }) => theme.font.regular};
  z-index: 2;
  @media (max-width: 1024px) {
    display: inline-block;
    position: static;
    white-space: pre-wrap;
    border: none;
    border-radius: none;
    background: white;
    padding: 0 0 0 10px;
    font: 14px/20px ${({ theme }) => theme.font.regular};
    box-shadow: none;
    color: ${(props) =>
      props.href
        ? `${props.theme.colors.secondary}`
        : `${props.theme.colors.gray}`};
  }
  &:before {
    content: '';
    width: 0;
    height: 0;
    border-right: 0 solid transparent;
    border-top: 14px solid transparent;
    border-left: 10px solid ${({ theme }) => theme.colors.gray};
    border-bottom: 0px solid ${({ theme }) => theme.colors.gray};
    transform: rotate(90deg);
    display: inline-flex;
    position: absolute;
    bottom: -12px;
    z-index: -3;
    right: 3px;
    @media (max-width: 1024px) {
      display: none;
    }
  }
`
