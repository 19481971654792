/* import external modules */
import React from 'react'

/* import internal modules */
import { BadgeMain } from './styles'

const Badge = ({ ...props }) => {
  return <BadgeMain {...props}>{props.text}</BadgeMain>
}

export default Badge
