/* import external modules */
import { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

/** @description This is a container functional component Portal render Modal
 *  @version 1.0.0
 *  @since 14/05/2020
 *  @author Yadurani López <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Portal
 *  @params {component} Childrens
 *  @params {string} Id modal
 *  @returns {Component} Returns the container component of preview images
 **/

const Portal = ({ children, selector }) => {
  const ref = useRef()
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  return mounted ? createPortal(children, ref.current) : null
}

export default Portal
