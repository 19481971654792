/* import external modules */
import React from 'react'

/* import internal modules */
import { SpanTag } from './styles'

const Span = (props) => {
  return <SpanTag {...props}>{props.children}</SpanTag>
}

export default Span
