/* import external modules */
import React, { useState } from 'react'

/* import internal modules */
import {
  Container,
  Toggle,
  MenuCollapsed,
  Label,
  ListItem,
  ListItemName,
  BlackoutMobile,
} from './styles'
import List from '../List'
import Icon from '../Icon'

const Menu = ({ nameUser, itemsMenu , classt = false }) => {
  const [active, setActive] = useState(false)

  const toggle = () => {
    setActive(!active)
  }

  const close = () => {
    setActive(false)
  }
  return (
    <>
      <BlackoutMobile active={active} onClick={close}>
        <Icon className="close-menu" icon="closeMenu" color="#ffffff" />
      </BlackoutMobile>
      <Container tabIndex="0" onBlur={close} >
        <Toggle active={active} onClick={toggle} className={classt.name_user}>
          <label>{nameUser}</label>
          <Icon className="desktop" icon="arrowDown" color="#ffffff" />
          <Icon className="mobile" icon="menu" color="#ffffff" />
        </Toggle>
        <MenuCollapsed className={`${active && 'active '+classt.sign_off}`}>
          <List>
            <ListItemName>{nameUser}</ListItemName>
            {itemsMenu.map((item, index) => (
              <ListItem key={index} onClick={item.onClick}>
                <Icon icon={item.icon} color="#4F5356" />
                <Label>{item.label}</Label>
              </ListItem>
            ))}
          </List>
        </MenuCollapsed>
      </Container>
    </>
  )
}

export default Menu
