/* import external modules */
import React from 'react'
import { Row, Col } from 'styled-bootstrap-grid'

/* import internal modules */
import {
  Footer,
  Container,
  LinkContact,
  RowDirectionElements,
  DivMobile,
  RowCopyright,
  RowDirection,
} from './styles'
import Copyright from '../Copyright'
import PayMethods from '../PayMethods'
import cname from '../../../constants/classNameData'
import Img from '../Img'
import Icon from '../Icon'

/** @description This functional component is a footer of the project
 *  @version 1.0.0
 *  @since 17/11/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name Footer
 *  @returns {Component} Returns components with sections of footer for each Col
 **/

const FooterComponent = () => {
  return (
    <Footer id="footer">
      <Container fluid>
        <Row>
          <Col col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <Row>
              <Col col xxl="3" xl="3" lg="3" md="12" sm="12" xs="12">
                <LinkContact textDecoration="underline" paddingTop="10px">
                  <a className={cname.mail_services}  href="mailto:servicioalcliente@eltiempoads.com" >
                    <Icon icon="mail" color="#ffffff" />
                    servicioalcliente@eltiempoads.com
                  </a>
                </LinkContact>
              </Col>
              <Col col xxl="6" xl="6" lg="6" md="12" sm="12" xs="12">
                <RowDirectionElements paddingTop="10px" marginTop>
                  <LinkContact fontSize="14px">
                    <Icon icon="clock" color="#ffffff" />
                    Horario de atención
                  </LinkContact>
                </RowDirectionElements>
                <RowDirection>
                  <RowDirectionElements paddingTop="10px">
                    <LinkContact color="#A8ADB7" marginLeft="35px">
                      Lunes a viernes: <span> 6:00 a.m. - 6:00 p.m.</span>
                    </LinkContact>
                  </RowDirectionElements>
                </RowDirection>
              </Col>
            </Row>
          </Col>
          <Col col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
            <DivMobile paddingTop="48px">
              <Row>
                <Col col xxl="7" xl="7" lg="7" md="12" sm="12" xs="12">
                  <RowCopyright>
                    <Img
                      icon="images/logo/logo-etce-white.png"
                      width="100px"
                      alt="Logo El Tiempo casa editorial"
                      marginRight="45px"
                    />
                    <Copyright />
                  </RowCopyright>
                </Col>
                <Col col xxl="5" xl="5" lg="5" md="12" sm="12" xs="12">
                  <PayMethods />
                </Col>
              </Row>
            </DivMobile>
          </Col>
        </Row>
      </Container>
    </Footer>
  )
}

export default FooterComponent
