import axios from 'axios'
import moment from 'moment'

import { USER_AUTH_KEY } from '../../constants/localStorage'
import { notificationsStatus } from '../../constants/notificationStatus'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

/**
 * Method to get notifications for auth user
 */
export const getNotifications = () => {
  const user = JSON.parse(window.sessionStorage.getItem(USER_AUTH_KEY))
  const format = 'YYYY-MM-DDTHH:mm:ss';
  const startDate = moment()
    .startOf('d')
    .subtract(1, 'month')
    .format(format);
  const endDate = moment().format(format);

  return axios.get(`${API_ENDPOINT}/event-notifications`, {
    params: {
      filter: {
        where: {
          userId: user.userId,
          status: { neq: notificationsStatus.deleted },
          createdAt: { between: [startDate, endDate] }
        },
      },
    },
  })
}

/**
 * Method to delete a notification by id
 * @param {number} id notification id
 * @returns {Promise}
 */
export const deleteNotification = (id) => {
  return axios.delete(`${API_ENDPOINT}/event-notifications/${id}`)
}

/**
 * Method to update a notification info
 * @param {number} id notification identifier
 * @param {Object} body object with new notification info
 * @returns {Promise}
 */
export const updateNotification = (id, body) => {
  return axios.patch(`${API_ENDPOINT}/event-notifications/${id}`, body)
}
