import styled from 'styled-components'

export const LoadContent = styled.div`
  width: 100%;
  background: rgba(255, 255, 255, 0.98);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: fixed;
  z-index: 110;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`
export const LoadText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
