import styled from 'styled-components'

export const WrapperHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: 20px;
  padding-right: 20px;
  > div {
    max-width: 150px;
  }
  @media(max-width: 1024px) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0;
    > div {
      width: calc(50% - 10px);
      float: left;
      max-width: 100%;
    }
    > div:first-child {
      width: 100%;
    }
    > div:nth-child(even) {
      margin-right: 10px;
    }
  }
`
export const Badge = styled.div`
  color: #CF8310;
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;

  .Report--mobile {
    display: none;
  }
  .Report--desktop {
    display: initial;
  }
  @media(max-width: 1024px) {
    margin: 18px 0;
    .Report--desktop {
    display: none;
    }
    .Report--mobile {
      display: initial;
    }
  }
  svg {
    flex-shrink: 0;
    margin-right: 10px;
  }
  path {
    fill: #CF8310;
  }
`

export const Format = styled.div`
  color: #252627;
  font: 14px/24px 'DMSans-Medium';
  svg {
    fill: #8E9194;
    margin-right: 5px;
  }
`