import _ from "lodash";

export const groupPortals = (advertisingPortals) => {
  let portals = []

  if (Boolean(advertisingPortals)) {
    advertisingPortals.forEach((item) => {
      portals.push(item)
    })
  }

  return _.join(_.uniq(portals), ', ')
}