/* import external modules */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

/* import internal modules */
import AdvertiseItem from '../AdvertiseItem'
import {
  getCurrentResource,
  getCurrentResourceClick,
  getFormatDate,
  getSubtractFormatDate,
} from '../../../herpers/listCampaigns'
import { AdvertisingType } from '../../../constants/advertisingType'
import {descryptImgUrl} from "../../../herpers/descriptImgUrl";
import AdvertiseItemClicks from '../AdvertiseItemClicks'
import CardTitleInfo from '../AdvertiseItemClicks/CardTitle'

/** @description This is a container functional component of AdvertiseList
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *  @author Franklin Marquez <framar.ext@eltiempo.com>
 *
 *  @function
 *  @name AdvertiseList
 *  @param {array} advertisements Receives an array of advertisements
 *  @param {string} startDate Initial date of campaign
 *  @param {string} id Id of campaign for upload button
 *  @returns {Component} Returns the container component of the advertise list
 **/

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const AdvertiseList = ({
  startDate,
  id,
  advertisementsType,
  adSponsoredContentsType,
  campaignName,
  campaignId
}) => {
  const [formatsMap, setFormatsMap] = useState({})
  const [devicesMap, setDevicesMap] = useState({})
  const [distributionMap, setDistributionMap] = useState({})
  const [imageFormatMap, setImageFormat] = useState({})
  const [resourcesMap, setResourcesMap] = useState({})
  const [resourcesClickMap, setResourcesClickMap] = useState({})
  const [portalSponsoredMap, setPortalSponsoredMap] = useState({})
  const listAdsOrder = advertisementsType.concat(adSponsoredContentsType)
  listAdsOrder.sort((a, b) => a.sort - b.sort)

  useEffect(() => {
    handleAdvertisements()
    handleAdsSponsored()
  }, [advertisementsType, adSponsoredContentsType])

  const handleAdvertisements = () => {
    const tmpFormatsMap = {}
    const tmpDevicesMap = {}
    const tmpDistMap = {}
    const tmpResourcesMap = {}
    const tmpResourcesClickMap = {}
    const tmpImageFormat = {}
    advertisementsType.forEach((ad) => {
      // console.log(ad,'<--ad');
      tmpDistMap[ad.advertisingId] = ad.advertisingDetails
        ? ad.advertisingDetails.map(
            (a) =>
              a.formatBySectionOnDisplay.digitalPropertyBySection
                .digitalProperty.name
          )
        : ad.advertisingDetailSegmentations.map(
            (a) => a.segmentationScope.segmentationMatch.code
          )
      tmpFormatsMap[ad.advertisingId] = ad.advertisingDetails
        ? ad.advertisingDetails[0].formatBySectionOnDisplay.formatDisplay.format
        : ad.advertisingDetailSegmentations
        ? ad.advertisingDetailSegmentations[0].segmentationScope.formatDisplay
            .format
        : null
      tmpDevicesMap[ad.advertisingId] = ad.advertisingDetails
        ? ad.advertisingDetails[0].formatBySectionOnDisplay.formatDisplay
            .displayId
        : ad.advertisingDetailSegmentations
        ? ad.advertisingDetailSegmentations[0].segmentationScope.formatDisplay
            .displayId
        : null
      tmpImageFormat[ad.advertisingId] = ad.advertisingDetails
        ? ad.advertisingDetails[0].formatBySectionOnDisplay.formatDisplay
            .imgPreview
        : ad.advertisingDetailSegmentations
        ? ad.advertisingDetailSegmentations[0].segmentationScope.formatDisplay
            .imgPreview
        : ''
      ad.resources && (tmpResourcesMap[ad.advertisingId] = getCurrentResource(ad.resources))
      ad.resourcesClick && (tmpResourcesClickMap[ad.advertisingId] = getCurrentResourceClick(ad.resourcesClick))
    })
    setImageFormat(tmpImageFormat)
    setFormatsMap(tmpFormatsMap)
    setDevicesMap(tmpDevicesMap)
    setDistributionMap(tmpDistMap)
    setResourcesMap(tmpResourcesMap)
    setResourcesClickMap(tmpResourcesClickMap)
  }

  // console.log(distributionMap[ad.advertisingId]);
  // console.log(distributionMap);
  
  const handleAdsSponsored = () => {
    const tmpPortalMap = {}
    adSponsoredContentsType.forEach((ad) => {
      tmpPortalMap[
        ad.adSponsoredContentId
      ] = ad.adSponsoredContentPricingAdContents.map(
        (a) =>
          a.pricingAdContent.advertisingTypeDigitalProperties.digitalProperty
            .name
      )
    })
    setPortalSponsoredMap(tmpPortalMap)
  }
  const funcionsdep = (ad)=>{
    let arr = []
    const displayArr = imageFormatMap[ad.advertisingId] ? imageFormatMap[ad.advertisingId].split(",") : ''
    if (resourcesClickMap[ad.advertisingId] && resourcesClickMap[ad.advertisingId].src_robapagina) {
      arr.push({'src':resourcesClickMap[ad.advertisingId].src_robapagina,
                'status':resourcesClickMap[ad.advertisingId].statusRobapagina,
                'observation':resourcesClickMap[ad.advertisingId].observationRobapagina,
                'format':{description:"Robapágina",fileSize:150000,formatId:1,height:250,name:"Robapágina",status:1,width:300}
              })
    }
    if (resourcesClickMap[ad.advertisingId] && resourcesClickMap[ad.advertisingId].src_bilborad) {
      arr.push({'src':resourcesClickMap[ad.advertisingId].src_bilborad,
                'status':resourcesClickMap[ad.advertisingId].statusBilborad,
                'observation':resourcesClickMap[ad.advertisingId].observationBillboard,
                'format':{description:"Billboard",fileSize:150000,formatId:2,height:250,name:"Billboard",status:1,width:970}
              })
    }
    if (resourcesClickMap[ad.advertisingId] && resourcesClickMap[ad.advertisingId].src_banner) {
      arr.push({'src':resourcesClickMap[ad.advertisingId].src_banner,
                'status':resourcesClickMap[ad.advertisingId].statusBanner,
                'observation':resourcesClickMap[ad.advertisingId].observationBanner,
                'format':{description:"Banner Gigante",fileSize:150000,formatId:3,height:90,name:"Banner Gigante",status:1,width:728}
              })
    }
    if (resourcesClickMap[ad.advertisingId] && resourcesClickMap[ad.advertisingId].src_catfist) {
      arr.push({'src':resourcesClickMap[ad.advertisingId].src_catfist,
                'status':resourcesClickMap[ad.advertisingId].statusCatfist,
                'observation':resourcesClickMap[ad.advertisingId].observationCatfish,
                'format':{description:"Catfish",fileSize:150000,formatId:5,height:50,name:"Catfish",status:1,width:320}
              })
    }
    const prueba = arr.map((element, i)=> {
      const shouldRenderCardTitleInfo = i === 0;
      return (
        <>
        
        
        {shouldRenderCardTitleInfo && (
          <CardTitleInfo
        key={i}
        typeAd={AdvertisingType.Clicks}
        numCampaign={id+i}
        numAdvertise={ad.sort}
        advertisements={ad.advertisingDetails}
        advertisingTypeId={ad.advertisingTypeId}
        statusCode={
          resourcesClickMap[ad.advertisingId]
            ? element.status
            : 2
        }
        review={
          resourcesClickMap[ad.advertisingId]
            ? element.observation
            : ''
        }
        imgAd={`${API_ENDPOINT}/${
          resourcesClickMap[ad.advertisingId]
            ? descryptImgUrl(element.src)
            : ''
        }`}
        adId={
          resourcesClickMap[ad.advertisingId]
            ? resourcesClickMap[ad.advertisingId].advertisingId
            : ''
        }
        format={element.format}
        device={devicesMap[ad.advertisingId] === 1 ? 'desktop' : 'mobile'}
        imageTemplate={displayArr[i]}
        typeSegment={ad.advertisingTypeId}
        infoSegmentPortal={
          ad.advertisingTypeId === 5 ? distributionMap[ad.advertisingId] : null
        }
        infoSegmentAudience={
          ad.advertisingTypeId === 5 ? null : distributionMap[ad.advertisingId]
        }        
        impressions={`${new Intl.NumberFormat('de-DE').format(
          Number(ad.views)
        )}`}
        url={ad.targetUrl || 'N/A'}
        initialDate={getFormatDate(ad.dateStart)}
        endDate={getFormatDate(ad.dateEnd)}
        urlReport={ad.report ? `${API_ENDPOINT}/${ad.report}` : null}
        limitDate={startDate}
        urlSmart={ad.smartTrackingLink}
        reportDataStudio={ad.report}
        reportType={ad.reportType}
        campaignName={campaignName}
        campaignId={campaignId}
        nameFormat={element.format.formatId}
      />
        )}
      
      <AdvertiseItemClicks
        key={i}
        typeAd={AdvertisingType.Clicks}
        numCampaign={id+i}
        numAdvertise={ad.sort}
        numPauta= {i+1} 
        advertisements={ad.advertisingDetails}
        advertisingTypeId={ad.advertisingTypeId}
        statusCode={
          resourcesClickMap[ad.advertisingId]
            ? element.status
            : 2
        }
        review={
          resourcesClickMap[ad.advertisingId]
            ? resourcesClickMap[ad.advertisingId].observation
            : ''
        }
        imgAd={`${API_ENDPOINT}/${
          resourcesClickMap[ad.advertisingId]
            ? descryptImgUrl(element.src)
            : ''
        }`}
        adId={
          resourcesClickMap[ad.advertisingId]
            ? resourcesClickMap[ad.advertisingId].advertisingId
            : ''
        }
        format={element.format}
        device={devicesMap[ad.advertisingId] === 1 ? 'desktop' : 'mobile'}
        imageTemplate={displayArr[i]}
        typeSegment={ad.advertisingTypeId}
        infoSegmentPortal={
          ad.advertisingTypeId === 5 ? distributionMap[ad.advertisingId] : null
        }
        infoSegmentAudience={
          ad.advertisingTypeId === 5 ? null : distributionMap[ad.advertisingId]
        }        
        impressions={`${new Intl.NumberFormat('de-DE').format(
          Number(ad.views)
        )}`}
        url={ad.targetUrl || 'N/A'}
        initialDate={getFormatDate(ad.dateStart)}
        endDate={getFormatDate(ad.dateEnd)}
        urlReport={ad.report ? `${API_ENDPOINT}/${ad.report}` : null}
        limitDate={startDate}
        urlSmart={ad.smartTrackingLink}
        reportDataStudio={ad.report}
        reportType={ad.reportType}
        campaignName={campaignName}
        campaignId={campaignId}
        nameFormat={element.format.formatId}
      />
    </>
      )
    }
    
      
    )
    return prueba
  }

  return listAdsOrder?.map((ad, i) =>
    ad.advertisingTypeId && ad.advertisingType.code !== 'CPC' ? (
      <AdvertiseItem
        key={i}
        typeAd={AdvertisingType.Display}
        numCampaign={id}
        numAdvertise={ad.sort}
        advertisements={ad.advertisingDetails}
        advertisingTypeId={ad.advertisingTypeId}
        statusCode={
          resourcesMap[ad.advertisingId]
            ? resourcesMap[ad.advertisingId].status
            : 2
        }
        review={
          resourcesMap[ad.advertisingId]
            ? resourcesMap[ad.advertisingId].observation
            : ''
        }
        imgAd={`${API_ENDPOINT}/${
          resourcesMap[ad.advertisingId]
            ? descryptImgUrl(resourcesMap[ad.advertisingId].src)
            : ''
        }`}
        adId={
          resourcesMap[ad.advertisingId]
            ? resourcesMap[ad.advertisingId].advertisingId
            : ''
        }
        format={formatsMap[ad.advertisingId]}
        device={devicesMap[ad.advertisingId] === 1 ? 'desktop' : 'mobile'}
        imageTemplate={imageFormatMap[ad.advertisingId]}
        typeSegment={ad.advertisingTypeId}
        infoSegmentAudience={
          ad.advertisingTypeId === 1 ? null : distributionMap[ad.advertisingId]
        }
        infoSegmentPortal={
          ad.advertisingTypeId === 1 ? distributionMap[ad.advertisingId] : null
        }
        impressions={`${new Intl.NumberFormat('de-DE').format(
          Number(ad.views)
        )}`}
        url={ad.targetUrl || 'N/A'}
        mathilde={ad.mathilde ? ad.mathilde[0].percentage : 'N/A'}
        mathildeViews={ad.mathilde ? ad.mathilde[0].views : 'N/A'}
        initialDate={getFormatDate(ad.dateStart)}
        endDate={getFormatDate(ad.dateEnd)}
        urlReport={ad.report ? `${API_ENDPOINT}/${ad.report}` : null}
        limitDate={startDate}
        urlSmart={ad.smartTrackingLink}
        reportDataStudio={ad.report}
        reportType={ad.reportType}
        campaignName={campaignName}
        campaignId={campaignId}
      />
    ) :
    ad.advertisingTypeId && ad.advertisingType.code === 'CPC' ? ( funcionsdep(ad) ) 
    : (
      <AdvertiseItemClicks
        key={i}
        typeAd={AdvertisingType.Content}
        numCampaign={id}
        numAdvertise={ad.sort}
        adContent={ad}
        typeSegment={1}
        statusCode={ad.status}
        review={ad.resourcesContents[0].observation}
        infoSegmentPortal={portalSponsoredMap[ad.adSponsoredContentId]}
        initialDate={getFormatDate(ad.dateStart)}
        endDate={getFormatDate(ad.dateEnd)}
        urlReport={ad.report && `${API_ENDPOINT}/${ad.report}`}
        limitDate={getSubtractFormatDate(ad.dateStart, 1)}
        reportDataStudio={ad.report}
        reportType={ad.reportType}
        campaignName={campaignName}
        campaignId={campaignId}
      />
    )
  )
}

AdvertiseList.propTypes = {
  advertisements: PropTypes.array,
  startDate: PropTypes.string,
  id: PropTypes.string,
  advertisementsType: PropTypes.array,
  adSponsoredContentsType: PropTypes.array,
}

export default AdvertiseList
