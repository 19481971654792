const messages = {
  viewReport: 'Consultar el reporte',
  reportDisabled:
    'El reporte estará disponible 3 días después del inicio de la publicación',
  deleteNotificationSuccessAlert: 'La notificación fue borrada exitosamente',
  deleteNotification: 'Borrar notificación',
  messageTitleDeleteNotification: 'Borrar la notificación',
  messageDeleteNotification: '¿Deseas borrar la notificación seleccionada?',
  uploadMaterialBeforeDate: 'Por favor carga un archivo antes del',
  maximiumWeight: 'El archivo supera el peso máximo',

  // status campaings
  materialApproved: 'Materiales aprobados',
  materialReview: 'Materiales en revisión',
  materialReject: 'Materiales rechazados',
  campaignCanceled: 'Campaña cancelada',
  campaignFinished: 'Campaña finalizada',
  campaignProgress: 'Campaña en curso',

  // messages of upload image
  messageErrorExceededWeight: 'El archivo supera el peso máximo',
  messageErrorInvalidDimensions: 'Las dimensiones del archivo no son válidas',
  messageErrorInvalidFormat: 'El formato del archivo no es válido',
  messageErrorImage: 'El archivo no cumple con las especifícaciones',
  messageSuccess: 'El archivo fue cargado exitosamente',

  messageChangeMaterial: '¿Estás seguro que quieres reemplazar el material?',
  messageReplaceMaterial: 'El archivo fue reemplazado exitosamente el',
  messageDefaultSponsoredTitle: 'CONTENIDO PATROCINADO',
  messageDefaultDateSponsored: 'FECHA DEL ARTÍCULO',
  messageTextUrl: 'Ver desempeño',
  messageViewReport: 'Ver reporte',
  messageTooltipPrint: 'Número de impresiones que compraste para esta pauta',
  messageTooltipClics: 'Número de veces que un usuario pulsa sobre tus piezas',
  messageReportDesktop:  ' puedes hacerlo mediante el clic derecho del mouse',
  messageReportMobile:  ' puedes hacerlo presionando sobre el informe hasta que aparezca la opción',
}

export const statusSuccess = {
  message: 'Los cambios se guardaron exitosamente',
  icon: 'check',
  status: 'success',
}

export const statusError = {
  icon: 'close',
  status: 'error',
}

export default messages
