/* import external modules */
import React from 'react'

/* import internal modules */
import Img from '../Img'
import { Container, Row, Col } from './styles'

const PayMethods = (props) => {
  return (
    <Container>
      <Row alignItems="center" {...props}>
        <Col col>
          <Img
            alt="Logo visa"
            icon="images/payments-icons/visa-logo-footer.png"
            width="50px"
          />
          <Img
            alt="Logo mastercard"
            icon="images/payments-icons/mastercard-logo-footer.png"
            width="125px"
          />
          <Img
            alt="Logo american express"
            icon="images/payments-icons/american-express-logo-footer.svg"
            width="40px"
          />
          <Img
            alt="Logo diners"
            icon="images/payments-icons/diners-logo-footer.png"
            width="100px"
          />
          <Img
            alt="Logo pse"
            icon="images/payments-icons/pse-logo-footer.png"
            width="30px"
          />
        </Col>
      </Row>
    </Container>
  )
}

export default PayMethods
