/**
 * @name {script} scrollToId
 * @description Captures the top position of the id element and sends the scroll with the indicated position
 * @param {string} id element id
 */

export const scrollToId = (id) => {
  setTimeout(() => {
    const yOffset = -90
    const element = document.getElementById(id)
    const yScroll =
      element.getBoundingClientRect().top + window.pageYOffset + yOffset
    window.scrollTo({ top: yScroll, behavior: 'smooth' })
  }, 100)
}
