/* import external modules */
import React from 'react'

/* import internal modules */
import Badge from '../Badge'
import Icon from '../Icon'
import LinkIconActive from '../LinkIcon'

import messages from '../../../constants/messages'

import { LinkReportDisabled, CaptionAnchor } from './styles'

/** @description This is a container functional component of ExternalLinkReport
 ** @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *  @author Franklin Marquez <framar.ext@eltiempo.com>
 *
 *  @function
 *  @name ExternalLinkReport
 *  @returns {Component} Returns the container component of the external link report
 **/

const ExternalLinkReport = ({ nextDayFinalDate, urlReport, onClick, reportType }) => {
    return (
        urlReport || nextDayFinalDate && urlReport ? (
            <>
                {reportType === 1 &&
                <LinkIconActive url={urlReport} />
                }
                {reportType === 2 &&
                <LinkIconActive as="button" onClick={onClick} disabled/>
                }
            </>
        ) : (
            <LinkReportDisabled>
                <Icon icon="file" color="#A6A9AB" />
                <CaptionAnchor>{messages.messageViewReport}</CaptionAnchor>
                <Badge className="badge-report" text={messages.reportDisabled} />
            </LinkReportDisabled>
        )
    )

}

export default ExternalLinkReport
