/* import external modules */
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Row, Col } from 'styled-bootstrap-grid'

/* import internal modules */
import WithoutCampaigns from '../../WithoutCampaigns'
import P from '../../Common/P'
import CampaignsList from '../CampaignsList'

/** @description This is a container functional component of CampaignsAndMaterials
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name CampaignsAndMaterials
 *  @param {array} campaignsList List of purchased campaigns
 *  @param {bool} loaded If request api was finally
 *  @returns {Component} Returns the container component of the Campaigns And Materials
 **/

const CampaignsAndMaterials = ({ campaignsList, loaded }) => {
  
  return campaignsList && campaignsList.length > 0 ? (
    <>
      <Row>
        <Col col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
          <P
            color="#252627"
            font="30px/38px'DMSans-Medium'"
            fontSm="20px/28px'DMSans-Medium'"
            p="5px 0 50px 0"
            pSm="5px 0 45px 0">
            Mis campañas y materiales
          </P>
        </Col>
      </Row>
      <Row>
        <Col col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
          <CampaignsList campaignsList={campaignsList} />
        </Col>
      </Row>
    </>
  ) : loaded ? (
    <WithoutCampaigns />
  ) : null
}

CampaignsAndMaterials.propTypes = {
  campaignsList: PropTypes.array,
  loaded: PropTypes.bool,
}

export default CampaignsAndMaterials
