import {
  SET_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  UPDATE_NOTIFICATION_STATUS,
} from '../../types/types'

export const setNotifications = (notifications) => ({
  type: SET_NOTIFICATIONS,
  payload: { notifications },
})

export const removeNotification = (id) => ({
  type: DELETE_NOTIFICATION,
  payload: { notificationId: id },
})

export const updateNotificationStatus = (id, status) => ({
  type: UPDATE_NOTIFICATION_STATUS,
  payload: { notificationId: id, status },
})
