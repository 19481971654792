/* import external modules */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Container } from 'styled-bootstrap-grid'

/* import internal modules */
import Portal from '../Modal/portal'

import useDebounce from '../../../hooks/useDebounce'

import { ModalWrapper } from './styles'
import Button from '../Button'

/** @description This is a container functional component of ModalFullwidth
 **  @version 1.0.0
 *  @since 21/05/2021
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *
 *  @function
 *  @name ModalFullwidth
 *  @params {boolean} open Is open Modal
 *  @params {function} onClose Is close Modal
 *  @params {component} Is children modal
 *  @returns {Component} Returns the container component of the Modal fullwidth with transitions
 **/

const ModalFullwidth = ({ open, children, onClose }) => {
  const [delayClose, setDelayClose] = useState(false)
  const classModalWidth = 'ModalWidth--overflow'
  const classModalHeader = 'ModalHeader--overflow'
  const headerId = 'header'

  const { next } = useDebounce(() => {
    document.body.classList.remove(classModalWidth)
    document.getElementById(headerId).classList.remove(classModalHeader)
    onClose()
  }, 350)

  useEffect(() => {
    if(open) {
      document.body.classList.add(classModalWidth)
      document.getElementById(headerId).classList.add(classModalHeader)
    }
  }, [open])

  const handleClose = () => {
    setDelayClose(true)
    next()
  }

  return (
    <Portal selector="#modal">
      <ModalWrapper visible={open} delay={delayClose}>
        <Container fluid>
          <Button
            text="Cerrar"
            onClick={handleClose}
            className="ModalReportClose"
            iconNew="closeModal"
          />
          {children}
        </Container>
      </ModalWrapper>
    </Portal>
  )
}


ModalFullwidth.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired
}

export default ModalFullwidth
