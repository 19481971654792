/* import external modules */
import styled from 'styled-components'
import { Row as RowComponent, Col as ColComponent } from 'styled-bootstrap-grid'

export const Container = styled.div`
  display: flex;
`
export const Row = styled(RowComponent)`
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
`
export const Col = styled(ColComponent)`
  padding-right: 0;
  img {
    vertical-align: middle;
    margin-right: 20px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-right: 0 !important;
    }
  }
`
