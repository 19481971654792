/* import external modules */
import React from 'react'

/* import internal modules */
import { Button } from './styles'
import Icon from '../Icon'

const ButtonShow = ({ onClick, active, ...props }) => {
  return (
    <Button onClick={onClick} {...props}>
      {active ? (
        <>
          <Icon icon="squareUp" color="#006ca5" /> Ocultar detalle
        </>
      ) : (
        <>
          <Icon icon="squareDown" color="#006ca5" />
          Ver detalle
        </>
      )}
    </Button>
  )
}

export default ButtonShow
