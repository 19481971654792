/* import external modules */
import styled from 'styled-components'
import { Container as ContainerComponent } from 'styled-bootstrap-grid'

export const WrapperMain = styled.main`
  width: 100%;
  height: auto;
  background: ${({ theme }) => theme.colors.graySuperLightness};
`

export const Container = styled(ContainerComponent)`
  max-width: 1200px;
  margin-top: 60px;
  padding-top: 45px;
  padding-bottom: 60px;
  min-height: calc(100vh - 375px);
  @media (max-width: 768px) {
    margin-top: 50px;
    padding-top: 30px;
    padding-bottom: 50px;
    min-height: calc(100vh - 325px);
  }
`
