export default {
    header_button_bell:'boton_campania_header',
    first_button_bell:'boton_campania_primera',
    menu_user:{
        name_user:'nombre_usuario',
        sign_off:'cerrar_sesion',
    },
    home_logo:'logo_inicio',
    notifications:'cuadro_notificaciones_usuario',
    see_detail:'ver_detalle',
    hide_detail:'ocultar_detalle',
    mail_services:'correo_servicio',
}