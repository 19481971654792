/* import external modules */
import React from 'react'

/* import internal modules */
import { Paragraph } from './styles'

const P = ({ ...props }) => {
  return <Paragraph {...props}>{props.children}</Paragraph>
}

export default P
