/* import external modules */
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
`
export const Img = styled.img`
  margin: ${(props) => (props.margin ? props.margin : null)};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
`

export const Svg = styled.svg`
  margin: ${(props) => (props.margin ? props.margin : null)};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : null)};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : null)};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : null)};
`
