/* import external modules */
import React from 'react'

/* import internal modules */
import { ButtonMain } from './styles'
import Img from '../Img'
import Icon from '../Icon'

const Button = ({ classt = false,...props }) => {
  return (
    <ButtonMain {...props} className={classt && classt}>
      {props.text}
      {props.icon && <Img icon={props.icon} width={20} marginLeft="8px" />}
      {props.iconNew && (
        <Icon icon={props.iconNew} color="#006CA5" marginLeft="8px" />
      )}
    </ButtonMain>
  )
}

export default Button
