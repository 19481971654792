export const ICONS = {
  check: {
    width: 50,
    height: 50,
    viewBox: '0 0 50 50',
    paths: [
      'M22.7,34 14.8,27.3 18,23.4 22.2,26.9 31.3,16 35.2,19.2',
      'M25,0.6C11.6,0.6,0.6,11.6,0.6,25s11,24.4,24.4,24.4s24.4-11,24.4-24.4S38.4,0.6,25,0.6z M25,45.8C13.5,45.8,4.2,36.5,4.2,25S13.5,4.2,25,4.2S45.8,13.5,45.8,25S36.5,45.8,25,45.8z',
    ],
  },
  close: {
    width: 10,
    height: 10,
    viewBox: '0 0 10 10',
    paths: [
      'M10.1,2.1 8,0 5.1,2.9 2.1,0 0,2.1 2.9,5.1 0,8 2.1,10.1 5.1,7.2 8,10.1 10.1,8 7.2,5.1',
    ],
  },
  closeModal: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: [
      'M7,13.5L9.3,10L7,6.5h1.5l1.6,2.4l1.4-2.4H13L10.8,10l2.2,3.5h-1.5L9.9,11l-1.4,2.5H7z',
      'M10,0.1c-5.5,0-9.9,4.4-9.9,9.9s4.4,9.9,9.9,9.9s9.9-4.4,9.9-9.9S15.5,0.1,10,0.1z M10,18.8c-4.9,0-8.8-3.9-8.8-8.8S5.1,1.2,10,1.2s8.8,3.9,8.8,8.8S14.9,18.8,10,18.8z',
    ],
  },
  upload: {
    width: 18,
    height: 18,
    viewBox: '0 0 18 18',
    paths: [
      'M10.4,13.5H7.6c-0.5,0-0.8-0.4-0.8-0.8c0,0,0,0,0,0V6.7H3.7C3.3,6.8,3,6.4,3,6.1C3,5.9,3,5.7,3.2,5.5l5.4-5.3c0.3-0.3,0.7-0.3,1,0l5.4,5.4c0.3,0.3,0.3,0.7,0,1c-0.1,0.1-0.3,0.2-0.5,0.2h-3.1v5.9C11.3,13.1,10.9,13.5,10.4,13.5C10.4,13.5,10.4,13.5,10.4,13.5z M18,13.2v3.9c0,0.5-0.4,0.8-0.8,0.8c0,0,0,0,0,0H0.8C0.4,18,0,17.6,0,17.2c0,0,0,0,0,0v-3.9c0-0.5,0.4-0.8,0.8-0.8c0,0,0,0,0,0h4.8v0.3c0,1.1,0.9,2,2,2c0,0,0,0,0,0h2.8c1.1,0,2-0.9,2-2v-0.3h4.8C17.6,12.4,18,12.7,18,13.2C18,13.2,18,13.2,18,13.2z M13.6,16.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7C13.3,17,13.6,16.7,13.6,16.3z M15.9,16.3c0-0.4-0.3-0.7-0.7-0.7c-0.4,0-0.7,0.3-0.7,0.7c0,0.4,0.3,0.7,0.7,0.7C15.6,17,15.9,16.7,15.9,16.3z',
    ],
  },
  desktop: {
    width: 15,
    height: 12,
    viewBox: '0 0 15 12',
    paths: [
      'M14.625,9.75H8.942a.739.739,0,0,1-.767.75H6.75a.8.8,0,0,1-.768-.75H.375A.376.376,0,0,0,0,10.125V10.5A1.5,1.5,0,0,0,1.5,12h12A1.5,1.5,0,0,0,15,10.5v-.375A.376.376,0,0,0,14.625,9.75ZM13.5,1.125A1.128,1.128,0,0,0,12.375,0H2.625A1.128,1.128,0,0,0,1.5,1.125V9h12ZM12,7.5H3v-6h9Z',
    ],
  },
  mobile: {
    width: 10,
    height: 15,
    viewBox: '0 0 10 15',
    paths: [
      'M7.969,0H1.406A1.407,1.407,0,0,0,0,1.406V13.594A1.407,1.407,0,0,0,1.406,15H7.969a1.407,1.407,0,0,0,1.406-1.406V1.406A1.407,1.407,0,0,0,7.969,0ZM4.688,14.063a.938.938,0,1,1,.938-.937A.936.936,0,0,1,4.688,14.063ZM7.969,10.9a.353.353,0,0,1-.352.352H1.758a.353.353,0,0,1-.352-.352V1.758a.353.353,0,0,1,.352-.352H7.617a.353.353,0,0,1,.352.352Z',
    ],
  },
  arrowDown: {
    width: 11,
    height: 6,
    viewBox: '0 0 11 6',
    paths: ['M5.5,6.5 0,1.5 1.4,0 5.5,3.9 10,0 11.3,1.5'],
  },
  trash: {
    width: 13,
    height: 15,
    viewBox: '0 0 13 15',
    paths: [
      'M8.47 12.14C8.66 12.14 8.82 11.99 8.82 11.79C8.82 11.79 8.82 11.79 8.82 11.79C8.82 11.17 8.82 6.15 8.82 5.53C8.82 5.33 8.66 5.18 8.47 5.18C8.47 5.18 8.47 5.18 8.47 5.18C8.4 5.18 7.85 5.18 7.78 5.18C7.58 5.18 7.43 5.33 7.43 5.53C7.43 5.53 7.43 5.53 7.43 5.53C7.43 6.15 7.43 11.17 7.43 11.79C7.43 11.99 7.59 12.14 7.78 12.14C7.78 12.14 8.4 12.14 8.47 12.14ZM10.14 2.39C9.55 1.41 9.22 0.86 9.15 0.75C8.9 0.33 8.45 0.07 7.96 0.07C7.67 0.07 5.33 0.07 5.04 0.07C4.55 0.07 4.09 0.33 3.84 0.75C3.78 0.86 3.45 1.41 2.85 2.39C1.42 2.39 0.62 2.39 0.46 2.39C0.21 2.39 0 2.6 0 2.86C0 2.9 0 3.27 0 3.32C0 3.58 0.21 3.79 0.46 3.79C0.5 3.79 0.65 3.79 0.93 3.79C0.93 9.64 0.93 12.89 0.93 13.54C0.93 14.31 1.55 14.93 2.32 14.93C3.16 14.93 9.84 14.93 10.68 14.93C11.45 14.93 12.07 14.31 12.07 13.54C12.07 13.54 12.07 13.54 12.07 13.54C12.07 12.89 12.07 9.64 12.07 3.79C12.35 3.79 12.5 3.79 12.54 3.79C12.79 3.79 13 3.58 13 3.32C13 3.27 13 2.9 13 2.86C13 2.6 12.79 2.39 12.54 2.39C12.53 2.39 11.74 2.39 10.14 2.39ZM4.99 1.55C5.02 1.5 5.07 1.46 5.14 1.46C5.41 1.46 7.59 1.46 7.86 1.46C7.92 1.46 7.98 1.5 8.01 1.55C8.05 1.6 8.22 1.89 8.52 2.39L4.48 2.39C4.75 1.94 4.92 1.66 4.99 1.55ZM10.68 13.53L2.32 13.53L2.32 3.79L10.68 3.79L10.68 13.53ZM5.22 12.14C5.41 12.14 5.57 11.99 5.57 11.79C5.57 11.79 5.57 11.79 5.57 11.79C5.57 11.17 5.57 6.15 5.57 5.53C5.57 5.33 5.41 5.18 5.22 5.18C5.22 5.18 5.22 5.18 5.22 5.18C5.15 5.18 4.6 5.18 4.53 5.18C4.33 5.18 4.18 5.33 4.18 5.53C4.18 5.53 4.18 5.53 4.18 5.53C4.18 6.15 4.18 11.17 4.18 11.79C4.18 11.99 4.33 12.14 4.53 12.14C4.53 12.14 5.15 12.14 5.22 12.14Z',
    ],
  },
  mail: {
    width: 18,
    height: 13.5,
    viewBox: '0 0 18 13.5',
    paths: [
      'M17.6,4.5c-0.8,0.7-2.6,2-5.4,4l-0.4,0.3c-0.4,0.3-0.9,0.6-1.3,0.9c-0.5,0.3-1,0.5-1.5,0.5c-0.5,0-1.1-0.2-1.5-0.5C7,9.4,6.5,9.1,6.1,8.8L5.8,8.4c-2.8-2.1-4.6-3.4-5.4-4c-0.1,0-0.2-0.1-0.2,0C0,4.5,0,4.6,0,4.6v7.2c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.7,0.5,1.2,0.5h14.6c0.5,0,0.9-0.2,1.2-0.5c0.3-0.3,0.5-0.7,0.5-1.2V4.6c0-0.1,0-0.2-0.1-0.2C17.8,4.4,17.7,4.4,17.6,4.5z M9,9C8.6,9,8.2,8.8,7.9,8.6C7.5,8.3,7.1,8.1,6.8,7.8L6.4,7.6C3.6,5.4,1.6,4,0.6,3.2L0.3,3C0.1,2.9,0,2.6,0,2.4V1.7c0-0.5,0.2-0.9,0.5-1.2C0.8,0.2,1.2,0,1.7,0h14.6c0.5,0,0.9,0.2,1.2,0.5C17.8,0.8,18,1.2,18,1.7v0.7c0,0.3-0.1,0.5-0.3,0.7l-0.2,0.1c-1,0.8-3,2.3-5.9,4.4l-0.4,0.2c-0.3,0.3-0.7,0.5-1.1,0.8C9.8,8.8,9.4,9,9,9z',
    ],
  },
  phone: {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    paths: [
      'M15.4,0.8c0.2,0,0.3,0.1,0.4,0.3C15.9,1.2,16,1.3,16,1.5c0,2.6-0.7,5.1-2,7.3c-1.3,2.2-3.1,4-5.2,5.2c-2.2,1.3-4.7,2-7.3,2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.3-0.4L0,12.1c0-0.2,0-0.3,0.1-0.5c0.1-0.2,0.2-0.3,0.4-0.3L4,9.8c0.1-0.1,0.3-0.1,0.5,0c0.2,0,0.3,0.1,0.4,0.3l1.5,1.9c1.2-0.6,2.3-1.3,3.3-2.3c0.9-0.9,1.7-2,2.3-3.3L10,4.8C9.9,4.7,9.8,4.6,9.8,4.4c0-0.2,0-0.3,0-0.5l1.5-3.5c0.1-0.2,0.2-0.3,0.3-0.4C11.8,0,12,0,12.1,0L15.4,0.8z',
    ],
  },
  clock: {
    width: 16,
    height: 16,
    viewBox: '0 0 15.5 15.5',
    paths: [
      'M11.25 1.02C12.38 1.68 13.32 2.62 13.98 3.75C14.66 4.88 15.01 6.18 15 7.5C15.01 8.82 14.66 10.12 13.98 11.25C13.32 12.38 12.38 13.32 11.25 13.98C10.12 14.66 8.82 15.01 7.5 15C6.18 15.01 4.88 14.66 3.75 13.98C2.62 13.32 1.68 12.38 1.02 11.25C0.34 10.12 -0.01 8.82 0 7.5C-0.01 6.18 0.34 4.88 1.02 3.75C1.68 2.62 2.62 1.68 3.75 1.02C4.88 0.34 6.18 -0.01 7.5 0C8.82 -0.01 10.12 0.34 11.25 1.02ZM9.22 10.59C9.3 10.65 9.4 10.67 9.5 10.65C9.59 10.63 9.68 10.57 9.74 10.5C9.82 10.38 10.5 9.46 10.58 9.35C10.64 9.27 10.67 9.17 10.66 9.07C10.65 8.98 10.6 8.89 10.52 8.83C10.39 8.74 9.75 8.28 8.59 7.44C8.59 4.94 8.59 3.55 8.59 3.27C8.59 3.17 8.55 3.08 8.48 3.01C8.42 2.94 8.32 2.9 8.23 2.9C8.08 2.9 6.92 2.9 6.77 2.9C6.68 2.9 6.58 2.94 6.52 3.01C6.45 3.08 6.41 3.17 6.41 3.27C6.41 3.77 6.41 7.84 6.41 8.35C6.41 8.47 6.47 8.58 6.56 8.65C7.09 9.04 8.69 10.2 9.22 10.59Z',
    ],
  },
  arrow: {
    width: 45,
    height: 21,
    viewBox: '0 0 45 21',
    paths: [
      'M22.6,20.8c-0.4,0-0.9-0.1-1.3-0.4L1.6,4.4c-0.9-0.7-1-2-0.3-2.8c0.7-0.9,2-1,2.8-0.3l18.6,15l18.9-15 c0.9-0.7,2.1-0.5,2.8,0.3c0.7,0.9,0.5,2.1-0.3,2.8l-20.2,16C23.5,20.7,23.1,20.8,22.6,20.8z',
    ],
  },
  report: {
    width: 15,
    height: 12,
    viewBox: '0 0 15 12',
    paths: [
      'M14.56 1.03C14.54 0.99 14.53 0.95 14.5 0.91C14.39 0.47 13.97 0.18 13.51 0.24C13.13 0.28 10.06 0.62 9.67 0.66C9.16 0.72 8.79 1.18 8.85 1.69C8.9 2.2 9.36 2.57 9.87 2.51C9.99 2.5 10.55 2.44 11.57 2.33L7.76 7.33C6.3 6.47 5.49 5.99 5.32 5.89C4.92 5.65 4.4 5.75 4.11 6.13C3.8 6.54 1.25 9.86 0.93 10.27C0.61 10.68 0.69 11.26 1.1 11.58C1.51 11.89 2.09 11.81 2.4 11.4C2.58 11.17 3.48 10.01 5.09 7.91C6.55 8.78 7.36 9.26 7.53 9.36C7.93 9.6 8.46 9.5 8.74 9.12C9.04 8.73 10.51 6.79 13.16 3.3C13.35 4.15 13.46 4.62 13.48 4.72C13.58 5.18 14.02 5.49 14.49 5.44C14.52 5.44 14.56 5.43 14.59 5.42C15.09 5.31 15.41 4.81 15.29 4.31C15.29 4.31 14.63 1.36 14.56 1.03Z',
    ],
  },
  signOut: {
    width: 19,
    height: 15,
    viewBox: '0 0 19 15',
    paths: [
      'M19.4,8.2l-6.6,6.6c-0.4,0.4-1,0.4-1.3,0c-0.2-0.2-0.3-0.4-0.3-0.7v-3.7H5.9C5.4,10.3,5,9.9,5,9.4c0,0,0,0,0,0V5.6c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0h5.3V0.9c0-0.5,0.4-0.9,0.9-0.9c0.2,0,0.5,0.1,0.7,0.3l6.6,6.6C19.8,7.2,19.8,7.8,19.4,8.2zM7.5,14.5V13c0-0.3-0.2-0.5-0.5-0.5H3.7c-0.7,0-1.2-0.6-1.3-1.2c0,0,0,0,0,0V3.7c0-0.7,0.6-1.2,1.2-1.3c0,0,0,0,0,0H7c0.3,0,0.5-0.2,0.5-0.5V0.5C7.5,0.2,7.3,0,7,0H3.7C1.7,0,0,1.7,0,3.7v7.5C0,13.3,1.7,15,3.7,15H7C7.3,15,7.5,14.8,7.5,14.5z',
    ],
  },
  clipBoard: {
    width: 14,
    height: 18,
    viewBox: '0 0 13 18',
    paths: [
      'M11.8,2.2H9C9,1,8,0,6.8,0S4.5,1,4.5,2.2H1.7C0.8,2.2,0,3,0,3.9c0,0,0,0,0,0v12.4C0,17.2,0.8,18,1.7,18h10.1c0.9,0,1.7-0.8,1.7-1.7V3.9C13.5,3,12.7,2.3,11.8,2.2z M3.4,14.9c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0,0C4.2,14.5,3.8,14.9,3.4,14.9C3.4,14.9,3.4,14.9,3.4,14.9z M3.4,11.5c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8l0,0C4.2,11.2,3.8,11.5,3.4,11.5C3.4,11.5,3.4,11.5,3.4,11.5z M3.4,8.2c-0.5,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8c0.5,0,0.8,0.4,0.8,0.8l0,0C4.2,7.8,3.8,8.2,3.4,8.2C3.4,8.2,3.4,8.2,3.4,8.2z M6.8,1.4c0.5,0,0.8,0.4,0.8,0.8S7.2,3.1,6.8,3.1S5.9,2.7,5.9,2.2l0,0C5.9,1.8,6.3,1.4,6.8,1.4C6.7,1.4,6.7,1.4,6.8,1.4z M11.3,14.3c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V14.3z M11.3,11c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3v-0.6c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V11z M11.3,7.6c0,0.2-0.1,0.3-0.3,0.3H5.9c-0.2,0-0.3-0.1-0.3-0.3V7c0-0.2,0.1-0.3,0.3-0.3H11c0.2,0,0.3,0.1,0.3,0.3V7.6z',
    ],
  },
  menu: {
    width: 20,
    height: 16,
    viewBox: '0 0 20 16',
    paths: [
      'M15.8 12.44L15.91 12.58L15.98 12.74L16 12.91L15.98 13.09L15.91 13.25L15.8 13.38L15.67 13.49L15.51 13.56L15.33 13.58L0.67 13.58L0.49 13.56L0.33 13.49L0.2 13.38L0.09 13.25L0.02 13.09L0 12.91L0.02 12.74L0.09 12.58L0.2 12.44L0.33 12.34L0.49 12.27L0.67 12.24L15.33 12.24L15.51 12.27L15.67 12.34L15.67 12.34L15.8 12.44ZM15.8 7.32L15.91 7.46L15.98 7.62L16 7.8L15.98 7.97L15.91 8.13L15.8 8.27L15.67 8.37L15.51 8.44L15.33 8.46L0.67 8.46L0.49 8.44L0.33 8.37L0.2 8.27L0.09 8.13L0.02 7.97L0 7.8L0.02 7.62L0.09 7.46L0.2 7.32L0.33 7.22L0.49 7.15L0.67 7.13L15.33 7.13L15.51 7.15L15.67 7.22L15.67 7.22L15.8 7.32ZM15.8 2.21L15.91 2.34L15.98 2.5L16 2.68L15.98 2.85L15.91 3.01L15.8 3.15L15.67 3.25L15.51 3.32L15.33 3.34L0.67 3.34L0.49 3.32L0.33 3.25L0.2 3.15L0.09 3.01L0.02 2.85L0 2.68L0.02 2.5L0.09 2.34L0.2 2.21L0.33 2.1L0.49 2.03L0.67 2.01L15.33 2.01L15.51 2.03L15.67 2.1L15.67 2.1L15.8 2.21Z',
    ],
  },
  closeMenu: {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    paths: [
      'M14.94 17.94C14.77 17.77 13.43 16.42 13.26 16.25C11.16 14.16 9.48 12.47 9.06 12.05C9 11.99 8.97 12.02 8.93 12.06C8.51 12.48 5.16 15.84 4.74 16.26C3.9 17.09 3.24 17.76 3.07 17.93C3.01 17.99 2.98 17.99 2.93 17.93C1.5 16.5 0.35 15.36 0.07 15.07C0 15 0.01 14.97 0.07 14.91C2.99 11.99 5.33 9.65 5.92 9.07C5.98 9 5.98 8.97 5.92 8.9C3 5.99 0.67 3.66 0.09 3.08C-0.01 2.98 -0.01 2.98 0.09 2.87C1.5 1.46 2.63 0.33 2.91 0.05C2.98 -0.02 3.01 -0.02 3.08 0.05C5.99 2.96 8.33 5.3 8.91 5.88C9 5.97 9 5.97 9.09 5.88C12.01 2.96 14.34 0.62 14.93 0.04C14.99 -0.03 15.03 -0.02 15.09 0.04C16.51 1.47 17.66 2.61 17.95 2.9C18 2.96 18 2.98 17.95 3.04C15.02 5.96 12.68 8.3 12.1 8.89C12.02 8.96 12.02 8.99 12.1 9.07C15.02 11.99 17.36 14.32 17.95 14.91C18.01 14.97 18 15 17.95 15.06C16.51 16.49 15.37 17.64 15.08 17.93C15.05 17.96 15.03 17.98 15.01 18C14.98 17.98 14.96 17.96 14.94 17.94Z',
    ],
  },
  squareDown: {
    width: 14,
    height: 14,
    viewBox: '0 0 16 16',
    paths: [
      'M10.09 5.5C10.3 5.5 10.47 5.67 10.47 5.87C10.47 5.97 10.43 6.07 10.36 6.14C10.05 6.45 7.58 8.91 7.27 9.21C7.12 9.36 6.89 9.36 6.74 9.21C6.74 9.21 6.74 9.21 6.74 9.21C6.43 8.91 3.96 6.45 3.65 6.14C3.5 6 3.5 5.76 3.64 5.61C3.71 5.54 3.81 5.5 3.91 5.5C3.91 5.5 9.47 5.5 10.09 5.5ZM14 12.5C14 13.33 13.33 14 12.5 14C11.4 14 2.6 14 1.5 14C0.67 14 0 13.33 0 12.5C0 11.4 0 2.6 0 1.5C0 0.67 0.67 0 1.5 0C2.6 0 11.4 0 12.5 0C13.33 0 14 0.67 14 1.5C14 3.7 14 11.4 14 12.5ZM12.5 1.69C12.5 1.58 12.42 1.5 12.31 1.5C11.25 1.5 2.75 1.5 1.69 1.5C1.58 1.5 1.5 1.59 1.5 1.69C1.5 2.75 1.5 11.25 1.5 12.31C1.5 12.42 1.59 12.5 1.69 12.5C1.69 12.5 1.69 12.5 1.69 12.5C2.75 12.5 11.25 12.5 12.31 12.5C12.42 12.5 12.5 12.42 12.5 12.31C12.5 10.19 12.5 2.75 12.5 1.69Z',
    ],
  },
  squareUp: {
    width: 14,
    height: 14,
    viewBox: '0 0 16 16',
    paths: [
      'M3.91 8.5C3.7 8.5 3.53 8.33 3.53 8.13C3.53 8.03 3.57 7.93 3.64 7.86C3.95 7.55 6.42 5.09 6.73 4.79C6.88 4.64 7.11 4.64 7.26 4.79C7.26 4.79 7.26 4.79 7.26 4.79C7.57 5.09 10.04 7.55 10.35 7.86C10.5 8 10.5 8.24 10.36 8.39C10.29 8.46 10.19 8.5 10.09 8.5C8.85 8.5 4.53 8.5 3.91 8.5ZM14 12.5C14 13.33 13.33 14 12.5 14C11.4 14 2.6 14 1.5 14C0.67 14 0 13.33 0 12.5C0 11.4 0 2.6 0 1.5C0 0.67 0.67 0 1.5 0C2.6 0 11.4 0 12.5 0C13.33 0 14 0.67 14 1.5C14 3.7 14 11.4 14 12.5ZM12.5 1.69C12.5 1.58 12.42 1.5 12.31 1.5C11.25 1.5 2.75 1.5 1.69 1.5C1.58 1.5 1.5 1.59 1.5 1.69C1.5 2.75 1.5 11.25 1.5 12.31C1.5 12.42 1.59 12.5 1.69 12.5C1.69 12.5 1.69 12.5 1.69 12.5C2.75 12.5 11.25 12.5 12.31 12.5C12.42 12.5 12.5 12.42 12.5 12.31C12.5 10.19 12.5 2.75 12.5 1.69Z',
    ],
  },
  file: {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    paths: [
      'M11.25,9.688v1.094a.47.47,0,0,1-.469.469H4.219a.47.47,0,0,1-.469-.469V9.688a.47.47,0,0,1,.469-.469h6.563A.47.47,0,0,1,11.25,9.688ZM10.781,12.5H4.219a.47.47,0,0,0-.469.469v1.094a.47.47,0,0,0,.469.469h6.563a.47.47,0,0,0,.469-.469V12.969A.47.47,0,0,0,10.781,12.5ZM15,5.152V18.125A1.875,1.875,0,0,1,13.125,20H1.875A1.875,1.875,0,0,1,0,18.125V1.875A1.875,1.875,0,0,1,1.875,0H9.848a1.875,1.875,0,0,1,1.324.551l3.277,3.277A1.868,1.868,0,0,1,15,5.152ZM10,2.027V5h2.973L10,2.027Zm3.125,16.1V6.875H9.063a.935.935,0,0,1-.937-.937V1.875H1.875v16.25Z',
    ],
  },
  fileFill: {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    paths: [
      'M8.75,5.313V0H.938A.935.935,0,0,0,0,.938V19.063A.935.935,0,0,0,.938,20H14.063A.935.935,0,0,0,15,19.063V6.25H9.688A.94.94,0,0,1,8.75,5.313Zm2.5,9.219a.47.47,0,0,1-.469.469H4.219a.47.47,0,0,1-.469-.469v-.312a.47.47,0,0,1,.469-.469h6.563a.47.47,0,0,1,.469.469Zm0-2.5a.47.47,0,0,1-.469.469H4.219a.47.47,0,0,1-.469-.469v-.312a.47.47,0,0,1,.469-.469h6.563a.47.47,0,0,1,.469.469Zm0-2.812v.313a.47.47,0,0,1-.469.469H4.219a.47.47,0,0,1-.469-.469V9.219a.47.47,0,0,1,.469-.469h6.563A.47.47,0,0,1,11.25,9.219ZM15,4.762V5H10V0h.238A.937.937,0,0,1,10.9.273L14.727,4.1A.935.935,0,0,1,15,4.762Z',
    ],
  },
  warning: {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    paths: [
      'M10.6,13.5l-0.4-5.3V6h1.5v2.2l-0.4,5.3H10.6z M10.3,16v-1.4h1.4V16H10.3z',
      'M11,0.1C5,0.1,0.1,5,0.1,11S5,21.9,11,21.9S21.9,17,21.9,11S17,0.1,11,0.1z M11,20c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S16,20,11,20z',
    ],
  },
  info: {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    paths: [
      'M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8s8-3.6,8-8S12.4,0,8,0z M8,14.7c-3.7,0-6.7-3-6.7-6.7s3-6.7,6.7-6.7s6.7,3,6.7,6.7S11.7,14.7,8,14.7z',
      'M6.6,10.9H7V8.3H6.6c-0.2,0-0.4-0.2-0.4-0.4V7.1c0-0.2,0.2-0.4,0.4-0.4h2C8.9,6.7,9,6.9,9,7.1v3.9h0.4c0.2,0,0.4,0.2,0.4,0.4c0,0,0,0,0,0v0.9c0,0.2-0.2,0.4-0.4,0.4H6.6c-0.2,0-0.4-0.2-0.4-0.4c0,0,0,0,0,0v-0.9C6.2,11.1,6.4,10.9,6.6,10.9C6.6,10.9,6.6,10.9,6.6,10.9z M8,3.2c-0.7,0-1.3,0.6-1.3,1.3S7.3,5.8,8,5.8s1.3-0.6,1.3-1.3l0,0C9.3,3.8,8.7,3.2,8,3.2z',
    ],
  },
  img: {
    width: 20,
    height: 15,
    viewBox: '0 0 20 15',
    paths: [
      'M1.88 0C0.84 0 0 0.84 0 1.87C0 3 0 12 0 13.13C0 14.16 0.84 15 1.88 15C3.5 15 16.5 15 18.13 15C19.16 15 20 14.16 20 13.13C20 12 20 3 20 1.88C20 0.84 19.16 0 18.13 0C14.88 0 3.5 0 1.88 0ZM2.11 13.13C1.98 13.13 1.88 13.02 1.88 12.89C1.88 11.81 1.88 3.19 1.88 2.11C1.88 1.98 1.98 1.87 2.11 1.87C3.69 1.87 16.31 1.87 17.89 1.88C18.02 1.87 18.12 1.98 18.13 2.11C18.13 3.19 18.13 11.81 18.13 12.89C18.13 13.02 18.02 13.13 17.89 13.13C17.89 13.13 3.69 13.13 2.11 13.13ZM3.44 5C3.44 5.86 4.14 6.56 5 6.56C5.86 6.56 6.56 5.86 6.56 5C6.56 5 6.56 5 6.56 5C6.56 4.14 5.86 3.44 5 3.44C4.14 3.44 3.44 4.14 3.44 5ZM3.75 11.25L16.25 11.25L16.25 8.13C14.2 6.07 13.06 4.93 12.83 4.71C12.65 4.52 12.35 4.52 12.17 4.71C11.86 5.02 10.3 6.57 7.5 9.38C6.57 8.45 6.06 7.93 5.96 7.83C5.77 7.65 5.48 7.65 5.29 7.83C5.19 7.93 4.68 8.45 3.75 9.38L3.75 11.25Z',
    ],
  },
  chronometer: {
    width: 18,
    height: 19.6,
    viewBox: '0 0 18 19.6',
    paths: [
      'M17,9.5c-0.3-1.5-0.9-2.8-1.8-3.9l0,0l0.5-0.5c0.4-0.4,0.4-1,0-1.4l-0.6-0.6c-0.4-0.4-1-0.4-1.4,0l-0.6,0.6c0,0,0,0,0,0.1c0,0,0,0,0,0c-1-0.6-2.2-1.1-3.5-1.2v0V1.1h0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H9.6H7.5H7C6.7,0,6.4,0.2,6.4,0.5c0,0.3,0.2,0.5,0.5,0.5h0.5v1.4v0C3.3,3,0.1,6.6,0,10.9c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,4.1,2.9,7.6,6.8,8.4c0.6,0.1,1.1,0.2,1.7,0.2c0.6,0,1.2-0.1,1.7-0.2c3.5-0.7,6.2-3.5,6.7-7.1c0.1-0.4,0.1-0.9,0.1-1.3C17.1,10.5,17.1,10,17,9.5zM8.6,18.2c-4,0-7.2-3.2-7.2-7.2c0-4,3.2-7.2,7.2-7.2c4,0,7.2,3.2,7.2,7.2C15.8,15,12.6,18.2,8.6,18.2z',
      'M7.5,13.7H6.4V9.6C6,10,5.5,10.3,5,10.4v-1c0.3-0.1,0.6-0.3,0.9-0.5S6.5,8.3,6.6,8h0.9V13.7z',
      'M11,8c0.6,0,1,0.2,1.3,0.6c0.4,0.5,0.6,1.2,0.6,2.3c0,1.1-0.2,1.9-0.6,2.3c-0.3,0.4-0.7,0.6-1.3,0.6c-0.6,0-1-0.2-1.3-0.6S9.1,12,9.1,10.9c0-1.1,0.2-1.9,0.6-2.3C10,8.2,10.4,8,11,8z M11,8.9c-0.1,0-0.3,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.5c-0.1,0.3-0.1,0.8-0.1,1.4s0,1.1,0.1,1.4c0.1,0.3,0.2,0.4,0.3,0.5c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.1-0.1,0.2-0.2,0.2-0.5c0.1-0.3,0.1-0.8,0.1-1.4s0-1.1-0.1-1.4c-0.1-0.3-0.2-0.4-0.3-0.5C11.2,8.9,11.1,8.9,11,8.9z',
    ],
  },
  flag: {
    width: 17,
    height: 17,
    viewBox: '0 0 20 20',
    paths: [
      'M7.94 8.57C8.81 8.77 9.58 9.09 10.39 9.31C10.39 9.08 10.39 7.27 10.39 7.04C9.52 6.85 8.75 6.53 7.94 6.31C7.94 6.76 7.94 8.34 7.94 8.57ZM11.47 3.28C9.7 3.28 8.23 2.13 5.99 2.13C5.22 2.12 4.45 2.25 3.73 2.52C4.1 1.56 3.62 0.48 2.66 0.12C1.7 -0.25 0.63 0.23 0.26 1.19C-0.05 1.99 0.23 2.89 0.93 3.38C0.93 4.66 0.93 14.92 0.93 16.2C0.93 16.64 1.29 17 1.73 17C1.73 17 1.73 17 1.73 17C1.78 17 2.21 17 2.26 17C2.7 17 3.06 16.65 3.06 16.21C3.06 16.21 3.06 16.2 3.06 16.2C3.06 15.89 3.06 13.38 3.06 13.07C4.26 12.56 5.55 12.31 6.86 12.33C8.63 12.33 10.1 13.49 12.34 13.49C13.81 13.48 15.23 13 16.41 12.13C16.69 11.93 16.87 11.61 16.86 11.26C16.86 10.45 16.86 3.99 16.86 3.19C16.86 2.6 16.39 2.12 15.8 2.12C15.65 2.12 15.5 2.16 15.36 2.22C14.15 2.84 12.83 3.2 11.47 3.28ZM5.5 10.81C4.67 10.89 3.85 11.08 3.06 11.36C3.06 11.13 3.06 9.25 3.06 9.02C3.84 8.72 4.66 8.52 5.5 8.44C5.5 8.91 5.5 10.33 5.5 10.81ZM15.27 6.34C14.49 6.7 13.67 6.97 12.83 7.13C12.83 7.37 12.83 9.26 12.83 9.49C13.7 9.38 14.53 9.09 15.27 8.63C15.27 8.86 15.27 10.74 15.27 10.97C14.55 11.47 13.71 11.77 12.83 11.87C12.83 11.63 12.83 9.73 12.83 9.49C12.01 9.61 11.18 9.55 10.39 9.31C10.39 9.53 10.39 11.32 10.39 11.55C9.59 11.26 8.77 11.02 7.94 10.84C7.94 10.61 7.94 8.79 7.94 8.57C7.14 8.39 6.32 8.35 5.5 8.44C5.5 8.21 5.5 6.35 5.5 6.12C4.66 6.26 3.84 6.49 3.06 6.81C3.06 6.58 3.06 4.71 3.06 4.47C3.83 4.12 4.66 3.88 5.5 3.74C5.5 3.98 5.5 5.88 5.5 6.12C6.32 6 7.15 6.06 7.94 6.31C7.94 6.08 7.94 4.29 7.94 4.07C8.74 4.36 9.56 4.6 10.39 4.78C10.39 5 10.39 6.82 10.39 7.05C11.19 7.23 12.02 7.26 12.83 7.13C12.83 6.89 12.83 4.99 12.83 4.75C13.67 4.59 14.49 4.34 15.27 4C15.27 4.47 15.27 5.88 15.27 6.34Z',
    ],
  },
  error: {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    paths: [
      'M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0Z',
      'M9,4 8,3 6,4.9 4,3 3,4 4.9,6 3,8 4,9 6,7.1 8,9 9,8 7.1,6',
    ],
  },
  approved: {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    paths: [
      'M6,0a6,6,0,1,0,6,6A6,6,0,0,0,6,0Z',
      'M5.4,8.7 2.9,6.5 4.1,5 5.2,5.9 7.7,2.9 9.3,4.1',
    ],
  },
  canceled: {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    paths: [
      'M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M10,18.5c-4.7,0-8.5-3.8-8.5-8.5S5.3,1.5,10,1.5s8.5,3.8,8.5,8.5S14.7,18.5,10,18.5z',
      'M13.2,8.2 11.8,6.8 10,8.6 8.2,6.8 6.8,8.2 8.6,10 6.8,11.8 8.2,13.2 10,11.4 11.8,13.2 13.2,11.8 11.4,10',
    ],
  },
  bell: {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    paths: [
      'M15.447,12.737c-.679-.73-1.95-1.828-1.95-5.424A5.552,5.552,0,0,0,9,1.858V1.125a1.124,1.124,0,1,0-2.249,0v.733a5.552,5.552,0,0,0-4.5,5.455c0,3.6-1.271,4.694-1.95,5.424A1.1,1.1,0,0,0,0,13.5a1.126,1.126,0,0,0,1.129,1.125H14.621A1.126,1.126,0,0,0,15.749,13.5,1.1,1.1,0,0,0,15.447,12.737Zm-13.073.2a8.8,8.8,0,0,0,1.566-5.6c0-.007,0-.013,0-.02a3.938,3.938,0,0,1,7.875,0c0,.007,0,.013,0,.02a8.8,8.8,0,0,0,1.566,5.6ZM7.874,18a2.25,2.25,0,0,0,2.249-2.25h-4.5A2.25,2.25,0,0,0,7.874,18Z',
    ],
  },
  see: {
    width: 22,
    height: 16,
    viewBox: '0 0 22 16',
    paths: [
      'm21.373936,7.583739c-1.961376,-3.826908 -5.844344,-6.416155 -10.290443,-6.416155s-8.330152,2.591056 -10.290443,6.416517a1.170026,1.170026 0 0 0 0,1.055736c1.961376,3.826908 5.844344,6.416155 10.290443,6.416155s8.330152,-2.591056 10.290443,-6.416517a1.170026,1.170026 0 0 0 0,-1.055736zm-10.290443,5.736202a5.208153,5.208153 0 1 1 5.208153,-5.208153a5.205621,5.205621 0 0 1 -5.208153,5.208153zm0,-8.680255a3.447146,3.447146 0 0 0 -0.915405,0.137076a1.730626,1.730626 0 0 1 -2.419621,2.419621a3.464145,3.464145 0 1 0 3.335026,-2.556697z',
    ],
  },
  url: {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    paths: [
      'M1.824,20a1.837,1.837,0,0,1-.368-.037A1.814,1.814,0,0,1,.8,19.688a1.83,1.83,0,0,1-.661-.8,1.816,1.816,0,0,1-.106-.342A1.84,1.84,0,0,1,0,18.176V4.333a1.84,1.84,0,0,1,.037-.368,1.814,1.814,0,0,1,.274-.652,1.829,1.829,0,0,1,.8-.661,1.814,1.814,0,0,1,.342-.106,1.836,1.836,0,0,1,.368-.037H4.992A2.527,2.527,0,0,1,5.043,2a2.5,2.5,0,0,1,.377-.9A2.515,2.515,0,0,1,6.524.2,2.494,2.494,0,0,1,6.995.051a2.531,2.531,0,0,1,1.011,0,2.493,2.493,0,0,1,.9.377,2.515,2.515,0,0,1,.909,1.1A2.5,2.5,0,0,1,9.957,2a2.527,2.527,0,0,1,.051.506h3.169a1.836,1.836,0,0,1,.368.037,1.814,1.814,0,0,1,.652.275,1.83,1.83,0,0,1,.661.8,1.816,1.816,0,0,1,.106.342A1.838,1.838,0,0,1,15,4.333V18.176a1.838,1.838,0,0,1-.037.368,1.814,1.814,0,0,1-.274.652,1.829,1.829,0,0,1-.8.661,1.816,1.816,0,0,1-.342.106,1.836,1.836,0,0,1-.368.037ZM1.963,4.472a.317.317,0,0,0-.093.226V17.811a.319.319,0,0,0,.319.319H12.812a.319.319,0,0,0,.319-.319V4.7a.319.319,0,0,0-.319-.319H11.239V5.7a.57.57,0,0,1-.57.57H4.331a.57.57,0,0,1-.57-.57V4.379H2.188A.317.317,0,0,0,1.963,4.472ZM7.252,1.92A.64.64,0,1,0,7.5,1.87.636.636,0,0,0,7.252,1.92ZM3.442,13.74a.946.946,0,1,1,.946.946A.946.946,0,0,1,3.442,13.74Zm3.123.65a.319.319,0,0,1-.319-.319v-.661a.319.319,0,0,1,.319-.319h4.354a.319.319,0,0,1,.319.319v.661a.319.319,0,0,1-.319.319ZM4.8,11.24a.2.2,0,0,1-.152-.06L3.2,9.728a.205.205,0,0,1,0-.29l.484-.484a.205.205,0,0,1,.29,0l.829.829,1.86-1.861a.205.205,0,0,1,.29,0l.484.484a.205.205,0,0,1,0,.29L4.952,11.18a.2.2,0,0,1-.145.06Zm2.436-.567,1.3-1.3h2.383a.319.319,0,0,1,.319.319v.661a.319.319,0,0,1-.319.319Z',
    ],
  },
  urlFill: {
    width: 15,
    height: 20,
    viewBox: '0 0 15 20',
    paths: [
      'M13.118,20H1.882A1.885,1.885,0,0,1,0,18.112V4.366A1.882,1.882,0,0,1,1.882,2.478H4.942a2.529,2.529,0,0,1,5.057,0h3.118A1.885,1.885,0,0,1,15,4.366V18.112A1.882,1.882,0,0,1,13.118,20ZM4.353,12.8a.943.943,0,1,0,.666.276A.934.934,0,0,0,4.353,12.8Zm2.235.295a.354.354,0,0,0-.353.354v.59a.354.354,0,0,0,.353.354h4.294a.354.354,0,0,0,.353-.354v-.59a.354.354,0,0,0-.353-.354ZM3.842,8.856a.233.233,0,0,0-.166.069l-.5.5a.236.236,0,0,0,0,.334l1.414,1.418a.233.233,0,0,0,.166.069h.021a.233.233,0,0,0,.166-.069L7.4,8.717a.236.236,0,0,0,0-.334l-.5-.5a.235.235,0,0,0-.333,0l-1.8,1.8-.759-.761A.233.233,0,0,0,3.842,8.856Zm4.718.465-1.294,1.3h3.616a.354.354,0,0,0,.353-.354v-.59a.354.354,0,0,0-.353-.354H8.56ZM7.471,1.888a.648.648,0,1,0,.458.19A.642.642,0,0,0,7.471,1.888Z',
    ],
  },
  tracker: {
    width: 10.5,
    height: 15,
    viewBox: '8 2 9 15',
    paths: [
      'M12.75,3A5.246,5.246,0,0,0,7.5,8.25c0,3.938,5.25,9.75,5.25,9.75S18,12.188,18,8.25A5.246,5.246,0,0,0,12.75,3Zm0,7.125A1.875,1.875,0,1,1,14.625,8.25,1.876,1.876,0,0,1,12.75,10.125Z'
    ]
  }
}
