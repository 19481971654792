import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  &:focus {
    outline: none;
  }
`
export const BlackoutMobile = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 4, 19, 0.8);
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  transition: all linear 0.3s;
  z-index: 1;
  .close-menu {
    position: absolute;
    top: 15px;
    right: ${(props) => (props.active ? '10px' : '100%')};
    transition: all 0.6s;
  }
  @media (max-width: 768px) {
    display: block;
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  }
`
export const Toggle = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 15px;
  color: ${({ theme }) => theme.colors.white};
  font: 16px/26px 'DMSans-Medium';
  &:hover {
    opacity: 0.9;
  }
  svg {
    &.mobile {
      display: none;
    }
  }
  label {
    cursor: pointer;
    padding: 8px;
  }
  @media (max-width: 768px) {
    padding: 10px;
    label,
    svg.desktop {
      display: none;
    }
    svg.mobile {
      display: block;
    }
  }
`

export const MenuCollapsed = styled.div`
  position: absolute;
  right: 0;
  top: 50px;
  overflow: hidden;
  width: 0;
  height: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.grayShadow};
  z-index: 1;
  &.active {
    width: 257px;
    height: auto;
    @media (max-width: 768px) {
      background: rgba(0, 4, 19, 0.8);
      height: 100%;
      width: calc(100% - 50px);
      left: 0;
      transition: left 0.6s;
    }
  }
  @media (max-width: 768px) {
    position: fixed;
    top: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: transparent;
    left: -100%;
    transition: left 0.6s;
    box-shadow: none;
  }
  ul {
    @media (max-width: 768px) {
      box-shadow: 0px 3px 6px ${({ theme }) => theme.colors.grayShadow};
      height: 100%;
      width: 100%;
      background: ${({ theme }) => theme.colors.white};
      position: relative;
    }
  }
`

export const Label = styled.span`
  position: absolute;
  left: 54px;
  color: ${({ theme }) => theme.colors.gray_1};
  font: 14px/26px 'DMSans-Regular';
  @media (max-width: 768px) {
    left: 64px;
  }
`
export const ListItemName = styled.li`
  font: 16px/26px 'DMSans-Bold';
  padding: 32px 20px 20px 27px;
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`

export const ListItem = styled.li`
  display: flex;
  cursor: pointer;
  width: 100%;
  height: 60px;
  transition: background 0.25s;
  text-decoration: none;
  align-items: center;
  position: relative;
  &:hover {
    background: ${({ theme }) => theme.colors.graySuperLightness};
    @media (max-width: 768px) {
      background: inherit;
    }
  }
  svg {
    margin-left: 25px;
    @media (max-width: 768px) {
      margin-left: 35px;
    }
  }
`
