import styled from 'styled-components'

export const RejectContentWrapper = styled.div`
  display: flex;
  margin-top: 30px;
  width: calc(100% - 217px);
  @media (max-width: 1024px) {
    flex-wrap: wrap;
    width: 100%;
  }
`

export const RejectContentCause = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  font: 14px/20px 'DMSans-Regular';
  color: ${({ theme }) => theme.colors.error};
  svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }
  p {
    margin: 0;
  }
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    svg {
      width: 22px;
      height: 22px;
    }
  }
`

export const RejectContentDate = styled.div`
  width: 50%;
  border-radius: 4px;
  padding: 5px 10px;
  font: 14px/20px 'DMSans-Regular';
  background: #e9e9e9;
  margin-left: 20px;
  margin-right: 0;
  color: #4f5356;
  height: auto;
  text-align: left;
  align-self: center;
  b:first-of-type {
    text-transform: capitalize;
  }
  @media (max-width: 1024px) {
    margin: 30px 0 0 0;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }
`
