import styled from 'styled-components'

export const WrapperAlertFixed = styled.div`
  background: ${({ theme }) => theme.colors.bgAlert};
  box-shadow: 0px -3px 6px ${({ theme }) => theme.colors.grayShadow};
  height: 60px;
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 15px;
  display: flex;
  align-items: center;
  font: 14px/26px ${({ theme }) => theme.font.medium};
  color: ${({ theme }) => theme.colors.grayDark};
  z-index: 2;
  @media (max-width: 768px) {
    top: 50px;
    bottom: initial;
    padding: 17px 15px;
    height: auto;
    align-items: flex-start;
  }
  span {
    @media (max-width: 768px) {
      width: calc(100% - 41px);
      font: 13px/20px ${({ theme }) => theme.font.medium};
    }
  }
`
