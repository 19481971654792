import {
  SET_CAMPAIGNS,
  SET_FETCHING_CAMPAIGNS,
  SET_CLEAR_CAMPAIGNS,
  WERE_COOKIES_ACCEPTED
} from '../../types/types'

const initialState = {
  campaigns: [],
  fetching: false,
  wereCookiesAccepted: false,  
}
const campaingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CLEAR_CAMPAIGNS:
      return initialState
    case SET_CAMPAIGNS:
      return Object.assign({}, state, { campaigns: action.payload.campaigns })
    case SET_FETCHING_CAMPAIGNS:
      return Object.assign({}, state, { fetching: action.payload.fetching })
    case WERE_COOKIES_ACCEPTED:
        const updateWereCookiesAccepted = action.payload.data;
      return {...state, wereCookiesAccepted: updateWereCookiesAccepted}
  
    default:
      return state
  }
}

export default campaingsReducer
