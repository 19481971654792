/* import external modules */
import React from 'react'

/* import internal modules */
import { Card, TextTitle, TextPrimary, TextSecundary, Text } from './styles'

const CardInfo = ({
  title,
  textPrimary,
  textSecondary,
  text,
  primary,
  children,
  ...props
}) => {
  return (
    <Card primary={primary ? true : false} {...props}>
      {title && <TextTitle>{title}</TextTitle>}
      {textPrimary && <TextPrimary>{textPrimary}</TextPrimary>}
      {textSecondary && <TextSecundary>{textSecondary}</TextSecundary>}
      {text && <Text>{text}</Text>}
      {children}
    </Card>
  )
}

export default CardInfo
