// import internal modules
import { HANDLE_USER, SET_TOKEN } from '../../types/types'

const initialState = {
  user: null,
  token: '',
}

export default function (state = initialState, action) {
  switch (action.type) {
    case HANDLE_USER:
      return Object.assign({}, state, { user: action.payload.user })
    case SET_TOKEN:
      return Object.assign({}, state, { token: action.payload.token })
    default:
      return state
   
  }
}
