/* import external modules */
import styled from 'styled-components'

/* import internal modules */

export const Container = styled.div`
  display: flex;
  margin-top: 15px;
`
export const Text = styled.div`
  text-align: left;
  font-weight: 400;
  line-height: 14px;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.white};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : null)};
`
