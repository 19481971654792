/* import external modules */
import React, {useState} from 'react'

/* import internal modules */
import {
    Item,
    Concept, 
    Description 
} from './styles' 
import Modal from '../../Common/Modal'
import P from '../../Common/P'
import Icon from '../../Common/Icon'
import ActionsAdvertise from '../AdvertiseItem/ActionsAdvertise'

const ModalViewMathilde = ({
    isOpen,
    title,
    format,
    deviceName,
    mathildeViews,
    nextDay,
    typeAd,
    urlReport,
    urlSmart,
    reportType,
    ...props
})=>{
    const [ open, setOpen ] = useState(false)
    const handleClose = () => setOpen(false)
    const mathildeCant = Number(mathildeViews)
    return (
        <Modal open={isOpen} {...props} width="350px"  className="modal-mathilde">
            <P font="14px/22px 'DMSans-Regular'">
                <b>Pauta Extendida</b>
            </P>
            <Item>
                <Concept font="16px/22px 'DMSans-Medium'" mb="7px" className="colorCart">
                        <b>{title}</b>                    
                     <Description>
                        ‎ 
                     </Description>
                </Concept>
                <Concept className="colorCartSub classTextInfoHead">
                    Formato:
                    <Description>
                        <Icon width='15px' height='20px' icon={deviceName} color="#4f5356" />
                        {format}
                    </Description>
                </Concept>
                <Concept className="colorCartSub classTextInfoHead">
                    Impresiones:
                    <Description>
                        {mathildeCant}
                    </Description>
                </Concept>
                <Concept className="colorCartSub classTextInfo">
                    Al finalizar tu campaña podrás revisar en tu reporte los portales Premium en los que se publicó tu pauta extendida
                </Concept>
                <Concept className="colorCartSub classCenter">
                {urlSmart != null && (
                <ActionsAdvertise
                        onClick={() => setOpen(true)}
                        nextDayFinalDate={nextDay}
                        typeAd={typeAd}
                        urlReport={"Mathilde"}
                        urlSmart={urlSmart}
                        reportType={reportType}
                    />
                    )}
                </Concept>  
            </Item>
            
        </Modal>
    )
}

export default ModalViewMathilde