/* import external modules */
import React, { useState } from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'

/* import internal modules */
import CardInfo from '../../Common/CardInfo'
import Icon from '../../Common/Icon'
import Img from '../../Common/Img'
import CampaignReject from '../CampaignReject'
import CampaignRejectContent from '../CampaignRejectContent'
import ActionsAdvertise from '../AdvertiseItem/ActionsAdvertise'
import { Row} from 'styled-bootstrap-grid'

import { dateFormat } from './../../../constants/formats'
import { AdvertisingType } from '../../../constants/advertisingType'
import { MaterialStatusCode, MaterialStatus, CampaigntTypeStatus } from '../../../constants/status'

import { Container, CardWrapper, State, Link, Format, CardTitle, Line, LinkClics, RowC, CardWrapperClics,  } from '../AdvertiseItem/styles'

import 'moment/locale/es' // without this line it didn't work
import TooltipInfo from '../../Common/TooltipInfo'
import messages from '../../../constants/messages'
import ModalReport from '../ModalReport'
import TypeProductContent from "../AdvertiseItem/typeProductContent";
import {groupCities} from "../../../herpers/groupCities";
import {groupPortals} from "../../../herpers/groupPortals";
import CampaignRejectClicks from '../CampaignRejectClicks'

moment.locale('es')
moment.suppressDeprecationWarnings = true

/** @description This is a container functional component of AdvertiseItem
 **  @version 1.0.0
 *  @since 02/12/2020
 *  @author Yadurani López Rivera <yadlop.ext@eltiempo.com>
 *  @author Franklin Marquez <framar.ext@eltiempo.com>
 *
 *  @function
 *  @name AdvertiseItem
 *  @param {number} numAdvertise Receives a number of advertisements
 *  @param {number} statusCode Status advertise approved, pending, reject
 *  @param {string} imgAd Image for each advertisements
 *  @param {object} format Detail format for each advertisements
 *  @param {string} device Device for each advertisements
 *  @param {number} typeSegment Segment type for each advertisements
 *  @param {string} numCampaign Receive a number for the campaign as an identifier
 *  @param {array} infoSegmentPortal Receives array selected portals
 *  @param {array} infoSegmentAudience Receives array selected audience
 *  @param {string} impressions Number of impressions
 *  @param {string} url Url for each advertisements
 *  @param {string} initialDate Initial date for each advertisements
 *  @param {string} endDate End date for each advertisements
 *  @param {string} urlReport Url report for each advertisements
 *  @param {string} review Review for each advertisements
 *  @param {string} imageTemplate Image template for each advertisements
 *  @param {string} limitDate Limit date for each advertisements
 *  @param {object} adContent content ad info
 *  @param {string} typeAd type Ad
 *  @param {string} urlSmart Smart traking link
 *  @param {string} reportType Number
 *  @param {string} reportDataStudio String
 *  @param {string} campaignName String
 *  @param {string} campaignId Number
 *  @returns {Component} Returns the container component of the advertise item
 **/

const AdvertiseItemClicks = ({
  numAdvertise,
  numPauta,
  statusCode,
  imgAd,
  adId,
  format,
  device,
  advertisingTypeId,
  typeSegment,
  numCampaign,
  infoSegmentPortal,
  infoSegmentAudience,
  impressions = 'N/A',
  url = 'N/A',
  initialDate,
  endDate,
  urlReport,
  review,
  imageTemplate,
  limitDate,
  adContent,
  typeAd,
  urlSmart,
  reportDataStudio,
  reportType,
  campaignName,
  campaignId,
  advertisements,
  nameFormat
}) => {
  const [ open, setOpen ] = useState(false)
  let portals = groupPortals(infoSegmentPortal)
  let cities = groupCities(advertisements)
  let statusAdvertise =
    statusCode === MaterialStatusCode.Rejected
      ? MaterialStatus.Rejected
      : statusCode === MaterialStatusCode.Approved
      ? MaterialStatus.Approved
      : MaterialStatus.Pending

  const nameJoinPortals =
    (infoSegmentPortal && infoSegmentPortal.length === 6
      ? 'Toda la red'
      : portals) ||
    (infoSegmentAudience && infoSegmentAudience.join(', ')) ||
    []

  const start = moment()
  const finalDate = moment(endDate, dateFormat)

  //Difference in number of days
  const diff = start.diff(finalDate, 'days')
  const titleTypeSegment = typeSegment === CampaigntTypeStatus.Portal ? 'Portal:' : 'Audiencia:'
  const nextDayFinalDate = diff > 0
  const advertisingData = {
    numAdvertise,
    format,
    nameJoinPortals,
    impressions,
    url,
    initialDate,
    endDate,
    reportDataStudio,
    campaignName,
    campaignId,
    device
  }
  const handleClose = () => setOpen(false)
  return (
    <Container>
    
      <CardWrapperClics>
        <RowC  className="row"> 
        <Line></Line>
        <CardInfo className="Img">

        <CardInfo 
            text={
              <><Row >
               Pieza {numPauta}:
               <State className={statusAdvertise}>
                  {statusAdvertise}
                </State>
              </Row> </>
              
            }
         />
        {typeAd === AdvertisingType.Clicks ? (
         <LinkClics href={imgAd} target="_blank">
         <Img src={imgAd} />
         </LinkClics>
         ) : (
        <TypeProductContent
         data={adContent}
         numAdvertise={numAdvertise}
          />
         )}
        </CardInfo>
        </RowC>
        
        <CardInfo title="Formato:">
          <Format>
            {format && <Icon icon={device} />}
            {format?.name || 'N/A'}
          </Format>
        </CardInfo>
        {statusCode === MaterialStatusCode.Rejected &&
        typeAd === AdvertisingType.Clicks && (
          <CampaignRejectClicks
            num={`${numCampaign}-${numAdvertise}`}
            review={review}
            width={format?.width}
            height={format?.height}
            weight={format?.fileSize}
            device={device}
            date={limitDate}
            format={format?.name}
            imageTemplate={imageTemplate}
            adId={adId}
            advertisingTypeId={advertisingTypeId}
            nameFormat={nameFormat}
          />
        )}
      </CardWrapperClics>
     
      {open && <ModalReport onClose={handleClose} open={open} data={advertisingData}/>}
    </Container>
  )
}

AdvertiseItemClicks.propTypes = {
  numAdvertise: PropTypes.number,
  statusCode: PropTypes.number,
  imgAd: PropTypes.string,
  format: PropTypes.object,
  device: PropTypes.string,
  typeSegment: PropTypes.number,
  numCampaign: PropTypes.string,
  infoSegmentPortal: PropTypes.array,
  infoSegmentAudience: PropTypes.array,
  impressions: PropTypes.string,
  url: PropTypes.string,
  initialDate: PropTypes.string,
  endDate: PropTypes.string,
  urlReport: PropTypes.string,
  review: PropTypes.string,
  imageTemplate: PropTypes.string,
  limitDate: PropTypes.string,
  adContent: PropTypes.object,
  typeAd: PropTypes.string,
  urlSmart: PropTypes.string,
  reportDataStudio: PropTypes.string,
  campaignName: PropTypes.string,
  campaignId: PropTypes.number,
  reportType: PropTypes.number
}

export default AdvertiseItemClicks
