import styled from 'styled-components'

export const ContentImgDummy = styled.div`
  width: ${(props) => (props.device === 'desktop' ? '80%' : '168px')};
  position: relative;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 10px;
  @media (max-width: 767px) {
    width: ${(props) => (props.device === 'desktop' ? '100%' : '130px')};
  }
  @media only screen and (max-device-width: 767px) and (orientation: landscape) {
    width: ${(props) => (props.device === 'desktop' ? '100%' : '300px')};
  }
  p {
    font: 14px/22px ${({ theme }) => theme.font.regular};
  }
`
export const ContentImgPreview = styled.div`
  position: absolute;
  overflow: hidden;
  width: ${(props) =>
    props.sizesModal ? `${props.sizesModal.width}%` : 'initial'};
  height: ${(props) =>
    props.sizesModal ? `${props.sizesModal.height}%` : 'initial'};
  top: ${(props) =>
    props.positionModal
      ? props.positionModal.top === 'initial'
        ? props.positionModal.top
        : `${props.positionModal.top}%`
      : 0};
  bottom: ${(props) =>
    props.positionModal
      ? props.positionModal.bottom === 'initial'
        ? props.positionModal.bottom
        : `${props.positionModal.bottom}%`
      : 0};
  left: ${(props) =>
    props.positionModal
      ? props.positionModal.left === 'initial'
        ? props.positionModal.left
        : `${props.positionModal.left}%`
      : 0};
  right: ${(props) =>
    props.positionModal
      ? props.positionModal.right === 'initial'
        ? props.positionModal.right
        : `${props.positionModal.right}%`
      : 0};
  margin: auto;
  img {
    object-fit: contain;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`
export const SpanContent = styled.span`
  font-family: ${({ theme }) => theme.font.bold};
  vertical-align: baseline;
  padding-right: 5px;
`
export const ColTitle = styled.div`
  padding: 0;
  width: calc(100% - 15px);
  @media (max-width: 767px) {
    width: calc(100% - 80px);
  }
`
export const ContentImgDummyHeigth = styled.div`
  @media (max-width: 767px) {
    height: 100%;
    display: flex;
    align-items: ${(props) =>
      props.device === 'mobile' ? 'flex-start' : 'center'};
    justify-content: center;
    width: 100%;
    flex-direction: column;
    margin: ${(props) => props.device === 'mobile' && 'auto'};
    @media only screen and (max-device-width: 767px) and (orientation: landscape) {
      svg {
        /* height: ${(props) => (props.device === 'desktop' ? '90%' : null)}; */
        width: ${(props) => (props.device === 'mobile' ? '32%' : '100%')};
        margin-bottom: 0;
      }
    }
  }
  @media (max-height: 412px) {
    align-items: flex-start;
  }
`
export const ColFooterModal = styled.div`
  padding: 0 14px 28px 14px;
  color: ${({ theme }) => theme.colors.grayDark};
  font: 14px/18px ${({ theme }) => theme.font.regular};
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 0px 0 28px 0;
    flex-direction: column;
    white-space: pre-wrap;
    width: 100%;
  }
`
export const ColFooterButtons = styled.div`
  padding-left: 20px;
  @media (max-width: 768px) {
    padding: 30px 0 0 0;
  }
`
