// import internal modules

import { FETCH_LOADING, FETCH_MESSAGES } from '../../types/types'

const initialState = {
  loading: false,
  messages: {
    show: false,
    message: '',
    state: '',
  },
}

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_LOADING:
      return {
        ...state,
        loading: action.payload.load,
      }

    case FETCH_MESSAGES:
      return {
        ...state,
        messages: action.payload.message,
      }
    default:
      return state
  }
}
