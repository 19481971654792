const theme = {
  colors: {
    primary: '#FF7900',
    primaryDark: '#CF4700',
    secondary: '#006CA5',
    third: '#252627',
    blue_darkness: '#001634',
    grayBg: '#EEEEEE',
    gray: '#8E9194',
    graySuperLightness: '#E9E9E9',
    grayLightness: '#A6A9AB',
    grayLight: '#5D6164',
    grayMedium: '#8c8e8f',
    grayMediumLight: '#BBBEC1',
    grayDark: '#4F5356',
    graySuperDark: '#4C4E51',
    grayBorder: '#dbdddf',
    grayMediumBorder: '#cecece',
    grayShadow: '#00000029',
    grayDisabled: '#7d8184',
    white: '#FFFFFF',
    black: '#000000',
    success: '#00AE34',
    successLight: '#00AE3433',
    pending: '#06699D',
    pendingLight: '#006CA530',
    error: '#E20000',
    errorLight: '#E2000033',
    bgAlert: '#E5F7EA',
  },
  font: {
    regular: 'DMSans-Regular',
    medium: 'DMSans-Medium',
    bold: 'DMSans-Bold',
  },
  fonts: ['sans-serif', 'Roboto', 'Open Sans', 'Roboto Slab'],
}

export default theme
